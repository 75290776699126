import { Detail } from "../components/AccordionItem";
import { THoldFile } from "../types/holdFile";

export const createDetailFromData = (data: THoldFile): Detail => {
  return {
    key: data.sk,
    active: true,
    linkValue: `${data.lastName}, ${data.firstName}`,
    linkTarget: `customers/${data.customerId}`,
    label: `${data.storeLocation}`,
    notes: data.notes || "",
  };
};
