import React from "react";
import { Link, Outlet } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from "react-toastify";

import InputModal from "../components/InputModal";
import ModalHeader from "../components/ModalHeader";

import { PageBanner } from "../components";
import { Addresses } from "../components/addresses/Addresses";
import useShopDetails from "../hooks/useShopDetails";
import { useCustomers } from "../context/customersContext";
import { useProfileMachine } from "../context/profileContext";
import { useCustomer } from "../context/customerContext";
import ReactSelect from "react-select";
import { useTags } from "../machines/tagsMachine";
import Button from "../components/buttons/Button";
import { TTag } from "../types/tag";
import { TCustomer } from "../types/customer";

export type TCustomerOutletContext = {
  // customerService;
  handleEditCustomer: (customer: TCustomer) => void;
  handleDeleteCustomer: (customer: TCustomer) => void;
  handleShowCreateCustomer: () => void;
  handleHideCreateCustomer: () => void;
};

export default function Customers() {
  const uploadRef = React.useRef(null);
  const {
    handleEditCustomer,
    handleDeleteCustomer,
    handleHideCreateCustomer,
    handleShowCreateCustomer,
    customer,
    state,
    send,
    customerService,
  } = useCustomer();

  const { tags } = useTags();
  const { isProfileLoaded, locations } = useProfileMachine();
  const { handleCustomerAddSuccess, handleCustomerEditSuccess } =
    useCustomers();

  const { shop } = useShopDetails();

  //@ts-ignore
  const handleSubmitCustomer = async (e) => {
    e.preventDefault();
    const isEditing = state.context.mode === "edit";
    send("submit_form");

    try {
      if (!shop) {
        throw new Error("no user name");
      }

      if (isEditing) {
        handleCustomerEditSuccess(state.context.customer as TCustomer);
        editCustomer(state.context.customer as TCustomer);
      } else {
        const customer = await addACustomer({
          customer: { ...(state.context.customer as TCustomer) },
          shop,
        });
        handleCustomerAddSuccess(customer);
      }

      send("form_submit_success");
    } catch (error) {
      send("form_submit_error");
    }
  };

  const addACustomer = ({
    customer,
    shop,
  }: {
    customer: TCustomer;
    shop: string;
  }) => {
    return API.post("customers", "/customers", {
      body: { customer, shop },
    });
  };

  const handleInputChange = (
    e:
      | React.SelectHTMLAttributes<HTMLSelectElement>
      | React.InputHTMLAttributes<HTMLInputElement>
  ) => {
    // @ts-ignore
    const { name, value } = e.target;
    send({ type: "change_form_value", value: { [name]: value } });
  };

  //@ts-ignore
  const handleAddressChange = (e, addressId: number) => {
    const { name, value } = e.target;
    send({ type: "change-address", value: { [name]: value, addressId } });
  };

  const editCustomer = (customer: TCustomer) => {
    return API.put("customers", `/customers/${customer?.customerId}`, {
      body: { customer, shop },
    });
  };

  const handleModalClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const closeIds = ["modal", "close-icon"];
    //@ts-ignore
    if (closeIds.includes(e.target.id)) {
      send("hide_create_customer");
    }
  };

  const handleTagList = (tags: TTag[]) => {
    send({
      type: "change_form_value",
      //@ts-ignore
      value: { tags: tags },
    });
  };

  const showModal = state.matches("visible") || state.matches("submitting");
  const showUploadModal = state.matches("upload") || state.matches("uploading");
  const hasLocationsLoaded = isProfileLoaded;
  const mustCreateLocation = locations?.length === 0;
  const hasOneLocation = locations?.length === 1;

  return (
    <main>
      <PageBanner heading="Manage your customers">
        Add customers, set their details, and send messages
      </PageBanner>
      <Outlet
        context={{
          customerService,
          handleEditCustomer,
          handleDeleteCustomer,
          handleShowCreateCustomer,
          handleHideCreateCustomer,
        }}
      />
      {showUploadModal && (
        //@ts-ignore
        <InputModal onCloseModal={handleModalClose}>
          <ModalHeader>Upload customers via a CSV</ModalHeader>
          <div className="mt-2 px-7 py-3">
            <p>
              To bulk upload customers we need the CSV to have the following
              values
            </p>
            <ul className="list-disc ml-7">
              <li className="py-3">
                <p className="inline-block w-12">firstName</p>
                <p className="ml-8 inline-block text-sm text-gray-500">
                  This will allow messages to say "Hi Gwen"
                </p>
              </li>
              <li className="pb-3">
                <p className="inline-block w-12">lastName</p>
              </li>
              <li className="pb-3">email</li>
              <li className="pb-3">
                <p className="inline-block w-12">phone</p>
                <p className="ml-8 inline-block text-sm text-gray-500">
                  valid format: 610-322-6109
                </p>
              </li>
            </ul>
            <form onSubmit={() => toast("This is not yet implemented")}>
              <div className="w-1/2 md:flex md:my-2">
                <label
                  htmlFor="csv"
                  className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
                >
                  <input autoFocus type="file" name="csv" ref={uploadRef} />
                </label>
              </div>
              <Button disabled={state.matches("uploading")} type="submit">
                Upload
              </Button>
            </form>
          </div>
        </InputModal>
      )}
      {showModal && (
        //@ts-ignore
        <InputModal onCloseModal={handleModalClose}>
          <ModalHeader>Customer management</ModalHeader>
          {mustCreateLocation ? (
            <p>
              You have to{" "}
              <Link to="/profile" className="text-blue-500 underline">
                create a location first
              </Link>
            </p>
          ) : (
            <div className="mt-2 px-7 py-3">
              <form onSubmit={handleSubmitCustomer}>
                <div className="md:flex md:my-2">
                  <label
                    htmlFor="firstName"
                    className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
                  >
                    <span>First name</span>
                    <input
                      autoFocus
                      type="text"
                      name="firstName"
                      placeholder="Peter"
                      value={customer?.firstName}
                      onChange={(e) => {
                        if (hasOneLocation) {
                          send({
                            type: "change_form_value",
                            value: {
                              storeLocation: locations[0],
                            },
                          });
                        }
                        send({
                          type: "change_form_value",
                          value: { [e.target.name]: e.target.value },
                        });
                      }}
                    />
                  </label>
                  <label
                    htmlFor="lastName"
                    className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
                  >
                    <span>Last name</span>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Parker"
                      value={customer?.lastName}
                      onChange={(e) => {
                        send({
                          type: "change_form_value",
                          value: { [e.target.name]: e.target.value },
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:flex md:my-2">
                  <label
                    htmlFor="phone"
                    className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
                  >
                    <span>Customer phone</span>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="(610) 867-5309"
                      value={customer?.phone}
                      onChange={(e) => {
                        //@ts-ignore
                        handleInputChange(e);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="note"
                    className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
                  >
                    <span>Notes</span>
                    <input
                      type="text"
                      name="note"
                      id="note"
                      placeholder="Prefers variant covers"
                      value={customer?.note || ""}
                      onChange={(e) => {
                        //@ts-ignore
                        handleInputChange(e);
                      }}
                    />
                  </label>
                </div>
                <div className="md:flex md:my-2">
                  <label
                    htmlFor="email"
                    className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
                  >
                    <span>Customer email</span>
                    <input
                      name="email"
                      type="email"
                      placeholder="pparker@msst.edu"
                      id="email"
                      value={customer?.email || ""}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </label>
                  {hasLocationsLoaded && (
                    <label
                      htmlFor="location"
                      className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
                    >
                      {hasOneLocation ? (
                        <div className="flex-1 flex flex-col">
                          <span>Location</span>
                          <p className="flex flex-1 items-center text-gray-500">
                            {locations[0]}
                          </p>
                        </div>
                      ) : (
                        <>
                          <div>
                            <span>Select Location</span>
                            {customer.storeLocation && (
                              <span className="ml-4 opacity-60">
                                Currently set to: {customer.storeLocation}
                              </span>
                            )}
                          </div>
                          <select
                            name="storeLocation"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          >
                            <option>Choose a location</option>
                            {locations?.map((location) => {
                              return (
                                <option key={location} value={location}>
                                  {location}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </label>
                  )}
                </div>
                {tags?.length > 0 ? (
                  <div className="md:flex md:my-2">
                    <span className="flex-1" />
                    <label className="flex flex-1 flex-col my-2 md:m-0 md:mr-2">
                      <span>Tags</span>
                      <ReactSelect
                        placeholder="Select some tags"
                        isMulti
                        value={customer?.tags?.map((tag) => ({
                          value: tag.sk,
                          label: tag.tag,
                          ...tag,
                        }))}
                        onChange={(tag) => {
                          //@ts-ignore
                          handleTagList(tag);
                        }}
                        options={tags?.map((tag) => {
                          return {
                            value: tag.sk,
                            label: tag.tag,
                            ...tag,
                          };
                        })}
                      />
                    </label>
                  </div>
                ) : null}
                <Addresses
                  handleAddressChange={handleAddressChange}
                  customer={customer as TCustomer}
                />
                {
                  //@ts-ignore
                  state.matches("loaded.submitting") ? (
                    <button
                      className={`py-2 px-4 bg-green-400 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75;`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 animate-spin"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </button>
                  ) : (
                    <Button
                      variant="submission"
                      type="submit"
                      disabled={!state.can("submit_form")}
                    >
                      Submit changes
                    </Button>
                  )
                }
              </form>
            </div>
          )}
        </InputModal>
      )}
    </main>
  );
}
