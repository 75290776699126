import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import useKeypress from "../hooks/useKeypress";

import { useCustomers } from "../context/customersContext";
import Paper from "../components/paper/Paper";
import CustomersTable from "../components/CustomersTable";
import EmptyData from "../components/organisms/EmptyData";
import Spinner from "../components/Spinner";
import { useTags } from "../machines/tagsMachine";
import { Link, useOutletContext } from "react-router-dom";
import Button from "../components/buttons/Button";
import { TCustomer } from "../types/customer";
import { TCustomerOutletContext } from "../routes/customers";
import { useBundles } from "../machines/bundlesMachine";
import { Filters, ISelectableFilter } from "../components/filters/Filters";
import { useBoolean } from "usehooks-ts";

const CustomersPage = () => {
  const { value: isFiltersOpen, toggle } = useBoolean(false);
  const { bundles: b } = useBundles();
  const { tags: t } = useTags();
  const { handleHideCreateCustomer, handleShowCreateCustomer } =
    useOutletContext<TCustomerOutletContext>();
  const { customers, loading } = useCustomers();

  const [tags, setTags] = React.useState<ISelectableFilter[]>([]);
  const [bundles, setBundles] = React.useState<ISelectableFilter[]>([]);
  const [filteredCustomers, setFilteredCustomers] = React.useState<TCustomer[]>(
    []
  );
  const [filters, setFilters] = React.useState("");

  React.useEffect(() => {
    const filterTags = t.map((tag) => {
      return { ...tag, selected: false };
    });
    setTags(filterTags);
  }, [t, setTags]);
  React.useEffect(() => {
    const bundles = b.map((bundle) => {
      return { ...bundle, selected: false };
    });
    setBundles(bundles);
  }, [b, setBundles]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(window.location.search);
  React.useEffect(() => {
    if (queryParams.has("create")) {
      handleShowCreateCustomer();
      queryParams.delete("create");
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [handleShowCreateCustomer, queryParams]);

  const handleTagFiltering = React.useCallback(() => {
    const filteredList = customers.filter((customer) => {
      if (customer.tags) {
        const customerHasActiveFilter = customer.tags.some((v) =>
          tags.some((t) => t?.sk === v.sk && t.selected)
        );

        if (customerHasActiveFilter) return customer;
      }

      if (customer.bundles) {
        const customerHasActiveFilter = customer.bundles.some((v) =>
          bundles.some((t) => t?.sk === v.sk && t.selected)
        );

        if (customerHasActiveFilter) return customer;
      }

      return false;
    });

    if (filteredList.length > 0) {
      setFilteredCustomers(filteredList);
    } else {
      setFilteredCustomers(customers);
    }
  }, [customers, bundles, tags, setFilteredCustomers]);

  React.useEffect(() => {
    handleTagFiltering();
  }, [handleTagFiltering]);

  useKeypress("Escape", () => {
    handleHideCreateCustomer();
  });

  const handleFiltering = (event: React.FormEvent<HTMLInputElement>) => {
    //@ts-ignore
    const value = event.target.value;
    setFilters(value);
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          icon="arrow"
          onClick={() => handleShowCreateCustomer()}
        >
          Add a Customer
        </Button>
        <Button disabled className="ml-4">
          Upload a CSV
        </Button>
        <Link to="/tags" className="ml-8 text-blue-800">
          Manage Tags
        </Link>
      </div>
      {customers.length > 0 && (
        <>
          <div className="flex">
            <input
              className="my-3 mr-3"
              type="text"
              autoFocus
              placeholder="Find a customer"
              value={filters}
              onInput={handleFiltering}
            />
            <Button
              variant="simple-action"
              disabled={filters.length === 0}
              onClick={() => setFilters("")}
            >
              Clear
            </Button>
          </div>
          <div className="flex justify-end">
            <Button variant="simple-action" onClick={toggle}>
              {isFiltersOpen ? "Hide" : "Show"} filters
            </Button>
          </div>
          <AnimatePresence>
            {isFiltersOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ type: "tween" }}
              >
                <Filters filters={tags} setFilters={setTags} type="tag" />
                <Filters
                  filters={bundles}
                  setFilters={setBundles}
                  type="bundle"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
      {loading ? (
        <Spinner size="110px" margin={8} />
      ) : (
        <>
          {customers.length >= 1 ? (
            <>
              <CustomersTable
                customers={filteredCustomers!}
                filters={filters}
              />
            </>
          ) : (
            <Paper className="mt-8">
              <div className="p-10 grid grid-cols-1 gap-5">
                <EmptyData headingText="Add a customer">
                  Adding customers allows you to message them about their Pull
                  Box. Over time, I can add more features around customer
                  management. The goal is make expanding relationships with your
                  customers easier.
                </EmptyData>
              </div>
            </Paper>
          )}
        </>
      )}
    </>
  );
};

export default CustomersPage;
