import React from "react";
import { Tab } from "@headlessui/react";

import { PageBanner } from "../components";
import Button from "../components/buttons/Button";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import { InfoBanner } from "../components/InfoBanner";
import ReactSelect from "react-select";
import { TTemplate } from "../types/template";

const DEFAULT_TEMPLATES = [
  {
    id: "1",
    name: "New Comic Release",
    description: "For a new comic release that you want to promote",
    platforms: ["twitter"],
    numPosts: 5,
  },
  // {
  //   id: "2",
  //   name: "Cross Over Event",
  //   description: "Hype a large event, like Dark Crisis or King in Black",
  //   platforms: ["facebook", "instagram"],
  //   numPosts: 3,
  // },
  // {
  //   id: "3",
  //   name: "Magic Card Release",
  //   description: "Box set, new set, or new card release",
  //   platforms: ["facebook", "instagram", "twitter"],
  //   numPosts: 7,
  // },
] as TTemplate[];

export default function Campaigns() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const tabs =
    "text-xl py-1 px-2 rounded-t bg-white border-2 border-indigo-200 ui-selected:bg-indigo-200 ui-selected:text-gray-900 ui-selected:border-b-transparent border-b-transparent text-gray-400 font-light border-b-white translate-y-0.5";

  return (
    <>
      <PageBanner heading="Campaigns">
        Capture interest in an upcoming release or event.
      </PageBanner>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex justify-between border-b-2 border-indigo-200">
          <Tab className={`${tabs}`}>Current Campaigns</Tab>
          <Tab className={`${tabs}`}>Create a Campaign</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="py-4">
            You don't have any current campaigns. Create one to get started.
          </Tab.Panel>
          <Tab.Panel className="py-4">
            <CreateCampaign onSelect={setSelectedIndex} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

export function CreateCampaign({
  onSelect,
}: {
  onSelect: (arg: number) => void;
}) {
  // const handleCreateCampaign = () => {
  //   console.log("Create Campaign");
  // };

  return (
    <div className="flex flex-col justify-center w-full h-full">
      <InfoBanner storageKey="create-template">
        Create or a template to promote a comic or event. This template will be
        available as a starting point for each campaign.
      </InfoBanner>

      <div className="grid grid-cols-6 gap-4 w-full mb-4">
        {DEFAULT_TEMPLATES.map((template) => (
          <TemplateCard
            key={template.id}
            template={template}
            onSelect={onSelect}
          />
        ))}
      </div>
      <Paper className="w-full">
        <PaperHeader>
          <span className="">Create a template</span>
        </PaperHeader>
        <div className="p-4">
          <form
            onChange={(e) => {
              e.preventDefault();
              console.log("change");
            }}
          >
            <div className="flex mb-2">
              <label
                htmlFor="name"
                className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
              >
                <span className="text-sm">Template name</span>
                <input type="text" name="name" placeholder="New Series" />
              </label>
              <label
                htmlFor="description"
                className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
              >
                <span className="text-sm">Short description</span>
                <input
                  type="text"
                  name="description"
                  placeholder="New Series"
                />
              </label>
            </div>
            <div className="flex mb-2">
              <label
                htmlFor="platforms"
                className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
              >
                <span className="text-sm">Platforms</span>
                <ReactSelect
                  isMulti
                  options={[
                    { value: "facebook", label: "Facebook" },
                    { value: "twitter", label: "Twitter" },
                    { value: "instagram", label: "Instagram" },
                  ]}
                />
              </label>
              <label
                htmlFor="numPosts"
                className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
              >
                <span className="text-sm">Number of Posts</span>
                <input type="number" />
              </label>
            </div>
            <Button type="submit" variant="submission">
              Submit
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export function TemplateCard({
  template,
  onSelect,
}: {
  template: TTemplate;
  onSelect: (arg: number) => void;
}) {
  return (
    <Paper className="col-span-3">
      <PaperHeader action={() => onSelect(0)} actionText="select">
        {template.name}
      </PaperHeader>
      <p className="p-4 text-sm">{template.description}</p>
    </Paper>
  );
}
