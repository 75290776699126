import React, { useContext } from "react";
import "./App.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useActor } from "@xstate/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Outlet } from "react-router-dom";

import { Header, Sidebar } from "./components";
import { AuthProvider } from "./context/authContext";
import {
  CustomersProvider,
  CustomersContext,
} from "./context/customersContext";
import { HoldFilesProvider } from "./context/holdFilesContext";
import { ProfileProvider } from "./context/profileContext";
import { TitlesProvider } from "./context/titlesContext";
import { GlobalServicesProvider } from "./context/globalServicesContext";

import useShopDetails from "./hooks/useShopDetails";
import CommandPalette from "./components/CommandPalette";
import ErrorBoundary from "./components/ErrorBoundary";

import "@aws-amplify/ui-react/styles.css";

const queryClient = new QueryClient();

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GlobalServicesProvider>
            <CustomersProvider>
              <ProfileProvider>
                <TitlesProvider>
                  <HoldFilesProvider>
                    <div className="flex h-screen overflow-hidden">
                      <Sidebar
                        isSidebarOpen={isSidebarOpen}
                        setIsSidebarOpen={setIsSidebarOpen}
                      />
                      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        {/*  Site header */}
                        <Header
                          isSidebarOpen={isSidebarOpen}
                          setIsSidebarOpen={setIsSidebarOpen}
                        />
                        {/* Content area */}
                        <Main>
                          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <Outlet />
                          </div>
                        </Main>
                      </div>
                      <ToastContainer />
                      <CommandPalette />
                    </div>
                  </HoldFilesProvider>
                </TitlesProvider>
              </ProfileProvider>
            </CustomersProvider>
          </GlobalServicesProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

const Main = ({ children }) => {
  const { shop } = useShopDetails();
  const { customersService } = useContext(CustomersContext);
  const [, send] = useActor(customersService);

  React.useEffect(() => {
    if (shop) {
      send({ type: "fetchCustomers", shop: shop });
    }
  }, [send, shop]);

  return <main>{children}</main>;
};

export default withAuthenticator(
  App,
  {
    formFields: {
      signIn: {
        username: {
          placeholder: "Your email",
        },
      },
    },
    hideSignUp: true,
    usernameAlias: "email",
    className: "h-screen w-screen bg-black p-8",
  },
  [],
  null
);
