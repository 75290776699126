import React from "react";
import { API } from "aws-amplify";
import { createMachine, assign, StateFrom } from "xstate";
import { useActor, useSelector } from "@xstate/react";
import Fuse from "fuse.js";

import useShopDetails from "../hooks/useShopDetails";
import { GlobalServicesContext } from "./globalServicesContext";
import { TTitleTotal } from "../types/titles";

type Context = {
  totals: TTitleTotal[];
  topTen: TTitleTotal[];
  details: {};
  shop?: string;
  errors: string;
};

type Services = {
  getHoldFileDetails: {
    data: [{ sk: string }];
  };
  getTotals: {
    data: TTitleTotal[];
  };
  loadTitlesByPublisher: {
    data: TTitleTotal[];
  };
};

type Events =
  | {
      type: "shop-loaded";
      value?: string;
    }
  | {
      type: "refresh-totals";
    }
  | {
      type: "get-totals-by-publisher";
      selected: string[];
    }
  | {
      type: "get-hold-file-details";
      holdFile: { sk: string };
    };

export const totalsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBcD2yCGAbWBZDAxgBYCWAdmAHQAOYZE5UAxLEatQLRaoYSQDaABgC6iUNVSwSyEqjJiQAD0QBGAKwAWSgGZBGgOwqNKwfoBMATgsqAbABoQAT0QAONZRdmVLmypX6bCxc3CwBfUIc0TBx8YnIqbl5GJgg5KnIAN1QAayoo7DxCUgpKRIYyKARM1AIMGTkhYUaFCSl6+SQlRH1rSjUTFW1tfW01N30XB2cEWy0bFx7tMw0zG0FtfzNwyPQC2OKEnnLmMAAnU9RTmiw6gDNLgFtKfJii+NKjxiqyLNr2xuanVa0lkHVAyhmgjM+ko+jUggsGxctkEULUU1UajMlHm+kENhsagCBjham2IBehTiJTKjA4fEwJBwKTSlGquWeu1e1MOSQq9LAjJw31+dVBAJELUkILkCghNg0Lko1g0pg0Gix8KsGIQnkElHVCIMNn0eKG+nJlP271p-IZGCZsCYZwuV2oN2Q91OTytbxpnztgodwuqf3FIkB4ml7TliAJBom+PxFihViJOrcKj6Zj0RO0GgJcMtXKpBw+vEgTFOYFu1dYHEpkZAwJjnQh6mhBrUwz8ZlGxg0OuMSos+mM-m0Cp8ajCEQpJet-orECYMGQHDYWAgHFuTLAAqFsCbLdBsZmy20sORRvzKjMam7Oqs2N7cKNZqJxeipZtR0ra4bEsOAAI0cDhqAAV2ArASFYM5j2jU820QDQLC0JZTWWYdfAMHVdHceF5lVIxBCJQwvz2P1eWOcCoJguDTidVISnZPIFyo8saMg6DYKIM5YBFGoxQaCNJSBRDZWQ3UbA8DYBw1PsXEVMwdXMGFVhTZFoWhbttAo7ky1tKBaJ4hinRdS5rjuR5OW-RdqLpbj6L4xjBLDESmjEqM2iQ8FECGS8LEJNwDFGUwgp1XTKEEHx1m7NDUKxfSfxKatazgIgmEUWBMGQKgMFuPLTgAChzVEAEomF9HlKDSusiAQnzJL8mZtGRSh7wmRUYo2IxBycRAU2iwIUxsIZkRcfFkvsygLNOJhGplMEugQIIZIfEZCQmNqFh1E0LA8QZJ2MZZEWWcI5zIVA+HgTpqoOKUmuWiEOHsAaEA4dwrG+gkcxMYJ1mmjjaHoRhHqWs8Vh1EwDtsQIsLvJSbC2Od7t-PkoHB1sWuHA1JzxdQiQsbtJnetwcRcNrLDxYmDHvIGaqMg9g1u7yIakvxBi7Ax4t8LwzBU96TCGSgVERSaRnxAWegZwy-wgLHfJW0cDoWAIZ37EbBemfCDXFzQlmCEwbFl9GuLo3j+MV5qVs5nElg0fG+z7dZ+umMwXFhok+xWBZ8U-VH2JquqMut57MXWBMSJGYZRj7DMswMaw8ShDVHcCU2SjmsOzyGfU1AJGLbC1KFSemUdsVjpGlKMVYLtCIA */
  createMachine(
    {
      tsTypes: {} as import("./totalsContext.typegen").Typegen0,
      schema: {
        services: {} as Services,
        context: {} as Context,
        events: {} as Events,
      },
      id: "totalsMachine",
      initial: "pending",
      context: {
        totals: [],
        topTen: [],
        details: {},
        errors: "",
      },
      states: {
        pending: {
          on: {
            "shop-loaded": {
              target: "loading",
              actions: "insertShopIntoContext",
            },
          },
        },
        loading: {
          invoke: {
            src: "getTotals",
            onDone: {
              target: "loaded",
              actions: "addTotalsToContext",
            },
            onError: {
              target: "error",
              actions: "addErrorMessageToContext",
            },
          },
        },
        "loading-details": {
          invoke: {
            src: "getHoldFileDetails",
            onDone: {
              target: "loaded",
              actions: "addHoldFileDetailsToContext",
            },
            onError: {
              target: "error",
              actions: "addErrorMessageToContext",
            },
          },
        },
        loaded: {
          entry: ["setTopTen"],
          on: {
            "refresh-totals": {
              target: "refresh",
            },
            "get-hold-file-details": {
              target: "loading-details",
            },
            "get-totals-by-publisher": {
              target: "loading-publishers",
            },
          },
        },
        "loading-publishers": {
          invoke: {
            src: "loadTitlesByPublisher",
            onDone: {
              target: "loaded",
              actions: "addTotalsToContext",
            },
            onError: {
              target: "error",
            },
          },
        },
        refresh: {
          exit: ["clearDetails"],
          after: {
            2000: { target: "loading" },
          },
        },
        error: {
          always: {
            target: "loaded",
          },
        },
      },
    },
    {
      services: {
        loadTitlesByPublisher: async (context, event) => {
          const { totals } = await API.get("customers", `/totals`, {
            queryStringParameters: {
              shop: context.shop,
              selected: event.selected.join(","),
            },
          });

          return totals;
        },
        getTotals: async (context) => {
          if (context.shop) {
            const { totals } = await API.get("customers", `/totals`, {
              queryStringParameters: {
                shop: context.shop,
              },
            });

            return totals;
          }

          return [];
        },
        getHoldFileDetails: async (context: any, event) => {
          const itemKey = event.holdFile.sk.replace("#HOLDFILE-", "");
          if (!context.details[itemKey]) {
            const { customersWithHoldFile } = await API.get(
              "customers",
              `/totals/detail`,
              {
                queryStringParameters: {
                  shop: context.shop,
                  holdFile: event.holdFile.sk,
                },
              }
            );

            return customersWithHoldFile;
          }

          return [{ sk: "skip" }];
        },
      },
      actions: {
        setTopTen: assign({
          topTen: (context) => {
            const list = Array.from(context.totals);
            const sortedList = list.sort((a, b) => {
              const aVal = a.tally ? a.tally : 0;
              const bVal = b.tally ? b.tally : 0;
              return aVal > bVal ? -1 : 1;
            });

            return sortedList.slice(0, 10);
          },
        }),
        addTotalsToContext: assign({
          totals: (_, event) => {
            return event.data;
          },
        }),
        addHoldFileDetailsToContext: assign({
          details: (context, event) => {
            const holdFileKey = event.data[0]?.sk?.split("INSTANCE")[0];
            if (holdFileKey === "skip") {
              return { ...context.details };
            }

            return { ...context.details, ...{ [holdFileKey]: event.data } };
          },
        }),
        addErrorMessageToContext: assign({
          errors: (_) =>
            "Please reload the page, something didn't load properly",
        }),
        clearDetails: assign({
          details: (_) => ({ ...{} }),
        }),
        insertShopIntoContext: assign({
          /*
           * the value is {type: "shop-loaded", event: {value: someCognitoId}}
           */
          shop: (_, { value }) => {
            return value;
          },
        }),
      },
    }
  );

interface ITotalsContext {
  totals?: string[];
}

const defaultState = {
  totals: [],
};

export const TotalsContext = React.createContext<ITotalsContext>(defaultState);

export const useTotals = () => {
  const { totalsService } = React.useContext(GlobalServicesContext);
  const [state, send] = useActor(totalsService);

  const { shop } = useShopDetails();
  React.useEffect(() => {
    if (shop) {
      send({
        type: "shop-loaded",
        value: shop,
      });
    }
  }, [send, shop]);

  const totals = useSelector(totalsService, totalsSelector);
  const topTen = useSelector(totalsService, topTenSelector);
  const isTotalsLoaded = useSelector(totalsService, totalsLoadedSelector);
  const details = useSelector(totalsService, detailsSelector);
  const [totalsFilters, setTotalsFilters] = React.useState<string>("");
  const [filteredTotals, setFilteredTotals] = React.useState<TTitleTotal[]>([]);

  const [activeTotals, setActiveTotals] = React.useState<TTitleTotal[]>([]);

  React.useEffect(() => {
    setActiveTotals(totals.filter((t) => t.active));
  }, [totals]);

  React.useEffect(() => {
    const fuse = new Fuse(activeTotals, {
      keys: ["holdFile"],
    });

    if (!totalsFilters) {
      setFilteredTotals(activeTotals);
    } else {
      const results = fuse.search(totalsFilters);
      setFilteredTotals(results.map((r) => r.item));
    }
  }, [totalsFilters, activeTotals]);

  const handleRefreshTotals = React.useCallback(() => {
    send("refresh-totals");
  }, [send]);

  const handleLoadDetails = React.useCallback(
    (item: TTitleTotal) => {
      send({ type: "get-hold-file-details", holdFile: { sk: item.sk } });
    },
    [send]
  );

  const handleLoadPublisher = React.useCallback(
    (publisher: string) => {
      send({ type: "get-totals-by-publisher", selected: [publisher] });
    },
    [send]
  );

  const handleFilterDistributor = React.useCallback(
    (distributor: "prh" | "diamond" | "lunar" | "other" | "") => {
      if (!distributor) {
        setFilteredTotals(activeTotals);
        return;
      }

      const filteredByDist = activeTotals.filter((t) => {
        return (
          t.distributors && t.distributors.some((d) => d.value === distributor)
        );
      });

      setFilteredTotals(filteredByDist);
      return;
    },
    [activeTotals]
  );

  const ready = state.matches("loaded") || state.matches("loading-details");
  const isDetailsLoading = state.matches("loading-details");

  return {
    details,
    filteredTotals,
    handleFilterDistributor,
    handleLoadDetails,
    handleLoadPublisher,
    handleRefreshTotals,
    isDetailsLoading,
    isTotalsLoaded,
    ready,
    setTotalsFilters,
    totalsFilters,
    topTen,
    totals,
  };
};

const totalsSelector = (state: StateFrom<typeof totalsMachine>) =>
  state.context.totals;

const topTenSelector = (state: StateFrom<typeof totalsMachine>) =>
  state.context.topTen;

const detailsSelector = (state: StateFrom<typeof totalsMachine>) =>
  state.context.details;

const totalsLoadedSelector = (state: StateFrom<typeof totalsMachine>) =>
  state.matches("loaded") || state.matches("loading-details");
