import { API } from "aws-amplify";
import { Link } from "react-router-dom";

import { useProfileMachine } from "../context/profileContext";
import { PageBanner } from "../components";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";

import getGreetingTime from "../utils/getGreetingTime";
import { useTotals } from "../context/totalsContext";
import EmptyData from "../components/organisms/EmptyData";
import Spinner from "../components/Spinner";
import AccordionItem from "../components/AccordionItem";
import useShopDetails from "../hooks/useShopDetails";
import { useQuery } from "@tanstack/react-query";
import { Messages, NoMessages } from "../components/Messages";
import { createDetailFromData } from "../utils/createDetailFromData";
import { THoldFile } from "../types/holdFile";
import { ChangesGraph } from "../components/ChangesGraph";
import { useTitles } from "../context/titlesContext";
import { TMovement } from "../types/movement";
import { getTitleMovement } from "./title";
import { useMessages } from "../hooks/messages/useMessages";

export const COLORS = [
  "rgb(79 70 229)",
  "rgb(59 130 246 / 0.5)",
  "rgb(17 24 39 / 0.5)",
  "rgb(22 163 74 / 0.5)",
  "rgb(59 130 246)",
  "rgb(22 163 74)",
  "rgb(79 70 229 / 0.5)",
];

// const getMessages = async (shop: string) => {
//   const messages = await API.get("customers", `/messages?shop=${shop}`, {});
//   return messages;
// };

// const useMessages = () => {
//   const { shop } = useShopDetails();
//   const query = useQuery(["messages"], () => getMessages(shop!), {
//     enabled: !!shop,
//   });

//   const messages = query.data?.messaging || [];
//   return {
//     messages,
//     isLoading: query.isLoading,
//   };
// };

const getTopFiveMovers = async (
  shop: string,
  title: string,
  window?: string
) => {
  const movement = await API.get(
    "customers",
    `/movement?shop=${shop}&title=${title}&window=${window || "42"}`,
    {}
  );
  return movement;
};

export const useMovement = (title?: string) => {
  const { shop } = useShopDetails();
  const titleQuery = useQuery({
    queryKey: [`title-${title}`],
    queryFn: () => getTitleMovement(shop!, title!),
    enabled: !!shop && !!title,
    staleTime: 1000 * 60 * 60 * 24,
  });
  const query = useQuery({
    queryKey: ["top5"],
    queryFn: () => getTopFiveMovers(shop!, "topFive"),
    enabled: !!shop,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const movement = (query.data?.data || []) as TMovement[];
  return {
    ...titleQuery.data,
    movement,
    isTitleLoading: titleQuery.isLoading,
    isMovementLoading: query.isLoading,
  };
};

export default function Dashboard() {
  const {
    details,
    handleLoadDetails,
    handleRefreshTotals,
    isDetailsLoading,
    isTotalsLoaded,
    topTen,
    totals,
  } = useTotals();
  const { titles, loading } = useTitles();
  const { profile, isProfileLoaded } = useProfileMachine();
  const { messages, isLoading } = useMessages();
  const { movement, isMovementLoading } = useMovement();
  const handleRefreshMessages = () => {};

  const hasNoHoldFiles = totals.length === 0;

  return (
    <>
      {profile?.contactName ? (
        <PageBanner
          heading={`Good ${getGreetingTime()}, ${profile.contactName}! 👋`}
        >
          Here is some information about your store:
        </PageBanner>
      ) : (
        <PageBanner heading={`Welcome!`}>
          {isProfileLoaded ? (
            <>
              <p>
                In order to have emails sent from the correct name, you'll want
                to{" "}
                <Link className="underline" to="/profile">
                  update your profile
                </Link>
                .
              </p>
            </>
          ) : (
            <Spinner size="8px" alternate margin={0} />
          )}
        </PageBanner>
      )}

      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <Paper>
          <PaperHeader
            actionText={"Refresh messages"}
            action={handleRefreshMessages}
          >
            Messages
          </PaperHeader>
          <div className="p-3 my-1">
            {isLoading ? (
              <Spinner size="36px" />
            ) : messages.length === 0 ? (
              <NoMessages />
            ) : (
              <Messages messages={messages} />
            )}
          </div>
        </Paper>
        <Paper>
          <PaperHeader>Top Movers</PaperHeader>
          <div className="p-3 my-1">
            {isMovementLoading || loading ? (
              <Spinner size="48px" />
            ) : (
              <ChangesGraph
                data={{
                  labels: [
                    "6 weeks ago",
                    "5 weeks ago",
                    "4 weeks ago",
                    "3 weeks ago",
                    "2 weeks ago",
                    "Last Week",
                  ],
                  datasets: movement?.map((d, i) => ({
                    data: d.data,
                    label:
                      titles.find((t) => {
                        return t.sk === d.titleSk;
                      })?.title || "",
                    borderColor: COLORS[i],
                    backgroundColor: COLORS[i],
                  })),
                }}
              />
            )}
          </div>
        </Paper>
        <Paper>
          <PaperHeader actionText={"Refresh list"} action={handleRefreshTotals}>
            Top Ten Titles
          </PaperHeader>
          <div className="p-3">
            <div>
              {isTotalsLoaded ? (
                <>
                  <header className="flex justify-between text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
                    <span>title</span>
                    <span>totals</span>
                  </header>
                  {hasNoHoldFiles ? (
                    <EmptyData>
                      As you add hold files and titles, your title totals will
                      display here. Next up I'll add in how to delete a title
                      globally.
                    </EmptyData>
                  ) : (
                    <ul className="my-1">
                      {topTen.map((item) => {
                        if (!item || !item.holdFile) return null;

                        const itemKey = item.sk.replace("HOLDFILE#", "");
                        //@ts-ignore
                        const customerList = (details[itemKey] ||
                          []) as THoldFile[];
                        const data = customerList
                          .map(createDetailFromData)
                          .sort((a, b) => (a.linkValue > b.linkValue ? 1 : -1));

                        const countsByGroup = data.reduce((acc, customer) => {
                          //@ts-ignore
                          if (acc[customer.label]) {
                            //@ts-ignore
                            acc[customer.label] += 1;
                          } else {
                            //@ts-ignore
                            acc[customer.label] = 1;
                          }
                          return acc;
                        }, {});

                        return (
                          <AccordionItem
                            key={item.sk}
                            actionLink={`/titles/${item.sk.replace(
                              `HOLDFILE#TITLE#`,
                              ""
                            )}`}
                            count={item.tally}
                            countsByGroup={countsByGroup}
                            details={data}
                            getDetails={() => {
                              handleLoadDetails(item);
                            }}
                            isDetailsLoading={isDetailsLoading}
                            title={item.holdFile}
                          />
                        );
                      })}
                    </ul>
                  )}
                </>
              ) : (
                <Spinner margin={14} size="64px" />
              )}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}
