import { useActor, useSelector } from "@xstate/react";
import React from "react";
import { PageBanner } from "../components";
import Spinner from "../components/Spinner";
import { GlobalServicesContext } from "../context/globalServicesContext";
import { selectTags } from "../machines/tagsMachine";
import { TTag } from "../types/tag";

const Tags = () => {
  const { tagsService } = React.useContext(GlobalServicesContext);
  const [state, send] = useActor(tagsService);
  const showSpinner = !state.matches("idle");
  const tags = useSelector(tagsService, selectTags);

  const hasTags = Boolean(tags.length);
  const ref = React.useRef<HTMLInputElement>(null);
  const disabled = Boolean(
    !state.matches("idle") || (ref.current && ref.current.value)
  );

  const handleDelete = (tag: TTag) => {
    send({ type: "delete", tag });
  };

  const handleUpdate = (value: string, tag: TTag) => {
    send({ type: "edit", value, tag });
  };

  const handleCreate = (input: HTMLInputElement) => {
    send({ type: "create", value: input.value });
    if (ref.current) {
      ref.current.value = "";
    }
  };

  return (
    <>
      <PageBanner heading="Create, Edit and Delete Tags">
        Tags can be applied to customers to help you manage messaging and
        viewing groups of customers.
      </PageBanner>
      <h1>Tags</h1>
      <div className="flex">
        <div className="flex-1 p-2 border">
          <ul>
            <li>
              Create a tag
              <label className="ml-2" htmlFor="create">
                <input
                  name="create"
                  type="text"
                  id="yee"
                  defaultValue=""
                  ref={ref}
                />
                <button
                  disabled={disabled}
                  className={`mx-4 text-sm ${
                    disabled ? "text-grey-400" : "text-green-400"
                  }`}
                  onClick={() => {
                    if (ref.current) handleCreate(ref.current);
                  }}
                >
                  Create
                </button>
              </label>
              <hr className="my-4" />
            </li>
            {showSpinner && <Spinner size="84px" />}
            {hasTags &&
              !showSpinner &&
              tags.map((tag) => (
                <EditRow
                  {...tag}
                  key={tag.sk}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                />
              ))}
            {!hasTags && (
              <li>
                <p className="p-4 text-gray-400 font-light">
                  Your tags will appear in a list here!
                </p>
              </li>
            )}
          </ul>
        </div>
        <div className="flex-1 p-2 border">
          {showSpinner ? (
            <Spinner size="84px" />
          ) : (
            <ul>
              {tags.map(({ tag, sk }) => {
                return <li key={sk}>{tag}</li>;
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Tags;

interface EditRowProps extends TTag {
  handleUpdate: (value: string, tag: TTag) => void;
  handleDelete: (item: TTag) => void;
}

const EditRow = ({ pk, sk, tag, handleUpdate, handleDelete }: EditRowProps) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [editing, setEditing] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (ref.current) {
      handleUpdate(ref.current.value, { pk, sk, tag });
    }
  };

  return (
    <li className="flex mb-2" key={sk}>
      <div>
        <form onSubmit={handleSubmit}>
          <label htmlFor={tag}>
            <input
              name={tag}
              type="text"
              id={tag}
              defaultValue={tag}
              ref={ref}
              onChange={() => setEditing(true)}
            />
          </label>
          <button
            className={`mx-4 text-sm text-green-600 ${
              editing ? "" : "opacity-60 pointer-events-none"
            }`}
            disabled={!editing}
            type="submit"
          >
            Update
          </button>
        </form>
      </div>
      <button
        className="mx-4 text-sm text-red-500"
        onClick={() => handleDelete({ pk, sk, tag })}
      >
        Delete
      </button>
    </li>
  );
};
