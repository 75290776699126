import React from "react";
import { PageBanner } from "../components";
import Button from "../components/buttons/Button";
import { Notes } from "../components/Notes";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import Spinner from "../components/Spinner";
import config from "../config";
import { THoldFile } from "../types/holdFile";
import { IProfile } from "../types/profile";

interface IMessage {
  gsi1pk?: string;
  gsi1sk?: string;
  pk?: string;
  sk?: string;
  message?: string;
  status?: string;
  customerId?: string;
}

const env = {
  name: "customers",
  endpoint: config.apiGateway.URL,
  region: config.apiGateway.REGION,
};

const fetchCustomerHoldFile = async () => {
  const response = await fetch(
    `${env.endpoint}/public/hold-file?hash=${window.location.pathname}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
};

const postToShop = async (reply: string, message: {}) => {
  await fetch(
    `${env.endpoint}/public/hold-file?hash=${window.location.pathname}`,
    {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reply, message, status: "REPLIED" }),
    }
  );
};

export default function Public() {
  const [loading, setLoading] = React.useState(true);
  const [holdFiles, setHoldFiles] = React.useState<THoldFile[]>([]);
  const [message, setMessage] = React.useState<IMessage>({});
  const [profile, setProfile] = React.useState<IProfile>({});
  const [reply, setReply] = React.useState("");
  const [hasReplied, setHasReplied] = React.useState(false);

  React.useEffect(() => {
    fetchCustomerHoldFile().then((response) => {
      //   //@ts-ignore
      setHoldFiles(response.holdFile);
      setProfile(response.profile as IProfile);
      setMessage(response.message as IMessage);
      setHasReplied(response.message.status === "REPLIED");
      setLoading(false);

      if (response.message.status === "REPLIED") {
        setReply(response.message.reply);
      }
    });
  }, [setHoldFiles, setLoading]);

  const handleReply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postToShop(reply, message);
    setHasReplied(true);
  };

  return (
    <main>
      {loading ? (
        <Spinner size="148px" />
      ) : (
        <>
          <PageBanner
            heading={`Messages from ${profile.shopName}`}
            className="shadow-md"
          >
            You can see your pulls and reply to your most recent message here.
          </PageBanner>
          <article className="px-4 sm:px-6">
            <>
              <div>
                <h2 className="text-2xl ">Recent Message:</h2>
                <div className="md:columns-2">
                  <section className="border rounded p-4 mb-4 md:mb-0">
                    We said:
                    <p className="italic">{message.message}</p>
                  </section>
                  <section className="border rounded p-4">
                    {hasReplied ? (
                      <p>
                        You replied: <br />
                        <br />
                        {reply}
                      </p>
                    ) : (
                      <form className="flex flex-col" onSubmit={handleReply}>
                        <textarea
                          className="mb-2"
                          placeholder="Write a reply..."
                          onChange={(e) => setReply(e.target.value)}
                        />
                        <Button disabled={reply.length < 3} type="submit">
                          Reply
                        </Button>
                      </form>
                    )}
                  </section>
                </div>
              </div>
              <hr className="mt-8 pb-8" />
              <Paper>
                <PaperHeader>Your Hold File</PaperHeader>
                <div className="p-3">
                  <div>
                    <header className="flex justify-between text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
                      Hold File <span className="mr-24 pr-2">Notes</span>
                    </header>
                    <ul className="my-1">
                      {holdFiles
                        .sort((a, b) => (a.active === b.active ? 1 : -1))
                        .map((listItem, index) => {
                          return (
                            <li
                              key={`${listItem.sk}}`}
                              className={`flex px-2 ${
                                index % 2 !== 0 ? "bg-gray-50" : ""
                              }`}
                            >
                              <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
                                <div className="flex-grow flex justify-between">
                                  <div className="self-center">
                                    <span
                                      className={`font-medium text-gray-800 hover:text-gray-900 ${
                                        listItem.active ? "" : "opacity-30"
                                      }`}
                                    >
                                      {listItem.holdFile}
                                    </span>
                                  </div>
                                  <div className="flex-shrink-0 self-start ml-2">
                                    <Notes notes={listItem.notes || ""} />
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="flex justify-between px-2 pt-2 print:hidden">
                    <button
                      className="text-sm text-green-600"
                      onClick={() => window.print()}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </Paper>
            </>
          </article>
        </>
      )}
    </main>
  );
}
