import React from "react";
import useKeypress from "../hooks/useKeypress";
import { IProfile } from "../types/profile";
import ActionPlusButton from "./buttons/ActionPlusButton";
import Button from "./buttons/Button";
import InputModal from "./InputModal";
import ModalHeader from "./ModalHeader";
import EmptyData from "./organisms/EmptyData";
import Spinner from "./Spinner";

interface IProfileProps extends IProfile {
  location?: undefined | string;
}

type Props = {
  hasLocations: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  profile: IProfileProps | undefined;
  send: (event: any) => void;
};

const LocationInput: React.FC<Props> = ({
  hasLocations,
  isDeleting,
  isLoading,
  isSubmitting,
  profile,
  send,
}) => {
  const [enterLocations, setEnterLocations] = React.useState(hasLocations);
  React.useEffect(() => {
    setEnterLocations(hasLocations);
  }, [hasLocations]);
  const [isShowingInputModal, setIsShowingInputModal] = React.useState(false);
  useKeypress("Escape", () => {
    setIsShowingInputModal(false);
  });

  const handleToggleInputModal = () => {
    setIsShowingInputModal(true);
  };

  if (isLoading) {
    return <Spinner size="16px" margin={2} />;
  }

  return (
    <>
      {enterLocations ? (
        <div className="pb-10 grid grid-cols-1 gap-5">
          {isSubmitting || isDeleting ? (
            <Spinner size="48px" margin={2} />
          ) : (
            <ul className="my-1">
              {profile?.locations?.map((storeLocation, index) => (
                <li
                  key={storeLocation}
                  className={`flex p-2 ${index % 2 === 0 ? "bg-gray-50" : ""}`}
                >
                  <div className="flex-grow flex justify-between">
                    <div className="self-center">
                      <span className="font-medium text-gray-800 hover:text-gray-900">
                        {storeLocation}
                      </span>
                    </div>
                    <div className="ml-8">
                      <button
                        className="font-medium text-red-400 hover:text-red-700"
                        onClick={() =>
                          send({ type: "delete", value: storeLocation })
                        }
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="flex justify-end">
            <ActionPlusButton
              disabled={false}
              send={() => setIsShowingInputModal(true)}
              sendType={undefined}
            />
          </div>
        </div>
      ) : (
        <div className="p-10 grid grid-cols-1 gap-5">
          <EmptyData action={handleToggleInputModal} actionText="Add locations">
            If your store has multiple locations, we support that. Just add in
            whatever names you like for your locations. On each customer, you'll
            then get the ability to assign a customer to a store.
          </EmptyData>
        </div>
      )}
      {isShowingInputModal && (
        <InputModal
          onCloseModal={(e) => {
            //@ts-ignore
            if (e.target.name !== "location") {
              e.preventDefault();
              setIsShowingInputModal(false);
              setEnterLocations(true);
              send("submit");
            }
          }}
        >
          <ModalHeader>Add a location</ModalHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const form = new FormData(e.currentTarget as HTMLFormElement);
              const location = form.get("location") as string;
              if (location) {
                send({ type: "edit", name: "location", value: location });
                send({ type: "submit" });
              }

              setIsShowingInputModal(false);
            }}
            className="flex justify-around py-8"
          >
            <label htmlFor="location" className="">
              <input
                autoFocus
                aria-label="location"
                name="location"
                placeholder="Enter a location"
                className="font-medium text-gray-800 w-52 pl-4"
              />
            </label>
            <Button
              icon="plus"
              variant="secondary"
              type="submit"
              disabled={!Boolean(profile?.location?.length)}
            >
              Submit
            </Button>
          </form>
        </InputModal>
      )}
    </>
  );
};

export default LocationInput;
