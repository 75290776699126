interface Props {
  children: React.ReactNode;
  onCloseModal: (
    e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
  ) => void;
}

const InputModal = ({ children, onCloseModal }: Props) => {
  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      id="modal"
      data-testid="modal"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (e.currentTarget.id === "modal") {
          onCloseModal(e);
        }
      }}
    >
      <div className="relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white">
        <div className="flex justify-end text-gray-500">
          <button onClick={onCloseModal}>
            <svg
              id="close-icon"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                id="close-icon"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="mt-3">{children}</div>
      </div>
    </div>
  );
};

export default InputModal;
