import { useActor } from "@xstate/react";
import React from "react";
import ReactSelect, { createFilter } from "react-select";

import { GlobalServicesContext } from "../context/globalServicesContext";
import { PaletteOption, Tags } from "../machines/commandPaletteMachine";
import useKeypress from "../hooks/useKeypress";
import Paper from "./paper/Paper";

const CustomOptionLabel = ({ value, label, tag }: PaletteOption) => {
  const getTagColor = (tag: Tags) => {
    switch (tag) {
      case "Customers":
        return "bg-indigo-300";
      case "Titles":
        return "bg-violet-400";
      case "Account":
        return "bg-blue-200";
      case "Orders":
        return "bg-green-300";

      default:
        return "";
    }
  };

  return (
    <div className="flex">
      <span className={`p-1 ${getTagColor(tag)}`}>{tag}</span>
      <p className="p-1 ml-2">{label}</p>
      <p className="p-1 ml-2 text-gray-600">{value}</p>
    </div>
  );
};

const CommandPalette = () => {
  const { commandPaletteService } = React.useContext(GlobalServicesContext);
  const [state, send] = useActor(commandPaletteService);
  const visibleOptions = state.context.commands.filter(
    (command) => command.visible
  );

  useKeypress("Escape", () => {
    send("close");
  });

  React.useEffect(() => {
    function keyDown(e: KeyboardEvent) {
      if (e.ctrlKey && e.key === "k") {
        send("open");
      }
    }

    window.addEventListener("keyup", keyDown);
    return () => window.removeEventListener("keyup", keyDown);
  }, [send]);

  return state.matches("open") ? (
    <div
      data-testid="modal"
      className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 pt-14 px-20"
      id="modal"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (event.currentTarget.id === "modal") {
          send("close");
        }
      }}
    >
      <Paper className="h-72">
        <p className="text-sm text-right mr-6 text-gray-500">
          Open this menu with keyboard shortcut crtl + k
        </p>
        <div className="p-4 flex">
          <div className="flex-1">
            <ReactSelect
              styles={{
                menuList: () => ({
                  padding: 0,
                }),
              }}
              placeholder="Type something"
              autoFocus
              filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: "any",
                stringify: (option) => {
                  return `${option.label} ${option.value} ${option.data.tag}`;
                },
                trim: true,
              })}
              formatOptionLabel={CustomOptionLabel}
              menuIsOpen
              options={visibleOptions}
              onChange={(selection) => {
                if (selection?.action) {
                  selection.action();
                  send("close");
                }
              }}
              onInputChange={(event) => {
                if (!event) {
                  send("reset");
                }
                send({ type: "input", value: event });
              }}
            />
          </div>
          <button
            className="pl-10 relative"
            data-testid="close-palette"
            onClick={() => {
              send("close");
            }}
          >
            <svg
              id="close-icon"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 bottom-7 left-6 absolute opacity-60"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                id="close-icon"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </Paper>
    </div>
  ) : null;
};

export default CommandPalette;
