/**
 * Returns formatted phone
 * ex: (610) 322-6109
 *
 * @param {*} phone
 *
 * @return string
 */
const renderPhone = (phone: string) => {
  if (!phone) return "";

  const cleanPhone = phone.replace(/[^0-9]/g, "");
  const match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
  const resolvedMatch = match || "";

  return (
    "(" + resolvedMatch[1] + ") " + resolvedMatch[2] + "-" + resolvedMatch[3]
  );
};

export default renderPhone;
