import React, { FormEvent } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { PageBanner } from "../components";
import Button from "../components/buttons/Button";
import InputModal from "../components/InputModal";
import ModalHeader from "../components/ModalHeader";
import ReactSelect from "react-select";
import useKeypress from "../hooks/useKeypress";
import useShopDetails from "../hooks/useShopDetails";
import { useTitles } from "../context/titlesContext";
import { toast } from "react-toastify";
import { useBundles } from "../machines/bundlesMachine";

export default function Bundles() {
  const { shop } = useShopDetails();
  const navigate = useNavigate();
  const { titles } = useTitles();
  const { handleHideBundle, handleCreateBundle, isBundlesVisible } =
    useBundles();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectRef = React.useRef(null);
  useKeypress("Escape", () => handleHideBundle());

  const handleBundleCreate = (e: FormEvent) => {
    e.preventDefault();
    const bundleName = inputRef?.current?.value;
    //@ts-ignore - react-select types are not obvious
    const bundle = selectRef?.current?.state?.selectValue;
    const disabled = bundle.length >= 25;
    if (!bundleName) {
      toast.error("Please enter a bundle name");
      return;
    }
    if (disabled) {
      toast.error("Too many titles to create");
      navigate("/titles");
    } else {
      shop && handleCreateBundle({ bundle, bundleName, shop });
    }
  };

  return (
    <>
      <PageBanner heading="Manage your bundles">
        Edit what series are applied when you apply a bundle to a customer
      </PageBanner>
      <Outlet />
      {isBundlesVisible && (
        <InputModal
          onCloseModal={() => {
            handleHideBundle();
          }}
        >
          <ModalHeader>Manage Bundles</ModalHeader>
          <p className="text-center text-gray-500">
            The current title max for a bundle is 25
          </p>
          <form
            onSubmit={handleBundleCreate}
            className="flex-1"
            onClick={(e) => e.stopPropagation()}
          >
            <label
              htmlFor="name"
              className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
            >
              <span>Name of your bundle</span>
              <input
                autoFocus
                type="text"
                name="name"
                ref={inputRef}
                placeholder="All Spider-man"
              />
            </label>
            <ReactSelect
              className="ml-2 mt-4"
              isMulti
              options={titles.map((series) => ({
                ...series,
                label: series.title,
                value: series.sk,
              }))}
              ref={selectRef}
            />
            <Button variant="secondary" className="mt-8 ml-2" type="submit">
              Create Bundle
            </Button>
          </form>
        </InputModal>
      )}
    </>
  );
}
