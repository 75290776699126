import React from "react";

import CustomerActionRow from "./CustomerActionRow";
import { useProfileMachine } from "../context/profileContext";
import { useBroadcastMachine } from "../machines/broadcastMachine";
import { TCustomer } from "../types/customer";
import Button from "./buttons/Button";
import LocationVisibility from "./locations/LocationVisibility";
import { Modal } from "./Modal";
import ModalHeader from "./ModalHeader";

type Props = {
  customers: TCustomer[];
  filters: string;
};

const CustomersTable: React.FC<Props> = ({ customers, filters }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    handleBeginSendMessage,
    handleNeverMind,
    handleSendMessage,
    handleToggleCustomer,
    hasSelectedCustomers,
    isUpdating,
    selectedCustomers,
  } = useBroadcastMachine();
  const { locations } = useProfileMachine();
  const [hiddenLocations, setHiddenLocations] = React.useState<string[]>([]);

  return (
    <>
      <div className="flex flex-row items-end justify-between">
        <LocationVisibility
          locations={locations}
          setHiddenLocations={setHiddenLocations}
        />
        <div className="flex flex-col">
          <Button onClick={() => setIsOpen(true)} variant="informational">
            Learn about broadcasting
          </Button>
          <Button
            icon="arrow"
            onClick={handleBeginSendMessage}
            disabled={!hasSelectedCustomers}
          >
            Bulk Message
          </Button>
        </div>
      </div>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2 w-1/2 text-gray-800">Name</th>
            <th className="px-4 py-2 w-1/4 text-gray-800">Email</th>
            <th className="px-4 py-2 w-1/4 text-gray-800">Phone</th>
            <th className="px-4 py-2 w-1/2 text-gray-800">Broadcast</th>
          </tr>
        </thead>
        <tbody>
          {customers
            .filter((c) => !hiddenLocations.includes(c.storeLocation))
            .map((customer, index) => {
              return (
                <CustomerActionRow
                  filters={filters}
                  key={customer?.customerId + `${index}`}
                  customer={customer}
                  checked={selectedCustomers.some(
                    (item) => item.customerId === customer.customerId
                  )}
                  handleToggleCustomer={handleToggleCustomer}
                />
              );
            })}
        </tbody>
      </table>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader>Bulk Messaging:</ModalHeader>
        <p>
          Select customers using the checkboxes to the right of their names.
          Once you've chosen the customers you want to bulk message, click the
          button below.
        </p>
        <p>
          You'll get a modal with the message you want to send and the option to
          "send message".
        </p>
        <p>
          This is designed for sending a message like: "We're having a Magic
          Event this Friday at Grand Slam in Loveland, please come!"
        </p>
      </Modal>
      {isUpdating && (
        <BulkMessageModal
          handleNeverMind={handleNeverMind}
          isUpdating={isUpdating}
          selectedCustomers={selectedCustomers}
          handleSendMessage={handleSendMessage}
        />
      )}
    </>
  );
};

interface IMessageModal {
  isUpdating: boolean;
  handleNeverMind: () => void;
  selectedCustomers: TCustomer[];
  handleSendMessage: (message: string) => void;
}

const BulkMessageModal = ({
  handleNeverMind,
  handleSendMessage,
  isUpdating,
  selectedCustomers,
}: IMessageModal) => {
  const [message, setMessage] = React.useState("");

  return (
    <Modal isOpen={isUpdating} onClose={() => handleNeverMind()}>
      <ModalHeader>
        <div>
          Create Bulk Message:
          <span className="block text-xs">
            Message {selectedCustomers.length} customers
          </span>
        </div>
      </ModalHeader>
      <div>
        <label className="flex">
          Message:
          <textarea
            autoFocus
            rows={3}
            maxLength={160}
            className="ml-4 w-full"
            onChange={(e) => {
              const inputMessage = e.target.value;
              const message = inputMessage.slice(0, 160);
              setMessage(message);
            }}
          />
        </label>
        <p className="text-right text-xs text-gray-600 mt-2">
          Character limit:
          <span className={message.length > 140 ? "text-red-600" : ""}>
            {160 - message.length}
          </span>
          /160
        </p>
        <div className="mt-4 text-right">
          <Button
            variant="outlined"
            className="mr-4"
            onClick={() => handleNeverMind()}
          >
            Never mind
          </Button>
          <Button
            variant="submission"
            onClick={() => handleSendMessage(message)}
          >
            Send!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomersTable;
