import React from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { useActor } from "@xstate/react";
import { toast } from "react-toastify";

import useKeypress from "../hooks/useKeypress";

import { CustomersContext } from "../context/customersContext";
import dateFormat from "../utils/dateFormat";
import renderPhone from "../utils/renderPhone";
import CustomerInterests from "../components/customerInterests/CustomerInterests";
import Spinner from "../components/Spinner";
import Button from "../components/buttons/Button";
import { TCustomer } from "../types/customer";
import { IMessage, MESSAGE_STATE } from "../types/messages";
import { Modal } from "../components/Modal";
import { useBoolean } from "usehooks-ts";
import { useCustomerMessages } from "../hooks/messages/useMessages";

type TOutletContext = {
  handleEditCustomer: (customer: TCustomer) => void;
  handleDeleteCustomer: (customer: TCustomer) => void;
  handleHideCreateCustomer: () => void;
};

export default function Customer() {
  const { handleEditCustomer, handleDeleteCustomer, handleHideCreateCustomer } =
    useOutletContext<TOutletContext>();

  const { customersService } = React.useContext(CustomersContext);
  const [customersState, customersSend] = useActor(customersService);
  const params = useParams();
  const customer = customersState.context.customers.find(
    ({ customerId }) => `${customerId}` === params.customerId
  );
  const navigate = useNavigate();

  useKeypress("Escape", () => handleHideCreateCustomer());

  return customersState.matches("loading") || !customer ? (
    <Spinner size="32px" />
  ) : (
    <div>
      <div className="flex justify-between print:hidden">
        <Button
          variant="contained"
          icon="arrow"
          onClick={() => handleEditCustomer(customer)}
        >
          Edit Customer Details
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            handleDeleteCustomer(customer);
            customersSend({
              type: "delete_customer_by_id",
              customer,
            });
            toast("Customer deleted");
            navigate("/customers");
          }}
        >
          Delete Customer
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-full 2xl:col-span-6">
          <div className="mb-6 print:hidden">
            <h2 className="mt-8 text-xl flex justify-between align-middle">
              {customer.firstName} {customer.lastName}
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">
                  email: {customer.email}
                </span>
                <span className="text-sm text-gray-500">
                  phone: {renderPhone(customer.phone)}
                </span>
                {customer.addresses && customer.addresses.length > 0 && (
                  <span className="text-sm text-gray-500">
                    address:{" "}
                    <span className="text-right">
                      {customer.addresses[0].addressLine}
                    </span>
                    <div className="flex flex-col items-end">
                      <span>{customer.addresses[0].addressLineTwo}</span>
                      <span>{customer.addresses[0].city}</span>
                      <span>
                        {customer.addresses[0].state},{" "}
                        {customer.addresses[0].zipCode}
                      </span>
                    </div>
                  </span>
                )}
              </div>
            </h2>
            {customer.storeLocation && (
              <p className="pl-2">
                Location:{" "}
                <span className="text-sm text-gray-500">
                  {customer.storeLocation}
                </span>
              </p>
            )}
            <p className="pl-2">
              Customer since:{" "}
              <span className="text-sm text-gray-500">
                {customer.createdAt
                  ? dateFormat(customer.createdAt)
                  : "Just now"}
              </span>
            </p>
            <p className="pl-2">
              Last updated:{" "}
              <span className="text-sm text-gray-500">
                {customer.createdAt
                  ? dateFormat(customer.createdAt)
                  : "Just now"}
              </span>
            </p>
            {customer.note && (
              <p className="pl-2">
                Note:{" "}
                <span className="text-sm text-gray-500">{customer.note}</span>
              </p>
            )}
          </div>

          {customer.tags && customer.tags.length > 0 ? (
            <div className="flex mb-2 print:hidden items-center">
              <span className="mr-4">Tags:</span>
              {customer?.tags?.map(({ sk, tag }) => {
                return (
                  <div
                    key={sk}
                    className="flex border-b border-indigo-200 py-1 px-2 mr-2"
                  >
                    <span>{tag}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
          {customer.bundles && customer.bundles.length > 0 ? (
            <div className="flex mb-2 print:hidden items-center">
              <span className="mr-4">Bundles:</span>
              {customer?.bundles?.map(({ sk, label }) => {
                return (
                  <div
                    key={sk}
                    className="flex border-b border-indigo-200 py-1 px-2 mr-2"
                  >
                    <span>{label}</span>
                  </div>
                );
              })}
            </div>
          ) : null}

          <CustomerInterests customer={customer} />
          <MessageHistory customer={customer} />
        </div>
      </div>
    </div>
  );
}

const MessageHistory = ({ customer }: { customer: TCustomer }) => {
  const { messages, isLoading } = useCustomerMessages(customer);

  return (
    <div className="mt-8 col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 print:hidden">
      <div className="p-3">
        <div>
          <header className="flex justify-between text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
            Message History
          </header>
          {isLoading && <Spinner size="48px" />}
          <ul className="my-1"></ul>
          {messages.map((message, index) => (
            <MessageHistoryRow key={index} message={message} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

const MessageHistoryRow = ({
  message,
  index,
}: {
  message: IMessage;
  index: number;
}) => {
  const messageModal = useBoolean(false);
  const isCustomMessage = Boolean(Object(message).hasOwnProperty("message"));

  // @ts-ignore
  const key = message.lastMessageSentDate + message.message;
  const text =
    "message" in message
      ? message.message
      : new Date(message.lastMessageSentDate).toLocaleDateString();
  const reply = "message" in message ? message.reply : null;

  return (
    <li
      key={key}
      className={`flex px-2 ${index % 2 !== 0 ? "bg-gray-50" : ""}`}
    >
      <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
        <span
          className={`font-extralight text-xs mr-2 p-1 rounded-sm w-14 text-center ${
            MESSAGE_STATE[message.messageState]
          }`}
        >
          {message?.messageState?.toLowerCase()}
        </span>
        <div className="flex-grow flex justify-between">
          <span className="font-medium text-gray-800">
            {isCustomMessage ? "Custom Message" : "Order fulfillment message"}
          </span>
          <span className="font-medium text-gray-800">
            {reply ? (
              <>
                <button
                  className="text-xs font-light text-indigo-400 mr-4"
                  onClick={messageModal.setTrue}
                >
                  View
                </button>
                <span className="ml-2">{reply}</span>
              </>
            ) : (
              <span>{text}</span>
            )}
          </span>
        </div>
      </div>
      <Modal isOpen={messageModal.value} onClose={messageModal.setFalse}>
        <h2 className="text-gray-400 font-light">You sent</h2>
        <p>{text}</p>
        <h2 className="text-gray-400 font-light">They sent</h2>
        <p>{reply}</p>
      </Modal>
    </li>
  );
};
