import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
};

/**
 * This is just for Modal headers. Use composition for
 * these elements
 *
 * @param {*} { children }
 * @return {*}
 */
const ModalHeader: React.FC<Props> = ({ children, className = "text-lg" }) => {
  return (
    <h3
      className={`text-center ${className} leading-6 font-medium text-gray-900 pb-3`}
    >
      {children}
    </h3>
  );
};

export default ModalHeader;
