import { PageBanner } from "../components";
import { useCustomers } from "../context/customersContext";

export default function Rewards() {
  const { customers } = useCustomers();

  return (
    <>
      <PageBanner heading="Customer Rewards">
        Track loyalty, view top customers, identify gaps in visits
      </PageBanner>
      <h1>Rewards</h1>
      <div>You have {customers.length} customers we can evaluate.</div>
    </>
  );
}
