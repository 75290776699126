import React from "react";
import { Link } from "react-router-dom";
import AccordionArrow from "./icons/AccordionArrow";
import { Notes } from "./Notes";
import Spinner from "./Spinner";
import { motion } from "framer-motion";
import { useProfileMachine } from "../context/profileContext";

export interface Detail {
  active: boolean;
  key: string;
  label?: string;
  linkTarget: string;
  linkValue: string;
  notes: string;
}

interface Props {
  title: string;
  count?: number;
  details: Detail[];
  isDetailsLoading?: boolean;
  actionLink?: string;
  actionLinkText?: string;
  countsByGroup?: { [key: string]: number };
  getDetails?: () => void;
}

const AccordionItem = ({
  title,
  count,
  countsByGroup = {},
  isDetailsLoading = false,
  details,
  actionLink,
  actionLinkText = "Edit",
  getDetails,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { hasLocations } = useProfileMachine();

  const handleAccordionToggle = () => {
    setIsOpen((current) => !current);
    if (getDetails) {
      getDetails();
    }
  };

  return (
    <motion.li initial={false} className="px-2">
      <div className="flex-grow flex items-center border-b border-gray-100 text-md py-2">
        <button onClick={handleAccordionToggle} className="print:hidden">
          <AccordionArrow isOpen={isOpen} />
        </button>
        <div className="flex-grow flex justify-between">
          <div className="self-center">
            <span className="underline underline-offset-1 decoration-purple-500 font-medium text-gray-800 hover:text-gray-900 print:no-underline">
              {title}
            </span>
            {actionLink && (
              <Link className="pl-6 text-sm text-blue-800" to={actionLink}>
                {actionLinkText}
              </Link>
            )}
          </div>
          <div className="flex-shrink-0 self-start ml-2">
            {hasLocations &&
              Object.keys(countsByGroup).map((shop) => {
                return (
                  <span key={shop} className="text-sm text-gray-400 mr-2">
                    {shop} {countsByGroup[shop]}
                  </span>
                );
              })}
            {count && (
              <span className="font-medium text-gray-800 ml-4">{count}</span>
            )}
          </div>
        </div>
      </div>
      <motion.div
        initial={false}
        transition={{ type: "tween" }}
        animate={{
          opacity: isOpen ? 1 : 0,
          height: isOpen ? "auto" : 0,
        }}
        className={`accordion-content px-5 pt-0 overflow-hidden`}
      >
        {isDetailsLoading ? (
          <Spinner size="28px" />
        ) : (
          <ul className="text-sm pb-3 pt-1.5">
            {details
              .sort((a, b) => (a.linkValue > b.linkValue ? 1 : -1))
              .map((detail) => {
                return (
                  <li
                    key={detail.key}
                    className="pt-3 pb-1 flex justify-between border-b border-gray-200"
                  >
                    <div className="flex justify-between">
                      {detail.label && (
                        <span className="pr-6 text-gray-400 w-20">
                          {detail.label}
                        </span>
                      )}
                      <Link
                        to={`${detail.linkTarget}`}
                        className={`${
                          detail.active ? "text-blue-800" : "text-gray-400"
                        }`}
                      >
                        {detail.linkValue}
                      </Link>
                    </div>
                    <Notes notes={detail.notes} />
                  </li>
                );
              })}
          </ul>
        )}
      </motion.div>
    </motion.li>
  );
};

export default AccordionItem;
