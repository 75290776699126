type Props = {
  disabled: boolean;
  send: Function;
  sendType: string | undefined;
};

/**
 * a little round button that you can use to enter a create modal
 * expects a send function and a type
 *
 * @param {*} { disabled, send, sendType }
 * @return {*}
 */
const ActionPlusButton: React.FC<Props> = ({ disabled, send, sendType }) => {
  return (
    <button
      data-testid="action-plus-button"
      disabled={disabled}
      onClick={() => send(sendType)}
      className="flex align-middle justify-center h-8 w-8 bg-green-400 rounded-full text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 4v16m8-8H4"
        />
      </svg>
    </button>
  );
};

export default ActionPlusButton;
