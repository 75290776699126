import React from "react";
import { Link } from "react-router-dom";
import { TTitle } from "../types/titles";

type SendEvent = {
  type: string;
  title: string;
  sk: string;
};

type Props = {
  titles: TTitle[];
  send: (args: SendEvent) => void;
};

const TitlesTable: React.FC<Props> = ({ titles, send }) => {
  const [activeTitlesVisibility, setActiveTitlesVisibility] =
    React.useState(true);

  const visibleTitles = titles.filter(
    (title) => title.active === activeTitlesVisibility
  );

  return (
    <>
      <div className="mt-6">
        <label htmlFor="toggle-show-active">Show inactive titles</label>
        <input
          type="checkbox"
          className="ml-4"
          onClick={() => setActiveTitlesVisibility((c) => !c)}
        />
      </div>
      <table className="table-auto mt-6">
        <thead>
          <tr>
            <th className="px-4 py-2 w-1/3 text-gray-800">Series Title</th>
            <th className="px-4 py-2 w-1/6 text-gray-800">Publisher</th>
            <th className="px-4 py-2 w-1/5 text-gray-800">Distributors</th>
            <th className="px-4 py-2 w-1/3 text-gray-800">Notes</th>
          </tr>
        </thead>
        <tbody>
          {visibleTitles.map((series, index) => {
            const route = series.sk.replace("TITLE#", "");
            return (
              <tr
                key={series.sk}
                className={!(index % 2) ? "bg-indigo-100" : ""}
              >
                <td className="border border-indigo-300 px-4 py-2">
                  <Link to={`/titles/${route}`}>{series.title}</Link>
                </td>
                <td className="border border-indigo-300 px-4 py-2">
                  {series.publisher}
                </td>
                <td className="border border-indigo-300 px-4 py-2">
                  {series.distributors?.map(({ label }) => label).join(", ")}
                </td>
                <td className="border border-indigo-300 px-4 py-2">
                  {series.notes}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TitlesTable;
