import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import Fuse from "fuse.js";

import InputModal from "../components/InputModal";
import ModalHeader from "../components/ModalHeader";
import Spinner from "../components/Spinner";
import TitlesTable from "../components/TitlesTable";
import useKeypress from "../hooks/useKeypress";
import Button from "../components/buttons/Button";
import { TitlesOutletContext } from "../routes/titles";
import ReactSelect from "react-select";
import { distributors } from "../types/distributors";

export default function Titles() {
  const [list, setList] = React.useState(undefined);
  const [filters, setFilters] = React.useState("");
  const { state, send, status } = useOutletContext<TitlesOutletContext>();
  const isLoading =
    status === "loading" ||
    status === "pending" ||
    status === "deleting" ||
    status === "deactivating";

  React.useEffect(() => {
    // clear the input when we leave the page
    return () => send({ type: "clear-title" });
  }, [send]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  useKeypress("Escape", () => {
    setIsModalOpen(false);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(window.location.search);
  React.useEffect(() => {
    if (queryParams.has("create")) {
      setIsModalOpen(true);
      queryParams.delete("create");
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [queryParams]);
  const sortedTitles = state.context.titles;

  const titleBeingCreated = state.context.series?.title!;

  const fuse = new Fuse(sortedTitles, {
    isCaseSensitive: false,
    threshold: 0.4,
    keys: ["title"],
  });
  const similarTitlesList =
    titleBeingCreated.length > 3 ? fuse.search(titleBeingCreated) : [];

  // @ts-ignore
  const handleCreateTitle = (e) => {
    e.preventDefault();
    send({ type: "create-new-title" });
    if (e.target.id === "create") {
      setIsModalOpen(false);
    }
  };

  const handleFiltering = (event: React.DOMAttributes<HTMLInputElement>) => {
    // @ts-ignore
    const value = event.target.value;
    const search = fuse.search(value);
    setFilters(value);
    // @ts-ignore
    setList(search.map((res) => res.item));
    if (!value) {
      setList(undefined);
    }
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          icon="arrow"
        >
          Create a title
        </Button>
        <Link to="/bundles" className="ml-8 text-blue-800">
          Manage Bundles
        </Link>
        {sortedTitles.length > 0 && (
          <div className="block">
            <input
              className="my-3 mr-3"
              type="text"
              autoFocus
              placeholder="Filter titles"
              value={filters}
              // @ts-ignore
              onInput={handleFiltering}
            />
            <Button
              variant="simple-action"
              disabled={filters.length === 0}
              onClick={() => {
                setFilters("");
                setList(undefined);
              }}
            >
              Clear
            </Button>
          </div>
        )}
        <div>
          {isLoading ? (
            <Spinner size="148px" margin={10} />
          ) : (
            // @ts-ignore
            <TitlesTable titles={list || sortedTitles} send={send} />
          )}
        </div>
      </div>
      {isModalOpen && (
        <InputModal onCloseModal={() => setIsModalOpen(false)}>
          <ModalHeader>Add a title</ModalHeader>
          <form onClick={(e) => e.stopPropagation()}>
            <label
              htmlFor="title"
              className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
            >
              <span>Title of series</span>
              <input
                autoFocus
                type="text"
                name="title"
                placeholder="Tank Girl"
                value={state.context.series?.title}
                onChange={(e) => {
                  send({
                    type: "input-change",
                    // @ts-ignore
                    value: { [e.target.name]: e.target.value },
                  });
                }}
              />
            </label>
            <div className="flex mt-4">
              <label
                htmlFor="publisher"
                className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
              >
                <span>Select a publisher</span>
                <select
                  name="publisher"
                  value={state.context.series?.publisher}
                  onChange={(e) => {
                    send({
                      type: "input-change",
                      // @ts-ignore
                      value: { [e.target.name]: e.target.value },
                    });
                  }}
                >
                  <option value="">Choose a publisher</option>
                  <option value="Marvel">Marvel</option>
                  <option value="DC">DC</option>
                  <option value="Image">Image</option>
                  <option value="Boom">Boom</option>
                  <option value="Dark Horse">Dark Horse</option>
                  <option value="IDW">IDW</option>
                  <option value="Dynamite">Dynamite</option>
                  <option value="other">Other</option>
                </select>
              </label>
              <label
                htmlFor="distributor"
                className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
              >
                <span className="flex">
                  Select a distributor
                  <span className="ml-2 text-xs italic">*optional</span>
                </span>
                <ReactSelect
                  className="border border-gray-500"
                  name="distributors"
                  styles={{
                    // @ts-expect-error
                    control: (styles) => ({
                      ...styles,
                      border: "none",
                      padding: "0.125rem",
                    }),
                  }}
                  onChange={(selections) => {
                    send({
                      type: "input-change",
                      // @ts-ignore
                      value: { distributors: selections },
                    });
                  }}
                  placeholder="Choose one or more distributors"
                  isMulti
                  // @ts-ignore
                  options={distributors}
                />
              </label>
            </div>
            <div className="flex mt-4">
              <label
                htmlFor="notes"
                className="flex flex-1 flex-col my-2 md:m-0 md:ml-2 pb-6"
              >
                <span className="flex">
                  Notes <span className="ml-2 text-xs italic">*optional</span>
                </span>
                <input
                  type="text"
                  name="notes"
                  value={state.context.series?.notes || ""}
                  onChange={(e) => {
                    send({
                      type: "input-change",
                      // @ts-ignore
                      value: { [e.target.name]: e.target.value },
                    });
                  }}
                />
              </label>
            </div>
            <Button
              type="submit"
              onClick={handleCreateTitle}
              id="create"
              variant="secondary"
              icon="plus"
              disabled={
                state.matches("submitting") ||
                Boolean(
                  !state.context.series?.publisher ||
                    !state.context.series?.title
                )
              }
            >
              Create Title
            </Button>
            <Button
              onClick={handleCreateTitle}
              id="create-and-add-another"
              variant="submission"
              className="ml-4"
              icon="plus"
              disabled={
                state.matches("submitting") ||
                Boolean(
                  !state.context.series?.publisher ||
                    !state.context.series?.title
                )
              }
            >
              Create and add another
            </Button>
            <div className="h-64 p-2 mt-2">
              <h2>Similar Matching Titles</h2>
              {similarTitlesList.map(({ item }) => {
                return (
                  <Link
                    key={item.sk}
                    to={`/titles/${item.sk.replace("TITLE#", "")}`}
                    className="mr-2 my-2 underline text-blue-700"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </form>
        </InputModal>
      )}
    </>
  );
}
