import { useBoolean } from "usehooks-ts";
import { TCustomer } from "../../types/customer";
import Button from "../buttons/Button";

interface BaseAddress {
  customer: TCustomer;
  handleAddressChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    addressId: number
  ) => void;
}

interface IAddressComponent extends BaseAddress {
  open: boolean;
}

interface IAddress extends BaseAddress {}

export const Addresses = ({ handleAddressChange, customer }: IAddress) => {
  const { value: open, toggle } = useBoolean(false);

  return (
    <>
      <div className="md:flex md:my-2">
        <span className="flex-1" />
        <Button
          variant={open ? "informational-destructive" : "informational"}
          icon={open ? "close" : "plus"}
          onClick={() => toggle()}
        >
          {open ? "Never mind" : "Add an Address"}
        </Button>
      </div>
      <AddressesInput
        open={open}
        handleAddressChange={handleAddressChange}
        customer={customer}
      />
    </>
  );
};

const AddressesInput = ({
  open,
  handleAddressChange,
  customer,
}: IAddressComponent) => {
  if (!open) return null;

  return (
    <>
      <div className="md:flex md:my-2">
        <span className="flex-1" />
        <label
          className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
          htmlFor="addressLine"
        >
          <span>Address Line</span>
          <input
            type="text"
            value={customer.addresses?.[0]?.addressLine || ""}
            onChange={(e) => {
              handleAddressChange(e, 0);
            }}
            placeholder="1007 Mountain Drive"
            name="addressLine"
          />
        </label>
      </div>

      <div className="md:flex md:my-2">
        <span className="flex-1" />
        <label
          className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
          htmlFor="addressLineTwo"
        >
          <span>Address Line Two</span>
          <input
            type="text"
            value={customer.addresses?.[0]?.addressLineTwo || ""}
            onChange={(e) => {
              handleAddressChange(e, 0);
            }}
            placeholder="Batcave Turnoff"
            name="addressLineTwo"
          />
        </label>
      </div>

      <div className="md:flex md:my-2">
        <span className="flex-1" />
        <label
          className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
          htmlFor="city"
        >
          <span>City</span>
          <input
            type="text"
            value={customer.addresses?.[0]?.city || ""}
            onChange={(e) => {
              handleAddressChange(e, 0);
            }}
            placeholder="Gotham"
            name="city"
          />
        </label>
      </div>

      <div className="md:flex md:my-2">
        <span className="flex-2" />
        <label
          className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
          htmlFor="state"
        >
          <span>State</span>
          <input
            type="text"
            value={customer.addresses?.[0]?.state || ""}
            onChange={(e) => {
              handleAddressChange(e, 0);
            }}
            placeholder="New Jerseyu"
            name="state"
          />
        </label>
        <label
          className="flex flex-1 flex-col my-2 md:m-0 md:mr-2"
          htmlFor="zipCode"
        >
          <span>Zip</span>
          <input
            type="text"
            value={customer.addresses?.[0]?.zipCode || ""}
            onChange={(e) => {
              handleAddressChange(e, 0);
            }}
            placeholder="12345"
            name="zipCode"
          />
        </label>
      </div>
    </>
  );
};
