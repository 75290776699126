import { useActor, useInterpret, useSelector } from "@xstate/react";
import { API } from "aws-amplify";
import { createMachine, assign, StateFrom } from "xstate";
import { TCustomer } from "../types/customer";

type Events =
  | { type: "toggle"; customer: TCustomer }
  | { type: "nevermind" }
  | { type: "send"; message: string }
  | { type: "update" };

type Services = {
  sendBulkMessage: {
    data: {};
  };
};

export const broadcastMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCMBOB7AhhAxp2ALgLQC2mOAFgJYB2YAdFRADZgDEB6UUrioADulhUCVdDT4gAHogAsAZnoAOAKyyATAE5ZAdnUBGfToAMs4yoA0IAJ6J1SnfR2b5ANh0rjmzftfzZAL4BVmhYuPjEZJS0DEysbLAArsgkIpKCwqLikjIIvipOKu6u+mYlOkqa6la2CCb0mhWm6n5uxv4qQSEY2HiEpOTUdIws7In8EJgEYOlCImISSNKI+sbG9PKeKprtpsauZvo1iERGim72a02aSurqOl0gob0RA9HDsGA0ELRQbLOZBY5RDyTb0IzGfQ+AzqXQVY4IIg6c4qFQGDRKLyubSdR40dAQOCSZ7hfpRIaxUYA+bZJa5RTGJRQ-aaNSmWQlFwIlT6eiuVxqdTyYx3NGeTSPEl9SKDGL0T7fX7UrKLUD0u70DFKW77WSY3yuBHa5R+WQ7MyaVxKDTqSU9Uky94zJYZGmq5aI9TrHyYlymbao+RKBFEWEFTZrfbmTY7NxBIJAA */
  createMachine(
    {
      context: { customersToBroadcast: [] },
      tsTypes: {} as import("./broadcastMachine.typegen").Typegen0,
      schema: {
        context: {} as { customersToBroadcast: TCustomer[] },
        events: {} as Events,
        services: {} as Services,
      },
      id: "broadcast-machine",
      initial: "idle",
      states: {
        idle: {
          on: {
            toggle: {
              actions: "toggleCustomerToBroadcast",
            },
            update: {
              target: "updating",
            },
          },
        },
        updating: {
          on: {
            nevermind: {
              target: "idle",
            },
            send: {
              target: "sending",
            },
          },
        },
        sending: {
          exit: "clearCustomersToBroadcast",
          invoke: {
            src: "sendBulkMessage",
            onDone: {
              target: "idle",
            },
            onError: {
              target: "idle",
            },
          },
        },
      },
    },
    {
      services: {
        sendBulkMessage: async (context, event) => {
          const message = await API.post("customers", "/send-message", {
            body: {
              customers: context.customersToBroadcast,
              message: event.message,
              sequence: "BULK_MESSAGE",
            },
          });
          return { message };
        },
      },
      actions: {
        clearCustomersToBroadcast: assign({
          customersToBroadcast: (_) => [],
        }),
        toggleCustomerToBroadcast: assign({
          customersToBroadcast: (context, event) => {
            const customerExists = Boolean(
              context.customersToBroadcast?.find(
                (c) => c.customerId === event.customer.customerId
              )
            );

            if (customerExists) {
              return context.customersToBroadcast.filter(
                (c) => c.customerId !== event.customer.customerId
              );
            }
            return context.customersToBroadcast
              ? [...context.customersToBroadcast, event.customer]
              : [event.customer];
          },
        }),
      },
    }
  );

export const useBroadcastMachine = () => {
  const broadcastService = useInterpret(broadcastMachine);
  const [state, send] = useActor(broadcastService);
  const hasSelectedCustomers = useSelector(
    broadcastService,
    selectHasCustomers
  );
  const selectedCustomers = useSelector(
    broadcastService,
    selectSelectedCustomers
  );

  const isUpdating = state.matches("updating");

  const handleToggleCustomer = (customer: TCustomer) => {
    send({ type: "toggle", customer });
  };

  const handleBeginSendMessage = () => {
    send("update");
  };

  const handleSendMessage = (message: string) => {
    send({ type: "send", message });
  };

  const handleNeverMind = () => {
    send("nevermind");
  };

  return {
    handleBeginSendMessage,
    handleNeverMind,
    handleSendMessage,
    handleToggleCustomer,
    hasSelectedCustomers,
    isUpdating,
    selectedCustomers,
  };
};

const selectHasCustomers = (state: StateFrom<typeof broadcastMachine>) => {
  return state.context.customersToBroadcast.length > 0;
};

const selectSelectedCustomers = (state: StateFrom<typeof broadcastMachine>) => {
  return state.context.customersToBroadcast;
};
