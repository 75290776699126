import { useMachine, useSelector } from "@xstate/react";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useProfileMachine } from "../context/profileContext";
import useShopDetails from "../hooks/useShopDetails";
import smsMachine from "../machines/smsMachine";
import { CustomersContext, selectCustomers } from "../context/customersContext";

import SmsIcon from "./icons/SmsIcon";
import { THoldFile } from "../types/holdFile";

interface CheckListProps {
  listTitle: string;
  handleRemove: (name: string) => void;
  customerName: string;
  list: { files: THoldFile[] };
}

/**
 * Checklist - item with values to list as a checkbox
 *
 * @param {*} { listTitle, list }
 * @return {*}
 */

interface IState {
  isComplete: boolean;
  isRemovable: boolean;
}

type TActions =
  | { type: "isComplete"; value: boolean }
  | { type: "isRemovable"; value: boolean };

const initialState = {
  isComplete: false,
  isRemovable: false,
};

const reducer = (state: IState, action: TActions) => {
  switch (action.type) {
    case "isComplete":
      return { ...state, isComplete: action.value };
    case "isRemovable":
      return { ...state, isRemovable: action.value };
    default:
      return state;
  }
};

const Checklist = ({
  listTitle,
  list,
  handleRemove,
  customerName,
}: CheckListProps) => {
  const files = list.files.map((listItem) => {
    return {
      checked: false,
      ...listItem,
    };
  });

  const { customersService } = React.useContext(CustomersContext);
  const customers = useSelector(customersService, selectCustomers);
  const currentCustomer = customers.find(
    (customer) => customer.customerId === list.files[0].customerId
  );
  const { shop } = useShopDetails();
  const { profile } = useProfileMachine();
  const [, send] = useMachine(smsMachine, {
    context: {
      shopName: profile?.shopName,
    },
  });
  const [listState, dispatch] = React.useReducer(reducer, initialState);
  const [checkList, setCheckList] = React.useState(files);

  React.useEffect(() => {
    const isComplete = checkList.every((item) => item.checked);
    dispatch({ type: "isComplete", value: isComplete });
  }, [checkList, dispatch]);

  const toggleCheckedItem = (
    e: React.InputHTMLAttributes<HTMLInputElement>
  ) => {
    setCheckList((current) => {
      return current.map((curr) => {
        //@ts-ignore
        if (curr.sk === e.target.name) {
          return {
            ...curr,
            checked: !curr.checked,
          };
        }
        return curr;
      });
    });
  };

  if (!currentCustomer) {
    return (
      <li className="mb-2 border-b">
        <div className="text-xl">
          Somehow there is a Phantom Customer! This happened because of manual
          database edits made in June. Please let Devin know what titles were in
          receiving and he can fix the Phantom Customer.
        </div>
      </li>
    );
  }

  return (
    <li className="mb-2 border-b">
      <div>
        <div className="text-indigo-700 print:text-gray-800">
          <Link
            to={`/customers/${list.files[0].customerId}`}
            className="text-lg"
          >
            {listTitle}
          </Link>
          <span className="pl-2 text-gray-400 text-xs">
            {list.files[0].storeLocation}
          </span>
          {currentCustomer.note && (
            <span className="ml-4 pl-2 text-green-800 text-sm">
              {currentCustomer.note}
            </span>
          )}
        </div>
        <ul>
          {checkList.map((listItem, index) => {
            return (
              <li key={listItem.sk} className="flex justify-between">
                <div className="flex-1 flex items-center text-lg p-1">
                  <input
                    type="checkbox"
                    className={`mr-4 ${
                      listState.isRemovable
                        ? "opacity-50 pointer-events-none"
                        : ""
                    }`}
                    name={listItem.sk}
                    checked={listItem.checked}
                    onChange={toggleCheckedItem}
                  />
                  <div className=" flex-1 flex justify-between items-end">
                    <p>
                      {listItem.holdFile}
                      <span className="text-gray-500 text-sm ml-4">
                        {listItem.notes}
                      </span>
                    </p>
                    {checkList.length === index + 1 && listState.isComplete && (
                      <div className="flex items-center">
                        <button
                          className="text-sm text-red-400 hover:text-red-700"
                          onClick={() => {
                            handleRemove(customerName);
                          }}
                        >
                          remove
                        </button>
                        <span className="w-6" />
                        <button
                          disabled={listState.isRemovable}
                          className="text-gray-500 hover:text-indigo-700 hover:translate-y-px transition-all"
                          onClick={() => {
                            toast("text message sent!");
                            send({
                              type: "send-text-message",
                              customerWithFile: list.files,
                              shopName: profile?.shopName,
                              shop,
                            });
                            dispatch({
                              type: "isRemovable",
                              value: true,
                            });
                          }}
                        >
                          <SmsIcon height={6} width={6} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
};

export default Checklist;
