import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import useKeypress from "../hooks/useKeypress";

const Header = ({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] =
    React.useState<boolean>(false);

  useKeypress("Escape", () =>
    isAccountMenuOpen ? setIsAccountMenuOpen(false) : () => null
  );

  async function signOut() {
    try {
      await Auth.signOut();
      window.location.href = "https://comicshelper.com";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <header className="print:hidden sticky top-0 bg-white border-b border-gray-200 z-30 shadow-md">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={isSidebarOpen}
              onClick={() => setIsSidebarOpen((current) => !current)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <div className="relative inline-flex">
              <button
                className="inline-flex justify-center items-center group"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setIsAccountMenuOpen((current) => !current)}
              >
                <div className="flex items-center truncate">
                  <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
                    Account Info
                  </span>
                  <svg
                    className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
                    viewBox="0 0 12 12"
                  >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
                  </svg>
                </div>
              </button>
              {isAccountMenuOpen && (
                <>
                  <div className="w-64 origin-top-right z-50 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-3">
                    <div>
                      <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                        <div className="font-medium text-gray-800">
                          Plan Type:
                        </div>
                        <div className="text-xs text-gray-500 italic">Beta</div>
                      </div>
                      <ul>
                        <li>
                          <Link
                            className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3 justify-end"
                            to="/profile"
                            onClick={() => setIsAccountMenuOpen(false)}
                          >
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3 justify-end"
                            to="/plans"
                            onClick={() => setIsAccountMenuOpen(false)}
                          >
                            Adjust Plan
                          </Link>
                        </li>
                        <li className="flex justify-end">
                          <button
                            className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                            onClick={signOut}
                          >
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    data-testid="account-backdrop"
                    onClick={() => {
                      setIsAccountMenuOpen(false);
                    }}
                    className={`fixed top-0 left-0 right-0 bottom-0 opacity-0 z-10 ${
                      isAccountMenuOpen ? "block" : "hidden"
                    }`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
