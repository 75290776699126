import React from "react";
import { API } from "aws-amplify";
import { createMachine, assign, InterpreterFrom, StateFrom } from "xstate";
import { useActor, useInterpret, useSelector } from "@xstate/react";

import { AsYouType } from "libphonenumber-js";
import useShopDetails from "../hooks/useShopDetails";
import renderPhone from "../utils/renderPhone";
import { IClerk } from "../types/clerk";
import { IProfile } from "../types/profile";

type Context = {
  clerk?: IClerk;
  shop?: string;
  profile?: IProfile;
};

type Events =
  | {
      type: "shop-and-clerk-loaded";
      value: { shop: string; clerk: IClerk };
    }
  | { type: "update-clerk"; name: string }
  | { type: "submit" }
  | { type: "delete"; value: string }
  | { type: "edit"; name: string; value: string };

type Services = {
  getProfile: {
    data: { clerk: IClerk; profile: IProfile } | undefined;
  };
  updateProfile: {
    data: { profile: IProfile };
  };
  deleteLocation: {
    data: { profile: IProfile };
  };
};

export const profileMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "profileMachine",
    initial: "pending",
    schema: {
      services: {} as Services,
      context: {} as Context,
      events: {} as Events,
    },
    tsTypes: {} as import("./profileContext.typegen").Typegen0,
    context: {
      clerk: {},
      shop: "",
      profile: {
        shopName: "",
        contactName: "",
        locations: [],
        phone: "",
        website: "",
        shopEmail: "",
      },
    },
    states: {
      pending: {
        on: {
          "shop-and-clerk-loaded": {
            target: "loading",
            actions: "insertShopIntoContext",
          },
        },
      },
      loading: {
        invoke: {
          id: "get-profile",
          src: "getProfile",
          onDone: {
            target: "loaded",
            actions: "loadProfile",
          },
          onError: {
            target: "error",
          },
        },
      },
      loaded: {
        on: {
          "update-clerk": {
            target: "loaded",
            actions: "updateClerk",
          },
          submit: {
            target: "submitting",
          },
          delete: {
            target: "deleting",
          },
          edit: {
            actions: ["editProfile"],
          },
        },
      },
      deleting: {
        invoke: {
          src: "deleteLocation",
          onDone: {
            actions: "submitProfileUpdates",
            target: "loaded",
          },
          onError: {
            target: "error",
          },
        },
      },
      submitting: {
        invoke: {
          src: "updateProfile",
          onDone: {
            actions: "submitProfileUpdates",
            target: "loaded",
          },
          onError: {
            target: "error",
          },
        },
      },
      error: {},
    },
  },
  {
    services: {
      deleteLocation: async (context, event) => {
        const response = await API.put("customers", "/profile", {
          body: {
            profile: context.profile,
            clerk: context.clerk,
            deleteLocation: event.value,
            shop: context.shop,
          },
        });
        return response.data;
      },
      getProfile: async (context) => {
        if (!context.clerk) return { clerk: {}, profile: {} };

        if (context.clerk) {
          const { profile, clerk } = await API.get(
            "customers",
            `/profile?clerk=${context.clerk.username}&shop=${context.shop}`,
            {}
          );

          return {
            profile: profile.Item as IProfile,
            clerk: clerk.Item as IClerk,
          };
        }
      },
      updateProfile: async (context) => {
        const response = await API.put("customers", "/profile", {
          body: {
            profile: context.profile,
            clerk: context.clerk,
            shop: context.shop,
          },
        });
        return response.data;
      },
    },
    actions: {
      editProfile: assign({
        profile: (context, event) => {
          if (event.name === "phone") {
            // max digits for phone numbers
            if (event.value?.length > 14) {
              return { ...context.profile };
            }

            const phone = new AsYouType("US").input(event.value);

            return { ...context.profile, ...{ phone } };
          }

          return {
            ...context.profile,
            [event.name]: event.value,
          };
        },
      }),
      loadProfile: assign({
        clerk: (context, event) => {
          if (!event.data?.clerk) return { ...context.clerk };

          const { clerk } = event.data;
          const newClerkValues = clerk ? clerk : {};
          return { ...context.clerk, ...newClerkValues };
        },
        profile: (context, event) => {
          if (!event.data?.profile) return { ...context.profile };

          const { profile } = event.data;
          if (!profile || !profile.phone) {
            return profile;
          }

          return {
            ...profile,
            phone: renderPhone(profile.phone),
          };
        },
      }),
      updateClerk: assign({
        clerk: (context, { name }) => {
          return {
            ...context.clerk,
            attributes: { ...context.clerk?.attributes, name },
          };
        },
      }),
      submitProfileUpdates: assign({
        profile: (_, event) => {
          const responsePhone = event.data.profile.phone;

          const phone = responsePhone
            ? new AsYouType("US").input(responsePhone)
            : "";

          return {
            ...event.data.profile,
            phone,
          };
        },
      }),
      insertShopIntoContext: assign({
        /*
         * the value is {type: "shop-loaded", value: {shop}}
         */
        shop: (_, { value: { shop } }) => {
          return shop;
        },
        clerk: (_, { value: { clerk } }) => {
          return clerk;
        },
      }),
    },
  }
);

export const ProfileContext = React.createContext({
  profileService: {} as InterpreterFrom<typeof profileMachine>,
});

export const useProfileMachine = () => {
  const { shop, clerk: clerkDetails } = useShopDetails();
  const { profileService } = React.useContext(ProfileContext);
  const [state, send] = useActor(profileService);

  const isLoading = useSelector(profileService, selectIsProfileLoading);
  const hasLocations = useSelector(profileService, selectHasLocations);
  const profile = useSelector(profileService, selectProfile);
  const locations = useSelector(profileService, selectLocations);

  React.useEffect(() => {
    if (shop && clerkDetails) {
      send({
        type: "shop-and-clerk-loaded",
        value: { shop, clerk: clerkDetails },
      });
    }
  }, [clerkDetails, send, shop]);

  const isSubmitting = state.matches("submitting");
  const isProfileLoaded = state.matches("loaded");
  const isDeleting = state.matches("deleting");

  const handleUpdateProfileValues = (value: string, name: string) => {
    send({ type: "edit", name, value });
  };

  const clerk = useSelector(profileService, selectClerk);
  const handleNameChange = (name: string) => {
    send({ type: "update-clerk", name });
  };

  return {
    clerk,
    handleNameChange,
    handleUpdateProfileValues,
    hasLocations,
    isDeleting,
    isLoading,
    isProfileLoaded,
    isSubmitting,
    locations,
    send,
    profile,
  };
};

type Props = {
  children?: React.ReactNode;
};

export const ProfileProvider = ({ children }: Props) => {
  const { shop, clerk } = useShopDetails();
  const profileService = useInterpret(profileMachine);
  const [, send] = useActor(profileService);

  React.useEffect(() => {
    if (shop && clerk) {
      send({
        type: "shop-and-clerk-loaded",
        value: { shop, clerk },
      });
    }
  }, [clerk, send, shop]);

  return (
    <ProfileContext.Provider value={{ profileService }}>
      {children}
    </ProfileContext.Provider>
  );
};

const selectIsProfileLoading = (state: StateFrom<typeof profileMachine>) => {
  return (
    state.matches("pending") ||
    state.matches("loading") ||
    state.matches("submitting") ||
    state.matches("deleting")
  );
};

const selectProfile = (state: StateFrom<typeof profileMachine>) => {
  return state.context.profile;
};

const selectClerk = (state: StateFrom<typeof profileMachine>) => {
  return state.context.clerk;
};

const selectLocations = (state: StateFrom<typeof profileMachine>) => {
  return state.context.profile?.locations || [];
};

const selectHasLocations = (state: StateFrom<typeof profileMachine>) => {
  return Boolean(state.context.profile?.locations?.length);
};
