export type TDistributors = {
  value: "prh" | "diamond" | "lunar" | "other";
  label: "PRH" | "Diamond" | "Lunar" | "Other";
};

export const distributors = [
  { value: "prh", label: "PRH" },
  { value: "diamond", label: "Diamond" },
  { value: "lunar", label: "Lunar" },
  { value: "other", label: "Other" },
];
