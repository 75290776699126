import React from "react";
import RingLoader from "react-spinners/RingLoader";

type Props = {
  margin?: number;
  size: string;
  padding?: number;
  alternate?: boolean;
};

const Spinner: React.FC<Props> = ({
  margin = 1,
  size,
  padding = 1,
  alternate,
}) => {
  return (
    <div
      data-testid="loading"
      className={`flex justify-center items-center`}
      style={{ margin: `${margin * 8}px 0`, padding: `${padding * 8}px 0` }}
    >
      <RingLoader
        size={size}
        color={alternate ? "rgb(31 41 55)" : "rgb(59 130 246 / 0.5)"}
      />
    </div>
  );
};

export default Spinner;
