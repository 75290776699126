export const MESSAGE_STATE = {
  SENT: "bg-indigo-300",
  REPLIED: "bg-green-700 text-white",
  REMINDER: "bg-yellow-500",
  RESOLVED: "bg-emerald-500",
} as const;

type TMessageBaseProps = {
  customerId: number;
  gsi1pk: string;
  gsi1sk: string;
  gsi2pk: string;
  pk: string;
  sk: string;
  sentDate: number;
  messageState: keyof typeof MESSAGE_STATE;
};

interface TReplyMessage extends TMessageBaseProps {
  message: string;
  reply: string;
}

interface TReminderMessage extends TMessageBaseProps {
  lastMessageSentDate: string;
  messageSid: string;
  messageStatus: string;
  sequence: string;
}

export type IMessage = TReplyMessage | TReminderMessage;
