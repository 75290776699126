import React from "react";
import { Modal } from "./Modal";

export const Notes = ({ notes }: { notes: string }) => {
  const [showNotes, setShowNotes] = React.useState(false);
  return (
    <span className="font-medium text-gray-800">
      {notes?.length > 15 ? (
        <button
          aria-label="show notes"
          onClick={() => setShowNotes(true)}
          className="text-gray-500"
        >
          {notes.substring(0, 25)}...
        </button>
      ) : (
        notes
      )}
      <Modal isOpen={showNotes} onClose={() => setShowNotes(false)}>
        {notes}
      </Modal>
    </span>
  );
};
