interface Props {
  backgroundColor?: string;
  children: React.ReactNode;
  className?: string;
  heading: string;
}

const PageBanner = ({
  heading,
  className,
  backgroundColor = "bg-indigo-200",
  children,
}: Props) => {
  return (
    <div
      className={`${backgroundColor} p-4 sm:p-6 rounded-sm overflow-hidden mb-8 print:hidden ${className}`}
    >
      {/* Content */}
      <div>
        {heading && (
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-1">
            {heading}
          </h1>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PageBanner;
