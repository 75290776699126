import { PageBanner } from "../components";

export default function Preorders() {
  return (
    <main>
      <PageBanner heading="Preorders">
        This feature doesn't exist yet
      </PageBanner>
      <h1 className="">Pre Orders</h1>
    </main>
  );
}
