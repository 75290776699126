import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Button from "../components/buttons/Button";
import Spinner from "../components/Spinner";
import { useTitles } from "../context/titlesContext";
import { useBundles } from "../machines/bundlesMachine";
import { TTitle } from "../types/titles";

const Bundle = () => {
  const [selectValue, setSelectValue] = React.useState<TTitle[]>([]);
  const { pathname } = useLocation();
  const currentBundleSk = pathname.replace("/bundles/", "BUNDLE#");
  const navigate = useNavigate();
  const { titles, loading } = useTitles();
  const { bundles, handleDeleteBundle, handleEditBundle, isBundlesLoading } =
    useBundles();
  const [bundleName, setBundleName] = React.useState("");
  const currentBundle = bundles.find(
    (bundle) => bundle.sk === currentBundleSk
  )!;
  React.useEffect(() => {
    if (currentBundle) {
      setBundleName(currentBundle.bundleName);
    }
  }, [currentBundle]);

  const bundlesSeries =
    currentBundle?.bundle.map((series) => {
      return titles.find((title) => title.sk === series);
    }) || [];

  const handleSubmitEdits = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleEditBundle(
      {
        ...currentBundle,
        bundle: [
          ...currentBundle.bundle,
          ...selectValue.map((series) => series.sk),
        ],
        bundleName: bundleName,
      },
      currentBundleSk
    );
    navigate("/bundles");
  };

  const handleDeleteTitle = (e: React.FormEvent, targetSeries: string) => {
    e.preventDefault();
    const remainingBundle = bundlesSeries?.filter(
      (series) => series?.sk !== targetSeries
    ) as TTitle[];
    handleEditBundle(
      {
        ...currentBundle,
        bundle: remainingBundle.map((series) => series.sk),
        bundleName: bundleName,
      },
      currentBundleSk
    );
  };

  if (isBundlesLoading || loading) {
    return <Spinner size="148px" />;
  }

  return (
    <div>
      <div className="text-right mb-4">
        <Button
          variant="destructive"
          className="mt-4"
          onClick={() => {
            handleDeleteBundle(currentBundle);
            toast("Deleted!");
            navigate("/bundles");
          }}
        >
          Delete this bundle
        </Button>
      </div>
      <form onSubmit={handleSubmitEdits}>
        <label
          htmlFor="name"
          className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
        >
          <span>Name of your bundle</span>
          <input
            autoFocus
            type="text"
            name="name"
            value={bundleName}
            onChange={(e) => setBundleName(e.target.value)}
          />
        </label>
        <div className="ml-2 mb-4 flex">
          <div className="flex-1">
            <h2 className="mt-6">Series in bundle</h2>
            <ul className="p-2">
              {bundlesSeries?.map((series) => {
                if (!series) return null;

                return (
                  <li
                    key={series.sk}
                    className="flex border-b p-2 mr-8 justify-between"
                  >
                    <span className="mr-4 w-28">{series.title}</span>
                    <button
                      className="ml-8 text-sm text-red-400"
                      onClick={(e) => {
                        handleDeleteTitle(e, series.sk);
                      }}
                    >
                      Remove
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex-1">
            <h2 className="mt-6">Add a series</h2>
            <ReactSelect
              className="ml-2 mt-4"
              isMulti
              options={titles.map((series) => ({
                ...series,
                label: series.title,
                value: series.sk,
              }))}
              //@ts-ignore
              onChange={setSelectValue}
            />
          </div>
        </div>
        <Button variant="submission" type="submit">
          Submit Updates
        </Button>
      </form>
    </div>
  );
};

export default Bundle;
