import React from "react";
import { Link } from "react-router-dom";
import { useHoldFiles } from "../context/holdFilesContext";
import { THoldFile } from "../types/holdFile";
import { TTitle } from "../types/titles";
import EmptyData from "./organisms/EmptyData";
import Spinner from "./Spinner";

export const CustomersWithTitle = ({
  currentTitle,
}: {
  currentTitle: TTitle;
}) => {
  const { customersWithHoldFile: cwhf, loaded, send } = useHoldFiles();
  const customersWithHoldFile = dedupe(cwhf);

  React.useEffect(() => {
    send({ type: "view-files", currentTitle });
  }, [send, currentTitle]);

  if (!loaded)
    return (
      <div className="flex-2">
        <Spinner size="64px" margin={8} />
      </div>
    );

  if (customersWithHoldFile?.length === 0) {
    return (
      <div className="flex-2">
        <EmptyData headingText="No subscribers">
          <Link to="/customers" className="underline">
            Add this book
          </Link>{" "}
          to some hold files.
        </EmptyData>
      </div>
    );
  }

  return (
    <>
      <h2>Subscribers: {customersWithHoldFile.length}</h2>
      <h2>Subscriptions: {cwhf.length}</h2>
      <table className="table-auto flex-2">
        <thead>
          <tr>
            <th className="px-4 py-2 w-1/3 text-gray-800">Customer</th>
            <th className="px-4 py-2 w-1/3 text-gray-800">Notes</th>
          </tr>
        </thead>
        <tbody>
          {customersWithHoldFile?.map((customer, index) => {
            return (
              <tr
                key={customer.pk}
                className={!(index % 2) ? "bg-indigo-100" : ""}
              >
                <td className="border border-indigo-300 px-4 py-2">
                  <Link to={`/customers/${customer.customerId}`}>
                    {customer.firstName} {customer.lastName}
                  </Link>
                </td>
                <td className="border border-indigo-300 px-4 py-2">
                  {customer.notes}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const dedupe = (arr: THoldFile[]) => {
  return arr.reduce((acc, curr) => {
    if (acc.some((c) => c.customerId === curr.customerId)) {
      return acc;
    }
    return [...acc, curr];
  }, [] as THoldFile[]);
};
