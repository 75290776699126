import { API } from "aws-amplify";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { IMessage } from "../../types/messages";

import useShopDetails from "../useShopDetails";
import { TCustomer } from "../../types/customer";

const getMessages = async (shop: string) => {
  const messages = await API.get("customers", `/messages?shop=${shop}`, {});
  return messages;
};

const getCustomerMessages = async (customerId: number, shop: string) => {
  console.log("customerId: ", customerId);
  const messages = await API.get(
    "customers",
    `/messages?customerId=${customerId}&shop=${shop}`,
    {}
  );
  return messages;
};

export const useCustomerMessages = ({ customerId }: TCustomer) => {
  const { shop } = useShopDetails();

  const query = useQuery({
    queryKey: ["messages", shop, customerId],
    queryFn: () => getCustomerMessages(customerId, shop!),
    enabled: !!shop,
  });

  const messages = (query.data?.messaging.sort((a: IMessage, b: IMessage) => {
    return new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime();
  }) || []) as IMessage[];
  return {
    messages,
    isLoading: query.isLoading,
  };
};

export const useMessages = () => {
  const { shop } = useShopDetails();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (message: IMessage) => {
      return API.post("customers", `/messages`, {
        body: message,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["messages", shop] });
      toast.success("Message resolved.");
    },
  });

  const handleResolveMessage = (message: IMessage) => {
    mutation.mutate(message);
  };

  const query = useQuery({
    queryKey: ["messages", shop],
    queryFn: () => getMessages(shop!),
    enabled: !!shop,
  });

  const messages = query.data?.messaging || [];
  return {
    handleResolveMessage,
    messages,
    isLoading: query.isLoading,
  };
};
