import { createMachine, assign } from "xstate";
import { AsYouType } from "libphonenumber-js";
import { API } from "aws-amplify";
import { TCreatingCustomer, TCustomer } from "../types/customer";
import { useActor, useInterpret } from "@xstate/react";
import { useProfileMachine } from "./profileContext";
import React from "react";

export const stateMap = {
  error: "error",
  hidden: "hidden",
  idle: "idle",
  inactive: "inactive",
  loading: "loading",
  submitting: "submitting",
  success: "success",
  upload: "upload",
  uploading: "uploading",
  visible: "visible",
};

export const actionMap = {
  delete_customer: "delete_customer",
  change_form_value: "change_form_value",
  clear_customer: "clear_customer",
  form_submit_error: "form_submit_error",
  form_submit_success: "form_submit_success",
  hide_create_customer: "hide_create_customer",
  show_create_customer: "show_create_customer",
  show_edit_customer: "show_edit_customer",
  show_upload_customers: "show_upload_customers",
  submit_form: "submit_form",
  update_location_status: "update_location_status",
  upload_customers: "upload_customers",
};

type Events =
  | {
      type: "delete_customer";
      customer: TCustomer;
    }
  | {
      type: "change_form_value";
      value: { [key: string]: string };
    }
  | {
      type: "change-address";
      value: { [key: string]: string | number; addressId: number };
    }
  | {
      type: "clear_customer";
    }
  | {
      type: "form_submit_error";
    }
  | {
      type: "form_submit_success";
    }
  | {
      type: "hide_create_customer";
    }
  | {
      type: "show_create_customer";
    }
  | {
      type: "show_edit_customer";
      customer: TCustomer;
    }
  | {
      type: "show_upload_customer";
    }
  | {
      type: "update_location_status";
      hasLocations: boolean;
    }
  | {
      type: "submit_form";
    };

type Context = {
  customer: TCreatingCustomer | TCustomer;
  hasLocations: boolean;
  error: string;
  mode?: undefined | string;
};

export const customerMachine = createMachine(
  {
    id: "customerMachine",
    initial: stateMap.hidden,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    context: {
      customer: {
        email: "",
        createdAt: undefined,
        firstName: "",
        lastName: "",
        phone: "",
        note: "",
        storeLocation: "",
        tags: [],
        bundles: [],
        addresses: [
          {
            addressId: 0,
            addressLine: "",
            addressLineTwo: "",
            city: "",
            state: "",
            zipCode: "",
          },
        ],
      },
      hasLocations: false,
      error: "",
      mode: undefined,
    },
    tsTypes: {} as import("./customerContext.typegen").Typegen0,
    states: {
      [stateMap.hidden]: {
        on: {
          [actionMap.delete_customer]: {
            actions: ["deleteCustomerById", "clearCustomer"],
          },
          [actionMap.clear_customer]: {
            actions: ["clearCustomer"],
          },
          [actionMap.update_location_status]: {
            actions: ["setLocationStatus"],
          },
          show_create_customer: [stateMap.visible],
          [actionMap.show_upload_customers]: [stateMap.upload],
          [actionMap.show_edit_customer]: {
            target: [stateMap.visible],
            actions: "handleEditCustomer",
          },
        },
      },
      [stateMap.upload]: {
        on: {
          [actionMap.hide_create_customer]: [stateMap.hidden],
          [actionMap.submit_form]: [stateMap.uploading],
        },
      },
      [stateMap.uploading]: {
        on: {
          [actionMap.form_submit_success]: {
            target: [stateMap.hidden],
          },
        },
      },
      [stateMap.visible]: {
        on: {
          [actionMap.hide_create_customer]: {
            target: [stateMap.hidden],
            actions: ["clearCustomer"],
          },
          [actionMap.change_form_value]: {
            target: [stateMap.visible],
            actions: ["onInputChange"],
          },
          "change-address": {
            target: [stateMap.visible],
            actions: ["onAddressChange"],
          },
          [actionMap.submit_form]: {
            cond: "isCreateCustomerFormValid",
            target: [stateMap.submitting],
            onExit: "clearCustomer",
          },
        },
      },
      [stateMap.submitting]: {
        on: {
          [actionMap.form_submit_success]: {
            target: [stateMap.hidden],
            actions: ["clearCustomer"],
          },
          [actionMap.form_submit_error]: {
            target: [stateMap.hidden],
            actions: ["customerError", "clearCustomer"],
          },
        },
      },
    },
  },
  {
    guards: {
      isCreateCustomerFormValid: ({ customer, hasLocations }) => {
        const hasValidLocation = hasLocations
          ? Boolean(customer.storeLocation)
          : false;
        const hasValidPhone = customer.phone.length === 14;
        const isValid = !!(
          customer.firstName &&
          customer.lastName &&
          hasValidPhone &&
          hasValidLocation
        );
        return isValid;
      },
    },
    actions: {
      onAddressChange: assign({
        customer: ({ customer }, { value }) => {
          const { addressId, ...rest } = value;
          const newAddresses = customer.addresses?.map((address) => {
            if (address.addressId === addressId) {
              return {
                ...address,
                ...rest,
              };
            }
            return address;
          });
          return { ...customer, ...{ addresses: newAddresses } };
        },
      }),
      handleEditCustomer: assign((context, { customer }) => {
        if (!customer.addresses) {
          customer.addresses = [
            {
              addressId: 0,
              addressLine: "",
              addressLineTwo: "",
              city: "",
              state: "",
              zipCode: "",
            },
          ];
        }
        return { ...context, customer, mode: "edit" };
      }),
      setLocationStatus: assign({
        hasLocations: (_, event) => {
          return event.hasLocations;
        },
      }),
      onInputChange: assign({
        customer: (context, { value }) => {
          if (value.phone) {
            if (value.phone.length > 14) {
              return { ...context.customer };
            }

            const phone = new AsYouType("US").input(value.phone);

            return { ...context.customer, ...{ phone } };
          }
          return { ...context.customer, ...value };
        },
      }),
      deleteCustomerById: (_, { customer }) => {
        try {
          API.del("customers", "/customers", {
            body: { sk: customer.sk, pk: customer.pk },
          });
        } catch (error) {
          console.log("error: ", error);
        }
      },
      clearCustomer: assign({
        customer: (_) => {
          return {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            note: "",
            storeLocation: "",
            tags: [],
            bundles: [],
            addresses: [
              {
                addressId: 0,
                addressLine: "",
                addressLineTwo: "",
                city: "",
                state: "",
                zipCode: "",
              },
            ],
          };
        },
      }),
      customerError: assign({
        error: (_) => "OMGOMGOMG",
      }),
    },
  }
);

export const useCustomer = () => {
  const { hasLocations } = useProfileMachine();
  const customerService = useInterpret(customerMachine, {
    context: { hasLocations },
  });
  const [state, send] = useActor(customerService);
  React.useEffect(() => {
    send({ type: "update_location_status", hasLocations });
  }, [send, hasLocations]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    send({ type: "change_form_value", value: { [name]: value } });
  };

  const handleAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    addressId: number
  ) => {
    const { name, value } = e.target;
    send({ type: "change-address", value: { [name]: value, addressId } });
  };

  const handleEditCustomer = (customer: TCustomer) => {
    send({
      type: "show_edit_customer",
      customer,
    });
  };

  const handleDeleteCustomer = (customer: TCustomer) => {
    send({
      type: "delete_customer",
      customer,
    });
  };

  const handleHideCreateCustomer = () => {
    send({
      type: "hide_create_customer",
    });
  };

  const handleShowCreateCustomer = () => {
    send({
      type: "show_create_customer",
    });
  };

  return {
    customerService,
    customer: state.context.customer,
    state,
    send,
    handleDeleteCustomer,
    handleEditCustomer,
    // handleCreateCustomer,
    // handleUploadCustomers,
    handleHideCreateCustomer,
    handleShowCreateCustomer,
    handleInputChange,
    handleAddressChange,
    // handleClearCustomer,
    // handleSubmitForm,
    // handleUpdateLocationStatus,
  };
};
