import React from "react";
import { useActor, useInterpret } from "@xstate/react";
import { useNavigate } from "react-router-dom";
import useShopDetails from "../hooks/useShopDetails";
import { bundlesMachine } from "../machines/bundlesMachine";
import { broadcastMachine } from "../machines/broadcastMachine";
import { tagsMachine } from "../machines/tagsMachine";
import {
  commandPaletteMachine,
  PaletteOption,
} from "../machines/commandPaletteMachine";
import { InterpreterFrom } from "xstate";
import { totalsMachine } from "./totalsContext";

const initialState = {
  broadcastService: {} as InterpreterFrom<typeof broadcastMachine>,
  bundlesService: {} as InterpreterFrom<typeof bundlesMachine>,
  commandPaletteService: {} as InterpreterFrom<typeof commandPaletteMachine>,
  tagsService: {} as InterpreterFrom<typeof tagsMachine>,
  totalsService: {} as InterpreterFrom<typeof totalsMachine>,
};

export const GlobalServicesContext = React.createContext(initialState);

interface Props {
  children: React.ReactNode;
}

export const GlobalServicesProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { shop } = useShopDetails();
  const commands: PaletteOption[] = [
    {
      tag: "Customers",
      label: "Customers",
      value: "Go to the main customer view",
      action: () => navigate("customers"),
      visible: true,
    },
    {
      tag: "Customers",
      label: "Add a customer",
      value: "Create a new customer",
      action: () => navigate("customers?create"),
      visible: true,
    },
    {
      tag: "Titles",
      label: "Titles",
      value: "Go to the main titles view",
      action: () => navigate("titles"),
      visible: false,
    },
    {
      tag: "Titles",
      label: "Add a title",
      value: "Create a title",
      action: () => navigate("titles?create"),
      visible: true,
    },
    {
      tag: "Titles",
      label: "Create a Bundle",
      value: "Create a set of titles",
      action: () => navigate("titles"),
      visible: false,
    },
    {
      tag: "Account",
      label: "Profile",
      value: "Edit your profile details",
      action: () => navigate("profile"),
      visible: true,
    },
    {
      tag: "Orders",
      label: "Receiving",
      value: "Check in an order",
      action: () => navigate("receiving"),
      visible: false,
    },
    {
      tag: "Orders",
      label: "Ordering",
      value: "Prepare an order",
      action: () => navigate("orders"),
      visible: false,
    },
  ];

  const commandPaletteService = useInterpret(commandPaletteMachine, {
    context: { ...initialState, defaultCommands: commands, commands },
  });

  const tagsService = useInterpret(tagsMachine);
  const totalsService = useInterpret(totalsMachine);
  const [, tagsSend] = useActor(tagsService);

  const bundlesService = useInterpret(bundlesMachine);
  const [, bundlesSend] = useActor(bundlesService);
  const broadcastService = useInterpret(broadcastMachine);

  React.useEffect(() => {
    if (shop) {
      bundlesSend({ type: "loaded", shop });
      tagsSend({ type: "load-tags", shop });
    }
  }, [bundlesSend, tagsSend, shop]);

  return (
    <GlobalServicesContext.Provider
      value={{
        broadcastService,
        bundlesService,
        commandPaletteService,
        tagsService,
        totalsService,
      }}
    >
      {children}
    </GlobalServicesContext.Provider>
  );
};
