import React from "react";

import { PageBanner } from "../components";
import LocationInput from "../components/LocationInput";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import { useProfileMachine } from "../context/profileContext";
import { UpdateValueRow } from "../components/UpdateValueRow";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

async function updateUser(name: string) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    name,
  });
}

export default function Profile() {
  const {
    clerk,
    handleUpdateProfileValues,
    handleNameChange,
    hasLocations,
    isDeleting,
    isLoading,
    isSubmitting,
    profile,
    send,
  } = useProfileMachine();

  const [isUpdating, setIsUpdating] = React.useState(false);

  const handleUpdateName = async (name: string) => {
    setIsUpdating(true);
    try {
      await updateUser(name);
      handleNameChange(name);
    } catch (error) {
      if (error instanceof Error) {
        toast(error.message);
      }
    }
    setIsUpdating(false);
  };

  return (
    <>
      <PageBanner heading="Your Profile">
        Manage global settings that relate to your emails, messaging and contact
        information.
      </PageBanner>
      <div className="grid grid-cols-12 gap-6">
        <Paper>
          <PaperHeader>Your Profile</PaperHeader>
          <div className="p-3">
            <ul className="my-1">
              <UpdateValueRow
                name="clerkEmail"
                editable={false}
                loading={isLoading}
                label="Clerk Email"
                value={clerk?.attributes?.email || ""}
              />
              <UpdateValueRow
                name="name"
                tip="This is the name that will appear on your emails and messages."
                loading={isLoading || isUpdating}
                label="Clerk Name"
                value={clerk?.attributes?.name || ""}
                handleSubmit={handleUpdateName}
              />
            </ul>
          </div>
        </Paper>
        <Paper>
          <PaperHeader>Shop Details</PaperHeader>
          <div className="p-3">
            <ul className="my-1">
              <UpdateValueRow
                name="shopName"
                handleUpdate={handleUpdateProfileValues}
                handleSubmit={() => send("submit")}
                label="Shop name"
                loading={isLoading}
                value={profile?.shopName || ""}
              />
              <UpdateValueRow
                name="contactName"
                handleUpdate={handleUpdateProfileValues}
                handleSubmit={() => send("submit")}
                label="Contact name"
                tip="This is the default name will appear on most emails and messages."
                loading={isLoading}
                value={profile?.contactName || ""}
              />
              <UpdateValueRow
                name="website"
                handleUpdate={handleUpdateProfileValues}
                handleSubmit={() => send("submit")}
                label="Website"
                loading={isLoading}
                value={profile?.website || ""}
              />
              <UpdateValueRow
                name="shopEmail"
                handleUpdate={handleUpdateProfileValues}
                handleSubmit={() => send("submit")}
                label="Shop email"
                loading={isLoading}
                value={profile?.shopEmail || ""}
              />
              <UpdateValueRow
                name="phone"
                handleUpdate={handleUpdateProfileValues}
                handleSubmit={() => send("submit")}
                label="Shop phone"
                loading={isLoading}
                value={profile?.phone || ""}
              />
            </ul>
          </div>
        </Paper>

        <Paper>
          <PaperHeader>Locations</PaperHeader>
          <div className="p-3">
            <div>
              <LocationInput
                hasLocations={hasLocations}
                isDeleting={isDeleting}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                profile={profile}
                send={send}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}
