import { THoldFile } from "../types/holdFile";
import { TTitle } from "../types/titles";

interface TitleWithCustomer {
  titleId: [THoldFile[]];
}

export const reshapeTitlesToCustomersWithTitle = (
  titlesOnHold: TitleWithCustomer,
  preselectedTitles: TTitle[]
) => {
  const selectedTitles = preselectedTitles.map((title) => title.sk);
  const selectedFiles = Object.entries(titlesOnHold)
    .filter((title) => selectedTitles.includes(title[0]))
    .flatMap((title) => {
      return title[1];
    });

  return selectedFiles?.reduce((acc, file) => {
    const name = `${file.lastName}, ${file.firstName}`;
    if (acc[name]) {
      return {
        ...acc,
        [name]: { files: [...acc[name].files, file] },
      };
    }
    return {
      ...acc,
      [name]: { files: [file] },
    };
  }, {});
};
