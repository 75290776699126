import { Link } from "react-router-dom";
import { TCustomer } from "../types/customer";
import renderPhone from "../utils/renderPhone";

interface ICustomerActionRow {
  customer: TCustomer;
  filters: string;
  handleToggleCustomer: (customer: TCustomer) => void;
  checked: boolean;
}

const CustomerActionRow = ({
  customer,
  filters = "",
  handleToggleCustomer,
  checked,
}: ICustomerActionRow) => {
  const customerValues =
    customer.firstName.toLowerCase() +
      customer.phone +
      customer.lastName.toLowerCase() || "";

  if (!customerValues.includes(filters.toLowerCase()) && filters?.length >= 2) {
    return null;
  }

  return (
    <>
      <tr className={"odd:bg-indigo-100"}>
        <td className="border border-indigo-300 px-4 py-2">
          <Link to={`/customers/${customer.customerId}`}>
            {customer.lastName}, {customer.firstName}
          </Link>
        </td>
        <td className="border border-indigo-300 px-4 py-2">{customer.email}</td>
        <td className="border border-indigo-300 px-4 py-2">
          {renderPhone(customer.phone)}
        </td>
        <td className="border border-indigo-300 px-4 py-2 text-center">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => handleToggleCustomer(customer)}
          />
        </td>
      </tr>
    </>
  );
};

export default CustomerActionRow;
