import { useSelector } from "@xstate/react";
import { API } from "aws-amplify";
import React from "react";
import { assign, createMachine, StateFrom } from "xstate";
import { GlobalServicesContext } from "../context/globalServicesContext";
import { TTag } from "../types/tag";

type Context = {
  shop: string;
  tags: TTag[];
};
type Services = {
  loadAllTags: {
    data: {
      statusCode: string;
      tags: TTag[];
    };
  };
  deleteTag: {
    data: boolean;
  };
  editTag: {
    data: boolean;
  };
};
type Events =
  | { type: "refresh" }
  | { type: "create"; value: string }
  | { type: "delete"; tag: TTag }
  | { type: "edit"; value: string; tag: TTag }
  | {
      type: "load-tags";
      shop: string;
    };

export const tagsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBcCGVYFoC2qDGAFgJYB2YAdAA5gkSlQDEANgPaoSZoaKiUuxFkRFiR4gAHogCMATgBM5AOwBmZXIAc6mVLkBWOQAZFAGhABPRHLXl1u3VIMAWewDYXTuQF9PprllyEpBQAZmDIgSSMECIUpABuLADWFH44+MRk5KHhGVAI8Sx4qEIiANoGALpifAIlokgSlorkVi4yinIyMo4uPQYuJuaIyprkyoq6akZSuorqigPevuj+6UFZYRGMYABOOyw7VEzFwQfY5KkBGSGbufkkCUV15VUNNYLC9aCSCJiKUuQXFopFJHMoXFZHOpnKYLL9lI5HC1HFIEbJdI45I4DMoliBLmtMngdmBivQGNFMgVkhcVmkIhRiaShJF7o8yWVKtV+B8RGIflYWnIXHYDFoZPpJr1YYhMKj1GMJcoZO4cb1Jl4fPi6Vd1kyyZEGLt9odKMdkKcducCQzyPqWXkCk9Pi9ubVPvzLMohSLdGKupLwY4Zb8ZAZyGCpJoodjZm1FHibddyBAwEwwuTKbEHkkUjrCRRU+mHWzChySK63jy6p6EIK5MLReLA9Khr8oxGVHYutpnAZQbpE-nbUWM4bjQcjiczrSMPTk6OS07y5XeNWPQ0Bd6G77-RK9EGQ50I82Qf8XFHDJrlnPdZlIB9DVnyNS87eC+QH0uc87Oa81+6fKbl6PpNgGB6tnCDgAoi6idOoIwqn67RDu+tpfuSE6mualrWsOyYYayy7PFyVaAV8jR1tujZ+s2EHBm2oIyBGILqAYEr6P83S4lqSbrEQEDpgwJLBCSsAEG6vIUT8mAaLo5CzE2GgqB06hHkCNgyMougiqimKTHYqGrLaAlCV+kk1sBvxyQpEy0cp4waCGrHkNB3RaOxPRdFIRnzvxglgBSaZhGAFkbt8so2Yp9nzI5altnKNiyPI-Q6WKmK9L5d6xAFDD2qFZFSbWmCODICp+ux-buBMUiQbKUbhlioJgh0-qqN4WokCwqbwA0fGZNQtD0GFQERQgUj-Ce4JyAs7H-MoMwhtpzEYk4E1uECiKOFlH7ZFsI3SZYfrkGqYK6BKQLjOoLghiVSIdGGBh6O0M2KGCO22vaw2FZZY2YLM3rQi9bGKO0LW3YiSidAYT3nR0ihvdtvH4esi7fQBRVWSVWktPYGgIb2IpuM5E0RmovT-BoMzuNe2poQRdAOgdxUxko2kbeCWnCsoS0IidunYmKtiqDxN7GcmpkFRjv2UXdzHjAshgIxecwwglqgAs9F6GL0XRYkjYt+WQzNY3DNilapRhg+MEOk3IOhAt5IoLeCHWeEAA */
  createMachine(
    {
      context: { tags: [], shop: "" },
      tsTypes: {} as import("./tagsMachine.typegen").Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Events,
        services: {} as Services,
      },
      id: "tags-machine",
      initial: "pending",
      states: {
        pending: {
          on: {
            "load-tags": {
              actions: "addShopToContext",
              target: "fetching",
            },
          },
        },
        fetching: {
          invoke: {
            src: "loadAllTags",
            onDone: [
              {
                actions: "addTagsToContext",
                target: "idle",
              },
            ],
            onError: [
              {
                actions: "addErrorTag",
                target: "idle",
              },
            ],
          },
        },
        creating: {
          invoke: {
            src: "createTag",
            onDone: [
              {
                target: "fetching",
              },
            ],
            onError: [
              {
                target: "fetching",
              },
            ],
          },
        },
        deleting: {
          invoke: {
            src: "deleteTag",
            onDone: [
              {
                target: "fetching",
              },
            ],
            onError: [
              {
                target: "fetching",
              },
            ],
          },
        },
        editing: {
          invoke: {
            src: "editTag",
            onDone: [
              {
                target: "fetching",
              },
            ],
            onError: [
              {
                target: "fetching",
              },
            ],
          },
        },
        idle: {
          on: {
            refresh: {
              target: "fetching",
            },
            edit: {
              target: "editing",
            },
            delete: {
              target: "deleting",
            },
            create: {
              target: "creating",
            },
          },
        },
      },
    },
    {
      actions: {
        addShopToContext: assign((context, event) => {
          return { ...context, shop: event.shop };
        }),
        addTagsToContext: assign((context, event) => {
          const tags = event.data.tags?.sort((a, b) => (a.sk > b.sk ? 1 : -1));
          return { ...context, tags };
        }),
        addErrorTag: assign((context) => {
          return {
            ...context,
            tags: [{ sk: "ERROR", pk: "ERROR", tag: "Please Refresh" }],
          };
        }),
      },
      services: {
        createTag: async (context, event) => {
          try {
            await API.post("customers", "/tags", {
              body: {
                tag: event.value,
                shop: context.shop,
              },
            });

            return true;
          } catch (error) {
            return false;
          }
        },
        editTag: async (context, event) => {
          await API.put("customers", "/tags", {
            body: {
              value: event.value,
              tag: event.tag,
              shop: context.shop,
            },
          });

          return true;
        },
        deleteTag: async (_, event) => {
          await API.del("customers", "/tags", {
            body: {
              pk: event.tag.pk,
              sk: event.tag.sk,
            },
          });

          return true;
        },
        loadAllTags: async (context) => {
          try {
            const res = await API.get("customers", "/tags", {
              queryStringParameters: {
                shop: context.shop,
              },
            });

            if (res.statusCode !== 200) {
              throw new Error("WHHHHHAAAAA");
            }

            return res;
          } catch (error) {
            return error;
          }
        },
      },
    }
  );

export const useTags = () => {
  const { tagsService } = React.useContext(GlobalServicesContext);
  const tags = useSelector(tagsService, selectTags);

  return {
    tags,
  };
};

export const selectTags = (state: StateFrom<typeof tagsMachine>) =>
  state.context.tags;
