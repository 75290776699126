interface Props {
  send: (event: { type: "open" }) => void;
}

const CommandPaletteButton = ({ send }: Props) => {
  return (
    <button
      className="absolute flex justify-center items-center bottom-8 left-8 h-10 w-10 rounded-full bg-indigo-500 z-30 shadow-md print:hidden"
      onClick={() => send({ type: "open" })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-indigo-300"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
      </svg>
    </button>
  );
};

export default CommandPaletteButton;
