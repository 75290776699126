type Props = {
  isSelected: boolean;
};

const ReceivingIcon: React.FC<Props> = ({ isSelected }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 mr-3 ${isSelected ? "fill-indigo-800" : ""}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      className={` ${isSelected ? "text-indigo-300" : "text-gray-400"}`}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
    />
  </svg>
);

export default ReceivingIcon;
