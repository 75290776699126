interface BasePaperHeader {
  children: React.ReactNode;
  withoutHeading?: boolean;
  headerClasses?: string;
}

interface PlainHeader extends BasePaperHeader {
  action?: undefined;
  actionText?: undefined;
}
interface ActionHeader extends BasePaperHeader {
  action: () => void;
  actionText: string;
}

type Props = PlainHeader | ActionHeader;

/**
 * a header component with an optional action and action text button
 *
 * @param {*} {
 *  action,
 *  actionText,
 *  children,
 *  headerClasses
 *  withoutHeading
 * }
 * @return {*}
 */
const PaperHeader: React.FC<Props> = ({
  action,
  actionText,
  children,
  headerClasses,
  withoutHeading,
}) => {
  return (
    <header className="print:hidden px-5 py-4 border-b border-gray-100 flex justify-between">
      {withoutHeading ? (
        <>{children}</>
      ) : (
        <h2 className={`font-semibold text-gray-800 ${headerClasses}`}>
          {children}
        </h2>
      )}
      {actionText && (
        <button className="text-sm text-green-600" onClick={action}>
          {actionText}
        </button>
      )}
    </header>
  );
};

export default PaperHeader;
