import React from "react";

import { PageBanner } from "../components";
import AccordionItem from "../components/AccordionItem";
import Button from "../components/buttons/Button";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import Spinner from "../components/Spinner";
import { useTotals } from "../context/totalsContext";
import { distributors } from "../types/distributors";
import { publishers } from "../types/publisher";
import { createDetailFromData } from "../utils/createDetailFromData";

export default function Orders() {
  const [selected, setSelected] = React.useState<string>("");
  const [selectedDistributor, setSelectedDistributor] =
    React.useState<string>("");

  const {
    details,
    filteredTotals,
    handleFilterDistributor,
    handleLoadDetails,
    handleLoadPublisher,
    isDetailsLoading,
    ready,
    setTotalsFilters,
    totalsFilters,
  } = useTotals();

  const toggleDistributors = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "distributor") {
      setSelectedDistributor(e.target.name);
    }
    // @ts-ignore
    handleFilterDistributor(e.target.name);
  };

  const toggleSelections = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.name);
    handleLoadPublisher(e.target.name);
    setTotalsFilters("");
    handleFilterDistributor("");
    setSelectedDistributor("");
  };

  return (
    <>
      <PageBanner heading="View totals on hold files">
        Select a filter to see just Marvel, DC, Image, or others.
      </PageBanner>
      <div className="flex">
        <input
          className="my-3 mr-3"
          type="text"
          autoFocus
          placeholder="Find a title"
          value={totalsFilters}
          onInput={(e) => {
            setTotalsFilters(e.currentTarget.value);
          }}
        />
        <Button
          variant="simple-action"
          disabled={totalsFilters.length === 0}
          onClick={() => {
            setTotalsFilters("");
          }}
        >
          Clear
        </Button>
      </div>
      <Paper className="p-4">
        <PaperHeader
          action={() => window.print()}
          actionText="Print"
          withoutHeading={true}
        >
          <form
            className="flex flex-col font-light flex-1 pr-8"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="flex flex-1 flex-wrap  justify-between pb-4">
              {publishers.map((publisher) => {
                const isSelected = selected?.toString() === publisher;
                return (
                  <div
                    key={publisher}
                    className={`flex items-center text-base ${
                      isSelected && "text-indigo-700 underline font-normal"
                    }`}
                  >
                    <input
                      type="radio"
                      checked={isSelected}
                      name={publisher}
                      onChange={toggleSelections}
                    />
                    <label className="pl-2" htmlFor={publisher}>
                      {publisher}
                    </label>
                  </div>
                );
              })}
            </div>
            <div>
              <p className="text-slate-800 font-medium underline pb-2">
                Distributors
              </p>
              <div className="flex flex-1 flex-wrap">
                {distributors.map(({ value: distributor }) => {
                  const isSelected = selectedDistributor === distributor;
                  return (
                    <div
                      key={distributor}
                      className={`flex items-center text-base pr-4 ${
                        isSelected && "text-indigo-700 underline font-normal"
                      }`}
                    >
                      <input
                        id="distributor"
                        type="radio"
                        checked={isSelected}
                        name={distributor}
                        onChange={toggleDistributors}
                      />
                      <label className="pl-2" htmlFor={distributor}>
                        {distributor}
                      </label>
                    </div>
                  );
                })}
                <Button
                  variant="simple-action"
                  disabled={!Boolean(selectedDistributor)}
                  onClick={() => {
                    handleFilterDistributor("");
                    setSelectedDistributor("");
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </form>
        </PaperHeader>
        {ready ? (
          <ul className="my-1">
            {filteredTotals.map((item) => {
              const titleKey = item.sk.replace("HOLDFILE#", "");
              //@ts-ignore
              const info = details[titleKey] || [];
              const detail = info.map(createDetailFromData);

              return (
                <AccordionItem
                  key={item.sk}
                  actionLinkText="View"
                  actionLink={`/titles/${item.sk.replace(
                    "HOLDFILE#TITLE#",
                    ""
                  )}`}
                  count={item.tally}
                  details={detail}
                  getDetails={() => handleLoadDetails(item)}
                  isDetailsLoading={isDetailsLoading}
                  title={item.holdFile}
                />
              );
            })}
          </ul>
        ) : (
          <Spinner size="48px" />
        )}
      </Paper>
    </>
  );
}
