/**
 * Returns morning, afternoon or evening
 *
 * @return String
 */

const getGreetingTime = () => {
  const now = new Date();
  const currentHours = now.getHours();

  if (currentHours < 12) {
    return "morning";
  }

  if (currentHours < 18) {
    return "afternoon";
  }

  return "evening";
};

export default getGreetingTime;
