import { Transition } from "@headlessui/react";

import { useBoolean, useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import Button from "./buttons/Button";

interface Props {
  children: React.ReactNode;
  storageKey: string;
  dismissible?: boolean;
  dismissalCount?: number;
}

export const InfoBanner = ({
  storageKey,
  children,
  dismissible = true,
  dismissalCount = 5,
}: Props) => {
  const [, setKeyValue] = useLocalStorage(`hide-${storageKey}`, "0");
  const count = useReadLocalStorage(`hide-${storageKey}`) as string;
  const countValue = parseInt(count || "0") < dismissalCount;

  const { value: showInfoBanner, setFalse } = useBoolean(countValue);

  const hideInfoBanner = () => {
    setKeyValue((prevValue: string) => `${parseInt(prevValue) + 1}`);
    setFalse();
  };

  return (
    <Transition
      show={showInfoBanner}
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="border border-indigo-100 border-x-4 px-4 py-2 mb-4 flex items-start">
        <p className="prose">{children}</p>
        {dismissible && (
          <Button
            variant="informational"
            onClick={hideInfoBanner}
            className="pt-1 ml-4 pr-0"
          >
            dismiss
          </Button>
        )}
      </div>
    </Transition>
  );
};
