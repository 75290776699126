import React from "react";
import Spinner from "./Spinner";
import Tooltip from "./Tooltip";

interface BaseProps {
  label: string;
  loading: boolean;
  name: string;
  value: string;
  tip?: string;
}
interface PresentationalProps extends BaseProps {
  editable: false;
  handleDelete?: undefined;
  handleSubmit?: undefined;
  handleUpdate?: undefined;
}

interface EditableProps extends BaseProps {
  editable?: true;
  handleUpdate?: (value: string, name: string) => void;
  handleSubmit?: (() => void) | ((name: string) => Promise<void>);
  handleDelete?: (updateValue: string, name?: string) => void;
}

type Props = PresentationalProps | EditableProps;

export const UpdateValueRow = ({
  editable = true,
  handleDelete,
  handleSubmit,
  handleUpdate,
  label,
  loading,
  name = "",
  tip,
  value,
}: Props) => {
  return (
    <li>
      <div className="flex justify-between items-center px-4">
        {loading ? (
          <Spinner size="24px" />
        ) : (
          <InputForm
            editable={editable}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            label={label}
            name={name}
            value={value}
            tip={tip}
          />
        )}
      </div>
    </li>
  );
};

const InputForm = ({
  name,
  value,
  label,
  handleDelete,
  handleSubmit,
  handleUpdate,
  editable,
  tip,
}: Omit<Props, "loading">) => {
  const [inputValue, setInputValue] = React.useState(value);
  const disabled = !inputValue;
  return (
    <div className="flex justify-between items-center mb-4 flex-1">
      {editable ? (
        <>
          <form
            className="flex flex-1"
            onSubmit={(e) => {
              e.preventDefault();
              if (handleSubmit) {
                handleSubmit(inputValue);
              }
            }}
          >
            <label className="text-sm flex">
              {tip ? (
                <Tooltip tip={tip} className="w-40 flex-1">
                  <span>{label}:</span>
                </Tooltip>
              ) : (
                <span className="w-40 flex-1">{label}:</span>
              )}
              <input
                type="text"
                className="ml-4 flex-2"
                name={name}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  handleUpdate && handleUpdate(e.target.value, name);
                }}
              />
            </label>
            <button
              disabled={disabled}
              className={`ml-4 text-sm text-green-600 ${
                disabled ? "opacity-60 pointer-events-none" : ""
              }`}
            >
              Update
            </button>
          </form>
          {handleDelete && (
            <button
              className="mx-4 text-sm text-red-500 leading-normal"
              onClick={() => alert("delete not implemented")}
            >
              Delete
            </button>
          )}
        </>
      ) : (
        <p className="">
          <span className="text-sm mr-8 text-gray-700">{label}:</span>
          {value}
        </p>
      )}
    </div>
  );
};
