import ArrowIcon from "../icons/ArrowIcon";
import PlusIcon from "../icons/PlusIcon";
import { CloseIcon } from "../icons/CloseIcon";

type Variants =
  | "contained"
  | "simple-action"
  | "create"
  | "destructive"
  | "informational"
  | "informational-destructive"
  | "inverted-create"
  | "outlined"
  | "primary"
  | "secondary"
  | "submission";

type Icons = "arrow" | "plus" | "close";

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: Icons;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
  variant?: Variants;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = "",
  disabled = false,
  icon = undefined,
  id = "button",
  onClick,
  type = "button",
  variant = "outlined",
}) => {
  const btnStyles =
    "border-2 py-2 px-4 rounded-lg focus:outline-none focus:ring-2 shadow-md transition-colors duration-200";

  const greenContained =
    "font-semibold border-0 bg-green-500 text-white hover:bg-green-700";

  const variantStyle = {
    primary:
      "font-semibold border-indigo-400 text-indigo-700 hover:bg-indigo-400 hover:text-white",
    create: greenContained,
    "simple-action":
      "shadow-none border-none font-normal text-sm text-blue-600 hover:text-blue-700 focus:ring-0 focus:outline-none",
    secondary: greenContained,
    "inverted-create":
      "font-semibold border-1 border-green-500 text-green-500 hover:bg-green-500 hover:text-white",
    outlined:
      "font-semibold py-1.5 border-indigo-600 text-indigo-600 hover:bg-indigo-600 hover:text-white",
    submission:
      "font-semibold py-1.5 border border-green-700 text-green-700 hover:text-white hover:bg-green-700",
    destructive:
      "font-semibold py-1.5 border border-red-600 bg-red-600 text-white hover:bg-red-800 hover:border-red-800",
    contained:
      "font-semibold border-0 bg-indigo-600 text-white hover:bg-indigo-700",
    informational:
      "shadow-none border-none font-normal text-sm text-indigo-400 hover:text-indigo-700 focus:ring-0 focus:outline-none",
    "informational-destructive":
      "shadow-none border-none text-rose-500 font-normal text-sm hover:text-rose-600 focus:ring-0 focus:outline-none",
  };

  const iconType = {
    arrow: <ArrowIcon />,
    close: <CloseIcon />,
    plus: <PlusIcon />,
  };

  return (
    <button
      className={`${btnStyles} ${
        variantStyle[variant]
      } shadow-md touch-none select-none ${
        disabled ? "opacity-40" : ""
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      id={id}
      data-testid="button"
      type={type}
    >
      {children}
      {icon && iconType[icon]}
    </button>
  );
};

export default Button;
