/**
 * Returns formatted date
 * ex: 09/21/1978
 *
 * @param {*} date number
 *
 * @return string
 */
const dateFormat = (date: number | string) => {
  const isDate = new Date(date);
  if (!(isDate instanceof Date && !isNaN(isDate.valueOf()))) return "";

  return new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(isDate));
};

export default dateFormat;
