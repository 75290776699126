import { useActor, useSelector } from "@xstate/react";
import { API } from "aws-amplify";
import React from "react";
import { createMachine, assign, StateFrom } from "xstate";
import useShopDetails from "../hooks/useShopDetails";
import { TTitle } from "../types/titles";
import { GlobalServicesContext } from "../context/globalServicesContext";

interface BaseBundle {
  pk: string;
  sk: string;
  bundleName: string;
}

interface Bundle extends BaseBundle {
  bundle: string[];
}

interface BundleSubmit extends BaseBundle {
  bundle: string[];
}

type Context = {
  shop: string;
  bundles: Bundle[];
};

type Services = {
  getAllBundles: {
    data: Bundle[];
  };
  createBundle: {
    data: {
      statusCode: number;
      item: Bundle;
    };
  };
  deleteBundle: {
    data: Bundle;
  };
  editBundle: {
    data: Bundle;
  };
};

type Events =
  | { type: "loaded"; shop: string }
  | { type: "show" }
  | { type: "hide" }
  | { type: "delete"; bundle: Bundle }
  | {
      type: "create";
      value: {
        bundle: TTitle[];
        bundleName: string;
        shop: string;
      };
    }
  | {
      type: "edit";
      value: {
        form: BundleSubmit;
        specificBundle: string;
      };
    };

export const bundlesMachine = createMachine(
  {
    tsTypes: {} as import("./bundlesMachine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    id: "bundles-machine",
    initial: "pending",
    context: {
      bundles: [],
      shop: "",
    },
    states: {
      pending: {
        on: {
          loaded: {
            target: "fetching",
            actions: ["addShopToContext"],
          },
        },
      },
      fetching: {
        invoke: {
          src: "getAllBundles",
          onDone: {
            target: "idle",
            actions: "addBundlesToContext",
          },
          onError: {
            target: "idle",
          },
        },
      },
      idle: {
        on: {
          show: {
            target: "showing",
          },
          delete: {
            target: "deleting",
          },
          edit: {
            target: "editing",
          },
        },
      },
      showing: {
        on: {
          hide: {
            target: "idle",
          },
          create: {
            target: "creating",
          },
        },
      },
      creating: {
        invoke: {
          src: "createBundle",
          onDone: {
            target: "idle",
            actions: ["addBundleToBundles"],
          },
          onError: {
            target: "idle",
          },
        },
      },
      deleting: {
        invoke: {
          src: "deleteBundle",
          onDone: {
            target: "idle",
            actions: ["removeBundleFromBundles"],
          },
          onError: {
            target: "idle",
          },
        },
      },
      editing: {
        invoke: {
          src: "editBundle",
          onDone: {
            target: "fetching",
            actions: ["updateBundles"],
          },
          onError: {
            target: "idle",
          },
        },
      },
    },
  },
  {
    services: {
      createBundle: async (_, event) => {
        const response = await API.post("customers", "/bundles", {
          body: {
            bundle: event.value.bundle,
            bundleName: event.value.bundleName,
            shop: event.value.shop,
          },
        });

        return response;
      },
      editBundle: async (_, event) => {
        const { bundle } = await API.put(
          "customers",
          `/bundles/${event.value.specificBundle}`,
          {
            body: {
              bundle: event.value.form,
            },
          }
        );

        return bundle;
      },
      getAllBundles: async (context, event) => {
        const { bundles } = await API.get("customers", "/bundles", {
          queryStringParameters: {
            shop: context.shop,
          },
        });

        return bundles;
      },
      deleteBundle: async (context, event) => {
        await API.del("customers", "/bundles", {
          body: {
            shop: context.shop,
            bundle: event.bundle,
          },
        });

        return event.bundle;
      },
    },
    actions: {
      updateBundles: assign({
        bundles: (context, event) => {
          return context.bundles.map((bundle) => {
            if (bundle.sk === event.data.sk) {
              return event.data;
            }
            return bundle;
          });
        },
      }),
      addShopToContext: assign((context, event) => {
        return { ...context, shop: event.shop };
      }),
      addBundlesToContext: assign({
        bundles: (_, event) => {
          return event.data;
        },
      }),
      addBundleToBundles: assign({
        bundles: (context, event) => {
          return [...context.bundles, event.data.item]?.sort((a, b) =>
            a.bundleName > b.bundleName ? 1 : -1
          );
        },
      }),
      removeBundleFromBundles: assign({
        bundles: (context, event) => {
          return context.bundles.filter(
            (bundle) => bundle.sk !== event.data.sk
          );
        },
      }),
    },
  }
);

export const useBundles = () => {
  const { shop } = useShopDetails();
  const { bundlesService } = React.useContext(GlobalServicesContext);
  const [state, send] = useActor(bundlesService);

  React.useEffect(() => {
    if (shop) {
      send({ type: "loaded", shop });
    }
  }, [send, shop]);
  const bundles = useSelector(bundlesService, selectBundles) || [];

  const isBundlesLoading =
    state.matches("editing") ||
    state.matches("deleting") ||
    state.matches("creating");

  const handleShowBundle = () => {
    send({ type: "show" });
  };

  const handleHideBundle = () => {
    send("hide");
  };

  const handleEditBundle = (form: BundleSubmit, specificBundle: string) => {
    send({
      type: "edit",
      value: {
        form: {
          ...form,
        },
        specificBundle,
      },
    });
  };

  const handleDeleteBundle = (bundle: Bundle) => {
    send({ type: "delete", bundle });
  };

  const handleCreateBundle = ({
    bundle,
    bundleName,
    shop,
  }: IHandleCreateBundle) => {
    send({ type: "create", value: { bundle, bundleName, shop } });
  };

  return {
    bundles,
    isBundlesLoading,
    handleCreateBundle,
    handleShowBundle,
    handleEditBundle,
    handleDeleteBundle,
    handleHideBundle,
    state,
    isBundlesVisible: state.matches("showing"),
  };
};

interface IHandleCreateBundle {
  bundle: TTitle[];
  bundleName: string;
  shop: string;
}

const selectBundles = (state: StateFrom<typeof bundlesMachine>) =>
  state.context.bundles;
