import React from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import { Link } from "react-router-dom";

// import config from "../config";
import { PageBanner } from "../components";
import { PlansModal } from "../components/PlansModal";
import useKeypress from "../hooks/useKeypress";
import { Modal } from "../components/Modal";
import ModalHeader from "../components/ModalHeader";
import { useBoolean } from "usehooks-ts";

export default function Plans() {
  const { value, setFalse, setTrue } = useBoolean(false);
  // const [stripePromise] = React.useState(loadStripe(config.stripeKey));

  useKeypress("Escape", () => setFalse());

  return (
    <>
      {/* <Elements stripe={stripePromise}> */}
      <Modal isOpen={value} onClose={setFalse}>
        <ModalHeader>Set up the Beta Plan</ModalHeader>
        <PlansModal />
      </Modal>
      {/* </Elements> */}
      <PageBanner heading="Pricing Plans">
        The only plan currently available is beta. Eventually, the beta plan
        will be stop being available. You'll have plenty of notice prior to this
        happening. The goal of comicshelper is that there will be enough value
        that you'll want to buy starter or standard.
      </PageBanner>
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
          <div className="p-5">
            <header className="flex justify-between items-start mb-2">
              {/* Menu button */}
              <ul className="relative inline-flex">
                <li>
                  <button
                    onClick={setTrue}
                    data-testid="show-alpha"
                    className="font-medium text-sm text-green-600 hover:text-green-800 flex py-1 px-3"
                  >
                    Founding member
                  </button>
                </li>
              </ul>
            </header>
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Founding member price
            </h2>
            <div className="pb-5">
              <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
                Features:
              </div>
              <ul>
                <li>Unlimited messaging</li>
                <li>Plan will disappear some day</li>
                <li>100% feature access</li>
                <li>Price valid for 1 year</li>
              </ul>
            </div>
            <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
              Pricing
            </div>
            <div className="flex justify-between align-bottom">
              <div className="flex items-start">
                <div className="text-3xl font-bold  mr-2">$4</div>
                <div className="text-sm font-semibold text-white px-1.5 bg-green-600 rounded-full">
                  annual
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
          <div className="p-5">
            <header className="flex justify-between items-start mb-2">
              {/* Menu button */}
              <ul className="relative inline-flex">
                <li>
                  <button
                    onClick={setTrue}
                    data-testid="show-beta"
                    className="font-medium text-sm text-green-600 hover:text-green-800 flex py-1 px-3"
                  >
                    Supporter Plan
                  </button>
                </li>
              </ul>
            </header>
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Special pricing
            </h2>
            <div className="pb-5">
              <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
                Features:
              </div>
              <ul>
                <li>Potential for messaging caps</li>
                <li>Pricing will change, relative value will remain</li>
                <li>100% feature access</li>
                <li>Plan available for 1 year</li>
              </ul>
            </div>
            <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
              Pricing
            </div>
            <div className="flex justify-between align-bottom">
              <div className="flex items-start">
                <div className="text-3xl font-bold  mr-2">$4</div>
                <div className="text-sm font-semibold text-white px-1.5 bg-blue-300 rounded-full">
                  monthly
                </div>
              </div>
              <div className="flex items-start">
                <div className="text-3xl font-bold mr-2">$39</div>
                <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
                  annual
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-gray-800 flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
          <div className="p-5">
            <header className="flex justify-between items-start mb-2">
              {/* Menu button */}
              <ul className="relative inline-flex">
                <li>
                  <Link
                    className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 pointer-events-none"
                    to="#0"
                  >
                    Standard
                  </Link>
                </li>
              </ul>
            </header>
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Best value or something?
            </h2>
            <div className="pb-5">
              <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
                Features:
              </div>
              <ul>
                <li>Something cool</li>
                <li>Something even cooler</li>
                <li>Something that's the coolest</li>
              </ul>
            </div>
            <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
              Pricing
            </div>
            <div className="flex justify-between">
              <div className="flex items-start">
                <div className="text-3xl font-bold  mr-2">$40</div>
                <div className="text-sm font-semibold text-white px-1.5 bg-blue-300 rounded-full">
                  monthly
                </div>
              </div>
              <div className="flex items-start">
                <div className="text-3xl font-bold mr-2">$399</div>
                <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
                  annual
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
