import React from "react";

type Props = {
  children: React.ReactNode;
  tip: String;
  className?: String;
};

const Tooltip: React.FC<Props> = ({ children, tip, className }) => {
  return (
    <span className={`has-tooltip ${className}`}>
      <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-800 font-normal text-base -mt-12 py-2 px-4">
        {tip}
      </span>
      {children}
    </span>
  );
};

export default Tooltip;
