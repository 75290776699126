import React from "react";
import { API } from "aws-amplify";
import { createMachine, assign, InterpreterFrom, StateFrom } from "xstate";
import { useActor, useInterpret, useSelector } from "@xstate/react";

import { TTitle } from "../types/titles";
import isDev from "../utils/isDev";
import useShopDetails from "../hooks/useShopDetails";
import { TDistributors } from "../types/distributors";

type Context = {
  shop?: string;
  titles: TTitle[];
  series?: {
    distributors?: TDistributors[];
    notes?: string;
    publisher: string;
    title: string;
  };
  errors?: string;
};

type Services = {
  getTitles: {
    data: TTitle[];
  };
  createTitle: {
    data: TTitle;
  };
  updateTitle: {
    data: TTitle;
  };
  removeTitle: { data: undefined };
  deleteTitle: { data: TTitle };
};

export type Events =
  | {
      type: "shop-loaded";
      value?: string;
    }
  | {
      type: "refresh-titles";
    }
  | {
      type: "clear-title";
    }
  | {
      type: "yes";
      currentTitle: TTitle;
    }
  | {
      type: "delete-title";
      currentTitle: TTitle;
    }
  | {
      type: "no";
    }
  | {
      type: "create-new-title";
    }
  | {
      type: "toggle-title-activation";
      currentTitle: TTitle;
    }
  | {
      type: "input-change";
      value: {
        distributors?: TDistributors[];
        notes?: string;
        publisher: string;
        title: string;
      };
    }
  | {
      type: "update-title";
      title: TTitle;
    };

export const titlesMachine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import("./titlesContext.typegen").Typegen0,
    schema: {
      services: {} as Services,
      context: {} as Context,
      events: {} as Events,
    },
    id: "titlesMachine",
    initial: "pending",
    context: {
      titles: [],
      series: {
        distributors: undefined,
        notes: undefined,
        publisher: "",
        title: "",
      },
      errors: "",
    },
    states: {
      pending: {
        on: {
          "shop-loaded": {
            target: "loading",
            actions: "insertShopIntoContext",
          },
        },
      },
      loading: {
        invoke: {
          src: "getTitles",
          onDone: {
            target: "loaded",
            actions: "addTitlesToContext",
          },
          onError: {
            target: "error",
            actions: "addErrorMessageToContext",
          },
        },
      },
      loaded: {
        on: {
          "input-change": {
            target: "loaded",
            actions: "onInputChange",
          },
          "clear-title": {
            target: "loaded",
            actions: "onClearTitle",
          },
          "create-new-title": {
            target: "submitting",
          },
          "toggle-title-activation": {
            target: "validating",
          },
          "delete-title": {
            target: "deleting",
          },
          "update-title": {
            target: "updating",
          },
        },
      },
      validating: {
        on: {
          yes: {
            target: "deactivating",
          },
          no: {
            target: "loaded",
          },
        },
      },
      updating: {
        invoke: {
          src: "updateTitle",
          onDone: {
            target: "loading",
          },
          onError: {
            target: "error",
          },
        },
      },
      submitting: {
        invoke: {
          src: "createTitle",
          onDone: {
            target: "loaded",
            actions: ["clearSeries"],
          },
          onError: {
            target: "error",
          },
        },
      },
      deactivating: {
        invoke: {
          src: "deactivateTitle",
          onDone: {
            target: "loading",
            actions: ["removeTitle"],
          },
          onError: {
            target: "error",
          },
        },
      },
      deleting: {
        invoke: {
          src: "deleteTitle",
          onDone: {
            target: "loading",
          },
          onError: {
            target: "error",
          },
        },
      },
      error: {
        always: {
          target: "loaded",
        },
      },
    },
  },
  {
    services: {
      createTitle: async (context) => {
        const { title } = await API.post("customers", "/title", {
          body: {
            shop: context.shop,
            publisher: context.series?.publisher,
            title: context.series?.title,
            notes: context.series?.notes,
            distributors: context.series?.distributors,
          },
        });

        return title;
      },
      deactivateTitle: async (context, { currentTitle }) => {
        await API.put("customers", `/title/deactivate`, {
          body: {
            title: currentTitle,
            shop: context.shop,
          },
        });

        return currentTitle;
      },
      deleteTitle: async (context, { currentTitle }) => {
        await API.del("customers", `/title/delete`, {
          body: {
            shop: context.shop,
            title: currentTitle,
          },
        });

        return currentTitle;
      },
      updateTitle: async (_, event) => {
        await API.put("customers", "/title", {
          body: {
            title: event.title,
          },
        });

        return event.title;
      },
      getTitles: async (context) => {
        const { titles } = await API.get("customers", "/title/all", {
          queryStringParameters: {
            shop: context.shop,
          },
        });

        return titles;
      },
    },
    actions: {
      onInputChange: assign({
        series: (context, { value }) => {
          return { ...context.series, ...value };
        },
      }),
      onClearTitle: assign({
        series: (_) => {
          return {
            distributors: undefined,
            notes: undefined,
            publisher: "",
            title: "",
          };
        },
      }),
      removeTitle: assign({
        titles: (context, event) => {
          return [...context.titles.filter((title) => title.sk !== event.data)];
        },
      }),
      clearSeries: assign((context, event) => {
        return {
          ...context,
          series: {
            title: "",
            publisher: "",
            notes: undefined,
            distributors: undefined,
          },
          titles: [...context.titles, event.data],
        };
      }),
      addTitlesToContext: assign({
        titles: (_, event) => {
          return event.data;
        },
      }),
      addErrorMessageToContext: assign({
        errors: (_) => "Please reload the page, something didn't load properly",
      }),
      insertShopIntoContext: assign({
        /*
         * the value is {type: "shop-loaded", event: {value: someCognitoId}}
         */
        shop: (_, { value }) => {
          return value;
        },
      }),
    },
  }
);

export const TitlesContext = React.createContext({
  titlesService: {} as InterpreterFrom<typeof titlesMachine>,
  titles: [] as TTitle[],
  status: {},
});

type Props = {
  children?: React.ReactNode;
};

export const selectStatus = (state: StateFrom<typeof titlesMachine>) => {
  return state.value;
};

export const useTitles = () => {
  const { shop } = useShopDetails();
  const { titlesService } = React.useContext(TitlesContext);
  const [state, send] = useActor(titlesService);
  React.useEffect(() => {
    if (shop) {
      send({
        type: "shop-loaded",
        value: shop,
      });
    }
  }, [send, shop]);
  const loading = useSelector(titlesService, selectIsTitlesLoading);
  const status = useSelector(titlesService, selectStatus);

  const titles = state.context.titles;

  const handleNo = () => {
    send({ type: "no" });
  };

  return {
    titles,
    status,
    handleNo,
    state,
    send,
    loading,
  };
};

export const TitlesProvider = ({ children }: Props) => {
  const { shop } = useShopDetails();
  const titlesService = useInterpret(titlesMachine, {
    devTools: isDev(),
  });
  const [state, send] = useActor(titlesService);
  const titles = state.context.titles;

  const status = useSelector(titlesService, selectStatus);

  React.useEffect(() => {
    if (shop) {
      send({
        type: "shop-loaded",
        value: shop,
      });
    }
  }, [send, shop]);

  return (
    <TitlesContext.Provider value={{ titlesService, titles, status }}>
      {children}
    </TitlesContext.Provider>
  );
};

export const selectIsTitlesLoading = (state: StateFrom<typeof titlesMachine>) =>
  state.value === "pending" || state.value === "loading";
