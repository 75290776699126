type Props = {
  isOpen: boolean;
};

const AccordionArrow: React.FC<Props> = ({ isOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-8 w-8 mr-4 text-purple-500 duration-300  ${
      isOpen && "rotate-90"
    }`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={1}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export default AccordionArrow;
