import React from "react";
import { Auth } from "aws-amplify";
import { IClerk } from "../types/clerk";

type Shop = {
  shop: string | null;
  clerk: IClerk | null;
  auth: null;
};

/**
 * returns the "shop" used for PK info
 * returns the clerk that is using the application
 *
 * @export
 * @return {*}
 */
export default function useShopDetails() {
  const [shop, setShop] = React.useState(null);
  const [clerk, setClerk] = React.useState(null);
  React.useEffect(() => {
    let active = true;

    const check = async () => {
      try {
        const currentClerk = await Auth.currentAuthenticatedUser();
        const group =
          currentClerk.signInUserSession.accessToken.payload[
            "cognito:groups"
          ][0];
        if (active) {
          setShop(group);
          setClerk(currentClerk);
        }
      } catch (error) {
        if (active) {
          setShop(null);
          setClerk(null);
        }
      }
    };

    check();

    return () => {
      active = false;
    };
  }, [setShop, setClerk]);

  return { shop, clerk } as Shop;
}
