/**
 * CapitalizeFirstLetter
 * Pretty much does what you'd think
 *
 * @param {string} str
 * @return {*} 
 */
const capitalizeFirstLetter = (str:string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default capitalizeFirstLetter