export type TPublisher =
  | "Boom"
  | "Dark Horse"
  | "DC"
  | "Dynamite"
  | "IDW"
  | "Image"
  | "Marvel"
  | "Other";

export const publishers: TPublisher[] = [
  "Boom",
  "Dark Horse",
  "DC",
  "Dynamite",
  "IDW",
  "Image",
  "Marvel",
  "Other",
];
