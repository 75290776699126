import { API } from "aws-amplify";
import { createMachine } from "xstate";
import { THoldFile } from "../types/holdFile";

type Context = {
  shopName: string;
};

type Services = {
  sendTextMessage: {
    data: { res: string };
  };
};
type Events = {
  type: "send-text-message";
  customerWithFile: THoldFile[];
  shopName: string | undefined;
  shop: string | null;
};

const smsMachine = createMachine(
  {
    tsTypes: {} as import("./smsMachine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    id: "sms-machine",
    initial: "idle",
    states: {
      idle: {
        on: {
          "send-text-message": {
            target: "sending-message",
          },
        },
      },
      "sending-message": {
        invoke: {
          src: "sendTextMessage",
          onDone: {
            target: "idle",
          },
          onError: {
            target: "idle",
          },
        },
      },
    },
  },
  {
    services: {
      sendTextMessage: async (context, event) => {
        const { customerId, firstName } = event.customerWithFile[0];
        const titlesMessage = event.customerWithFile
          ?.reduce((acc, item) => {
            if (acc.some((i) => i.holdFile === item.holdFile)) {
              return acc;
            }
            return [...acc, item];
          }, [] as THoldFile[])
          .slice(0, 2)
          .map(({ holdFile }) => holdFile)
          .join(", ");

        const andMore =
          event.customerWithFile.length > 2
            ? ` and ${event.customerWithFile.length - 2} more`
            : "";

        const titlesPlu =
          event.customerWithFile.length > 1 ? "titles" : "title";
        const areIs = event.customerWithFile.length > 1 ? "are" : "is";

        const message =
          `Hi ${firstName}, your ${titlesPlu} ${titlesMessage}${andMore} ${areIs} set aside at ${context.shopName}` as string;

        try {
          const res = await API.post("customers", "/send-message", {
            body: {
              message,
              customerId,
              sequence: "RECEIVING_MESSAGE",
              shop: event.shop,
            },
          });
          console.log("res: ", res);

          return res;
        } catch (error) {
          console.log("error: ", error);
          return "error";
        }
      },
    },
  }
);

export default smsMachine;
