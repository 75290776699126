import { PageBanner } from "../components";
import Button from "../components/buttons/Button";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import config from "../config";

const env = {
  name: "customers",
  endpoint: config.apiGateway.URL,
  region: config.apiGateway.REGION,
};

export default function DownloadHoldFiles() {
  // get shop name from url
  const shopName = window.location.pathname.split("/").pop();

  const handleFetchHoldFiles = async () => {
    const response = await fetch(
      `${env.endpoint}/public/hold-file/${shopName}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "hold-files.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <main>
      <>
        <PageBanner
          heading={`Download hold files from ${shopName}`}
          className="shadow-md"
        >
          If you're here, it's because there's a log in error.
        </PageBanner>
        <article className="px-4 sm:px-6">
          <>
            <hr className="mt-8 pb-8" />
            <Paper>
              <PaperHeader>Download your hold file here</PaperHeader>
              <Button className="m-8" onClick={handleFetchHoldFiles}>
                Download
              </Button>
            </Paper>
          </>
        </article>
      </>
    </main>
  );
}
