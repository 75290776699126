import { motion, AnimatePresence } from "framer-motion";

import useShopDetails from "../hooks/useShopDetails";
import { TCustomer } from "../types/customer";
import { THoldFile } from "../types/holdFile";
import { Notes } from "./Notes";

type SendType = {
  type: string;
};

interface SendParams extends SendType {
  id?: string;
  shop?: string;
  customer?: TCustomer;
}

interface SendType2 extends SendType {
  customer: TCustomer;
}

interface IInterestsList {
  handleEdit: (item: THoldFile) => void;
  interestsList: THoldFile[];
  loaded: boolean;
  refreshFunction: (arg?: SendType) => void;
  send: (arg: SendParams) => void;
  sendType: SendType | SendType2;
}

const InterestsList = ({
  handleEdit,
  interestsList,
  loaded,
  refreshFunction,
  send,
  sendType,
}: IInterestsList) => {
  const { shop } = useShopDetails();

  if (!loaded || !shop) {
    return (
      <article>
        <div
          data-testid="interests-loading-spinner"
          className="flex justify-center items-center mt-10"
        >
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      </article>
    );
  }

  return (
    <ul className="my-1">
      <AnimatePresence>
        {interestsList.length ? (
          interestsList.map((listItem, index) => {
            return (
              <motion.li
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ type: "tween" }}
                key={`${listItem.sk}}`}
                className={`flex px-2 ${index % 2 !== 0 ? "bg-gray-50" : ""}`}
              >
                <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
                  <div className="flex-grow flex justify-between">
                    <div className="self-center">
                      <span
                        className={`font-medium text-gray-800 hover:text-gray-900 ${
                          listItem.active ? "" : "opacity-30"
                        }`}
                      >
                        {listItem.holdFile}
                      </span>
                    </div>
                    <div className="flex-shrink-0 self-start ml-2">
                      <Notes notes={listItem.notes || ""} />
                      <button
                        className="font-medium text-indigo-400 hover:text-indigo-700 ml-4 print:hidden"
                        onClick={() => handleEdit(listItem)}
                      >
                        {listItem.notes ? "edit" : "add note"}
                      </button>
                    </div>
                  </div>
                  <div className="ml-8">
                    <button
                      className="font-medium text-red-400 hover:text-red-700 print:hidden"
                      onClick={() => {
                        send({ ...sendType, ...{ id: listItem.sk }, shop });
                        refreshFunction({ type: "refresh-totals" });
                      }}
                    >
                      delete
                    </button>
                  </div>
                </div>
              </motion.li>
            );
          })
        ) : (
          <li>
            <article>
              <div className="flex justify-center items-center mt-12 text-gray-800">
                <p className="pb-1">
                  Create a curated watch list for customers
                </p>
              </div>
            </article>
          </li>
        )}
      </AnimatePresence>
    </ul>
  );
};

export default InterestsList;
