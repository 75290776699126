import React from "react";
import Button from "../buttons/Button";

export interface ISelectableFilter {
  bundleName?: string;
  pk: string;
  selected: boolean;
  sk: string;
  tag?: string;
}
interface IFilters {
  filters: ISelectableFilter[];
  setFilters: React.Dispatch<React.SetStateAction<any[]>>;
  type: String;
}

interface IFilterButton {
  filter: ISelectableFilter;
  setFilters: React.Dispatch<React.SetStateAction<any[]>>;
}

export const Filters = ({ filters, setFilters, type }: IFilters) => {
  if (filters.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mr-4 mb-2 mb:ml-0">
        <span className="text-sm mr-2">Filter by {type}:</span>
        <Button
          variant="simple-action"
          className={`text-xs pl-0 pt-0`}
          disabled={!filters.some((filter) => filter.selected)}
          onClick={() =>
            setFilters((current) =>
              current.map((tag) => ({ ...tag, selected: false }))
            )
          }
        >
          Clear
        </Button>
      </div>
      <ul
        className="
            md:flex flex-1 items-center flex-wrap
            my-4 pb-2"
      >
        {filters.map((filter) => {
          return (
            <FilterButtons
              key={filter.sk}
              filter={filter}
              setFilters={setFilters}
            />
          );
        })}
      </ul>
    </>
  );
};

const FilterButtons = ({ filter, setFilters }: IFilterButton) => {
  const handleSelection = React.useCallback(
    (tag: ISelectableFilter) => {
      setFilters((current) => {
        const updated = current.map((current) => {
          if (current.sk === tag.sk) {
            return { ...current, selected: !current.selected };
          }
          return current;
        });
        return updated;
      });
    },
    [setFilters]
  );

  return (
    <li
      key={filter.tag || filter.bundleName}
      className="mr-2 mt-2 md:mt-0 pb-2"
    >
      <button
        className={`px-4 py-1 bg-white rounded border border-indigo-300 ${
          filter.selected ? "bg-indigo-600 text-white border-indigo-600" : ""
        }`}
        data-filter={`${filter.selected ? filter.tag : undefined}`}
        onClick={() => handleSelection(filter)}
      >
        {filter.tag || filter.bundleName}
      </button>
    </li>
  );
};
