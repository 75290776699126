import React from "react";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";

import { reshapeTitlesToCustomersWithTitle } from "../utils/reshapeTitlesToCustomersWithTitle";
import { PageBanner } from "../components";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import { useHoldFiles } from "../context/holdFilesContext";
import EmptyData from "../components/organisms/EmptyData";
import Spinner from "../components/Spinner";
import { useTitles } from "../context/titlesContext";
import Checklist from "../components/Checklist";
import { useProfileMachine } from "../context/profileContext";
import LocationVisibility from "../components/locations/LocationVisibility";
import AccordionItem from "../components/AccordionItem";
import { TTitle } from "../types/titles";

interface IActiveTitle extends TTitle {
  value: string;
  label: string;
}

type TCustomerFilePair = [
  string,
  {
    files: TTitle[];
  }
];

type ICustomerFilePairs = TCustomerFilePair[];

export default function Receiving() {
  const [viewTitlesMode, setViewTitlesMode] = React.useState(true);
  const { titles: contextTitles } = useTitles();
  const { locations } = useProfileMachine();
  const {
    holdFilesWithCustomers: titlesOnHold,
    handleSetVisibleTitles,
    handleSetViewFiles,
    checkedInTitles: titles,
    loading,
  } = useHoldFiles();

  const [hiddenLocations, setHiddenLocations] = React.useState<string[]>([]);
  const activeTitles = contextTitles?.reduce(
    (acc: IActiveTitle[], book: TTitle) => {
      if (!book.active) {
        return acc;
      }
      return [...acc, { ...book, value: book.sk, label: book.title }];
    },
    []
  );

  const preselectedTitles = activeTitles.filter((title) => {
    return titles.includes(title.value);
  });

  const customersWithTitleInfo = React.useMemo(
    // @ts-ignore
    () => reshapeTitlesToCustomersWithTitle(titlesOnHold, preselectedTitles),
    [titlesOnHold, preselectedTitles]
  );
  const [hiddenTitles, setHiddenTitles] = React.useState<string[]>([]);

  // @ts-ignore
  const customerChecklist = React.useMemo(
    () =>
      Object.entries(customersWithTitleInfo)
        .sort((a, b) => {
          return a[0] > b[0] ? 1 : -1;
        })
        .filter((customerFilePair) => {
          // @ts-ignore
          const isLocationHidden = customerFilePair[1].files.some((title) =>
            // @ts-ignore
            hiddenLocations.includes(title.storeLocation)
          );
          // @ts-ignore
          if (hiddenTitles.includes(customerFilePair[0]) || isLocationHidden)
            return null;

          return customerFilePair;
        }),
    [customersWithTitleInfo, hiddenLocations, hiddenTitles]
  ) as ICustomerFilePairs;

  const handleUpdateHoldFiles = (
    selected: TTitle[],
    { option }: { option: TTitle }
  ) => {
    const titlesToShow = selected.map((item) => {
      return item.sk;
    });

    handleSetVisibleTitles(titlesToShow);
    handleSetViewFiles(option);
  };

  const handleRemove = (customer: string) => {
    setHiddenTitles((current) => [...current, customer]);
  };

  return (
    <>
      <PageBanner heading="Check in an order">
        Select titles and create pick sheets for customers
      </PageBanner>
      <div className="print:hidden">
        <h1>Add a title</h1>
        {/* React Select needs some styles with tailwind forms */}
        <Select
          autoFocus
          styles={{
            // @ts-expect-error
            input: (base) => ({
              ...base,
              "input:focus": {
                boxShadow: "none",
              },
            }),
          }}
          defaultValue={preselectedTitles}
          options={activeTitles}
          isMulti
          // @ts-ignore
          onChange={handleUpdateHoldFiles}
        />
        <div className="flex justify-between items-center">
          {!viewTitlesMode ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
            >
              <LocationVisibility
                locations={locations}
                setHiddenLocations={setHiddenLocations}
              />
            </motion.div>
          ) : (
            <span /> // this is here to keep the flex working
          )}
          {/* <motion.div
          // animate={{ width: bounds.width }}
          >
            <button
              // ref={ref}
              className="my-6 py-2 px-4 font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75 shadow-md bg-indigo-600 text-white hover:bg-indigo-700"
              onClick={() => setViewTitlesMode(!viewTitlesMode)}
            >
              {viewTitlesMode
                ? "View as customer fulfillment"
                : "Title check-in"}
            </button>
          </motion.div> */}
          <button
            className="my-6 py-2 px-4 font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75 shadow-md bg-indigo-600 text-white hover:bg-indigo-700"
            onClick={() => setViewTitlesMode(!viewTitlesMode)}
          >
            View as {viewTitlesMode ? "customer fulfillment" : "title check-in"}
          </button>
        </div>
      </div>
      <Paper>
        {titles.length === 0 ? (
          <EmptyData headingText="Begin checking in an order">
            Each title that you select show you either that total or each create
            a checkbox for each customer. Toggle with the "View as customer
            fulfillment button"
          </EmptyData>
        ) : (
          <>
            <AnimatePresence>
              <motion.div
                animate={{
                  height: "auto",
                }}
                className="p-3 print:flex print:border-0"
              >
                <PaperHeader action={() => window.print()} actionText="Print">
                  {viewTitlesMode ? "Titles" : "Customers"}
                </PaperHeader>
                {viewTitlesMode && (
                  <header className="flex justify-between text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2 print:hidden">
                    <span>title</span>
                    <span>count</span>
                  </header>
                )}
                {loading ? (
                  <Spinner size="64px" />
                ) : (
                  <ul className="my-1 print:flex-1 relative">
                    {viewTitlesMode && (
                      <motion.div
                        key={"titles-mode"}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{ opacity: 0 }}
                      >
                        {titles.map((title) => {
                          const tally = titlesOnHold[title]?.length;

                          if (!tally)
                            return (
                              <React.Fragment key={title}></React.Fragment>
                            );

                          const seriesTitle =
                            titlesOnHold[title].at(0)!.holdFile;
                          const countsByShop = titlesOnHold[title]?.reduce<
                            Record<string, number>
                          >((acc, customer) => {
                            if (acc[customer.storeLocation]) {
                              acc[customer.storeLocation] += 1;
                            } else {
                              acc[customer.storeLocation] = 1;
                            }
                            return acc;
                          }, {});

                          const details = titlesOnHold[title].map((file) => {
                            return {
                              active: file.active,
                              key: file.sk,
                              label: file.notes || "",
                              linkTarget: `/customers/${file.customerId}`,
                              linkValue: `${file.lastName}, ${file.firstName}`,
                              notes: file.notes || "",
                            };
                          });

                          return (
                            <AccordionItem
                              actionLink={`/titles/${title.replace(
                                "TITLE#",
                                ""
                              )}`}
                              key={title}
                              title={seriesTitle}
                              count={tally}
                              countsByGroup={countsByShop}
                              details={details}
                            />
                          );
                        })}
                      </motion.div>
                    )}
                    {!viewTitlesMode && (
                      <motion.div
                        key={"customer-mode"}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{ opacity: 0 }}
                      >
                        {customerChecklist.map(([key, value]) => {
                          return (
                            <Checklist
                              key={key}
                              // @ts-ignore
                              list={value}
                              listTitle={key}
                              customerName={key}
                              handleRemove={handleRemove}
                            />
                          );
                        })}
                      </motion.div>
                    )}
                  </ul>
                )}
              </motion.div>
            </AnimatePresence>
          </>
        )}
      </Paper>
    </>
  );
}
