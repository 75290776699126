import React from "react";
import { API } from "aws-amplify";
import { createMachine, assign, InterpreterFrom } from "xstate";
import { useActor, useInterpret } from "@xstate/react";
import { TCustomer } from "../types/customer";
import { THoldFile } from "../types/holdFile";
import { TTitle } from "../types/titles";
import { TBundle } from "../types/bundle";
import { toast } from "react-toastify";

const deleteHoldFile = async (
  compoundId: string,
  holdFile: THoldFile | undefined,
  shop: string
) => {
  const args = { compoundId, holdFile, shop };
  try {
    const res = await API.del("customers", "/hold-files", {
      body: args,
    });
    if (res.error) {
      throw res.error;
    }

    return true;
  } catch (error) {
    toast.error("There was an error deleting this hold file.");
    return { error, ...args };
  }
};

interface IShopTitle extends TTitle {
  shop: string;
}

type Context = {
  new: {
    holdFile: THoldFile | Partial<THoldFile>;
    notes: string;
  };
  customersHoldFiles: { [key: string]: THoldFile[] };
  customersWithHoldFile: THoldFile[];
  holdFilesWithCustomers: {
    [key: string]: THoldFile[];
  };
  checkedInTitles: string[];
};

type Events =
  | {
      type: "view-customer";
      shop: string;
      customer: TCustomer;
    }
  | { type: "hide" }
  | {
      type: "view-files";
      currentTitle: IShopTitle;
    }
  | {
      type: "set-visible-titles";
      titlesToShow: string[];
    }
  | {
      type: "edit";
      holdFile: THoldFile;
    }
  | { type: "edit-hold-file"; item: THoldFile; note: string }
  | {
      type: "change_form_value";
      value: { holdFile?: TTitle; notes?: string };
    }
  | {
      type: "submit";
      customer: TCustomer;
      shop: string;
    }
  | {
      type: "submit-bundle";
      bundle: TBundle;
      customerId: number;
      shop: string;
    }
  | {
      type: "submit-and-close";
      customer: TCustomer;
      shop: string;
    }
  | { type: "set-visible-titles"; titlesToShow: string[] }
  | { type: "delete"; customer: TCustomer; shop: string; id: string }
  | { type: "filter-hold-files"; title: string };

type Services = {
  editHoldFile: {
    data: boolean;
  };
  retrieveCustomersWithHoldFile: {
    data: THoldFile[];
  };
  reLoadCustomerHoldFiles: {
    data: { [key: string]: THoldFile[] };
  };
  loadCustomerHoldFiles: {
    data: { [key: string]: THoldFile[] };
  };
  submitNewHoldFile: {
    data: { holdFile: THoldFile };
  };
  submitHoldFileBundle: {
    data: { bundle: Partial<TBundle>; customerId: number; shop: string };
  };
};

export const holdFilesMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "holdFilesMachine",
    initial: "pending",
    tsTypes: {} as import("./holdFilesContext.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      new: {
        holdFile: {},
        notes: "",
      },
      customersHoldFiles: {},
      customersWithHoldFile: [],
      holdFilesWithCustomers: {},
      checkedInTitles: [],
    },
    states: {
      pending: {
        on: {
          "view-files": {
            target: "loadingCustomersWithFile",
          },
          "set-visible-titles": {
            target: "loaded",
            actions: ["setVisibleTitles"],
          },
          "view-customer": [
            {
              cond: "areCustomerHoldFilesLoaded",
              target: "loading",
              actions: ["insertCustomerIntoContext"],
            },
            { target: "loaded" },
          ],
        },
      },
      loading: {
        invoke: {
          src: "loadCustomerHoldFiles",
          onDone: {
            target: "loaded",
            actions: "setLoadCustomerHoldFiles",
          },
          onError: {
            target: "loaded",
          },
        },
      },
      loadingCustomersWithFile: {
        invoke: {
          src: "retrieveCustomersWithHoldFile",
          onDone: {
            target: "loaded",
            actions: ["setCustomersWithHoldFile"],
          },
          onError: {
            target: "loaded",
          },
        },
      },
      loaded: {
        on: {
          "view-customer": [
            {
              cond: "areCustomerHoldFilesLoaded",
              target: "loading",
              actions: ["insertCustomerIntoContext"],
            },
            { target: "loaded" },
          ],
          "view-files": {
            target: "loadingCustomersWithFile",
          },
          edit: {
            target: "editing",
          },
          "set-visible-titles": {
            target: "loaded",
            actions: ["setVisibleTitles"],
          },
          "edit-hold-file": {
            target: "submitting-edits",
          },
          delete: {
            target: "loaded",
            actions: ["removeHoldFileItem"],
          },
          "filter-hold-files": {
            target: "loaded",
            actions: ["filterHoldFiles"],
          },
        },
      },
      "submitting-edits": {
        invoke: {
          src: "editHoldFile",
          onDone: {
            target: "refreshing",
          },
          onError: "loaded",
        },
        exit: ["clearNewHoldFiles"],
      },
      editing: {
        on: {
          change_form_value: {
            target: "editing",
            actions: "onInputChange",
          },
          hide: "loaded",
          submit: [
            {
              target: "submitting",
              cond: "isFormValid",
            },
            {
              target: "error",
            },
          ],
          "submit-and-close": [
            {
              target: "submitting_and_closing",
              cond: "isFormValid",
            },
            {
              target: "error",
            },
          ],
          "submit-bundle": {
            target: "bundling",
          },
        },
      },
      error: {
        after: {
          10: {
            target: "editing",
          },
        },
      },
      bundling: {
        invoke: {
          src: "submitHoldFileBundle",
          onDone: {
            target: "refreshing",
          },
          onError: {
            target: "loaded",
            actions: ["showError"],
          },
        },
        exit: ["clearNewHoldFiles"],
      },
      submitting: {
        invoke: {
          src: "submitNewHoldFile",
          onDone: {
            target: "editing",
            actions: ["addHoldFileToWatchList"],
          },
          onError: "loaded",
        },
        exit: ["clearNewHoldFiles"],
      },
      submitting_and_closing: {
        invoke: {
          src: "submitNewHoldFile",
          onDone: {
            target: "loaded",
            actions: ["addHoldFileToWatchList"],
          },
          onError: "loaded",
        },
        exit: ["clearNewHoldFiles"],
      },
      refreshing: {
        after: {
          5000: {
            target: "reloading",
          },
        },
      },
      reloading: {
        invoke: {
          src: "reLoadCustomerHoldFiles",
          onDone: {
            target: "loaded",
            actions: "setLoadCustomerHoldFiles",
          },
          onError: {
            target: "loaded",
          },
        },
      },
    },
  },
  {
    services: {
      editHoldFile: async (_, event) => {
        await API.put("customers", "/hold-files", {
          body: event,
        });
        return true;
      },
      reLoadCustomerHoldFiles: async (context) => {
        const compoundId = Object.keys(context.customersHoldFiles)[0];
        const customersList = {
          ...context.customersHoldFiles,
        };

        const { holdFiles } = await API.get(
          "customers",
          `/hold-files?id=${encodeURIComponent(compoundId)}`,
          {}
        );
        customersList[compoundId] = holdFiles;

        return { ...customersList };
      },
      submitHoldFileBundle: async (_, event) => {
        const res = await API.post("customers", "/hold-files/bundle", {
          body: {
            shop: event.shop,
            bundle: event.bundle,
            customerId: event.customerId,
          },
        });

        if (res.status === 400) {
          throw new Error("Error in bundle submission");
        }

        return event;
      },
      submitNewHoldFile: async ({ new: values }, event) => {
        const compoundId = `HOLDFILE#SHOP#${event.shop}${event.customer.sk}`;

        try {
          return await API.post("customers", "/hold-files", {
            body: {
              ...values,
              compoundId,
              shop: `SHOP#${event.shop}`,
              customerId: event.customer.customerId,
              firstName: event.customer.firstName,
              lastName: event.customer.lastName,
              storeLocation: event.customer.storeLocation,
            },
          });
        } catch (error) {
          return { error, ...values, compoundId };
        }
      },
      loadCustomerHoldFiles: async (context, event) => {
        const compoundId = `HOLDFILE#SHOP#${event.shop}${event.customer.sk}`;
        const customersList = {
          ...context.customersHoldFiles,
        };

        const { holdFiles } = await API.get(
          "customers",
          `/hold-files?id=${encodeURIComponent(compoundId)}`,
          {}
        );
        customersList[compoundId] = holdFiles;

        return { ...customersList };
      },
      retrieveCustomersWithHoldFile: async (_, { currentTitle }) => {
        const { data } = await API.get("customers", "/hold-files/title", {
          queryStringParameters: {
            shop: currentTitle.shop,
            title: currentTitle.sk.replace("TITLE#", ""),
          },
        });
        return data;
      },
    },
    guards: {
      isFormValid: ({ new: values }) => {
        return Boolean(values.holdFile) && Boolean(values.holdFile?.publisher);
      },
      areCustomerHoldFilesLoaded: (
        context,
        { customer }: { customer: TCustomer }
      ) => {
        if (!!context.customersHoldFiles[`HOLDFILE#${customer.sk}`]) {
          return false;
        }

        return true;
      },
    },
    actions: {
      showError: (context) => {
        toast.error(
          "A title in the bundle no longer exists. Please message Devin @ fitzsimonsdevin@gmail.com"
        );
        return context;
      },
      filterHoldFiles: assign({
        holdFilesWithCustomers: (context, { title }) => {
          const filteredHoldFiles = Object.fromEntries(
            Object.entries(context.holdFilesWithCustomers).filter(
              ([seriesTitle]) => {
                return seriesTitle !== title;
              }
            )
          );
          return filteredHoldFiles;
        },
      }),
      addHoldFileToWatchList: assign({
        customersHoldFiles: (context, event) => {
          const newHoldFile = event.data.holdFile;
          const targetCustomer = newHoldFile?.pk;
          const customerHoldFiles = context?.customersHoldFiles[targetCustomer];

          return {
            ...context.customersHoldFiles,
            [targetCustomer]: [...customerHoldFiles, newHoldFile],
          };
        },
      }),
      onInputChange: assign({
        //@ts-ignore
        new: (context, event) => {
          return {
            ...context.new,
            ...event.value,
          };
        },
      }),
      setLoadCustomerHoldFiles: assign({
        customersHoldFiles: (_, event) => {
          return event.data;
        },
      }),
      setCustomersWithHoldFile: assign({
        customersWithHoldFile: (_, event) => {
          return event.data || [];
        },
        holdFilesWithCustomers: (context, event) => {
          const title = event.data[0]?.sk.split("INSTANCE#")[0];
          if (title) {
            return {
              ...context.holdFilesWithCustomers,
              [title]: event.data,
            };
          }
          return context.holdFilesWithCustomers;
        },
      }),
      setVisibleTitles: assign({
        checkedInTitles: (_, event) => {
          return event.titlesToShow?.sort((a, b) => (a > b ? 1 : -1));
        },
      }),
      insertCustomerIntoContext: assign({
        customersHoldFiles: (context, event) => {
          const { sk } = event.customer;

          const compoundId = `HOLDFILE#SHOP#${event.shop}${sk}`;

          if (!context.customersHoldFiles[compoundId]) {
            return {
              ...context.customersHoldFiles,
              [compoundId]: [],
            };
          }

          return { ...context.customersHoldFiles };
        },
      }),
      removeHoldFileItem: assign({
        customersHoldFiles: ({ customersHoldFiles }, event) => {
          const compoundId = `HOLDFILE#SHOP#${event.shop}${event.customer.sk}`;
          const targetCustomersList = customersHoldFiles[compoundId];
          const fileToDelete = targetCustomersList.find(
            (series) => series.sk === event.id
          );

          deleteHoldFile(compoundId, fileToDelete, event.shop);
          const customersNewHoldFiles = targetCustomersList.filter(
            (holdFile) => {
              return holdFile.sk !== fileToDelete?.sk;
            }
          );

          return {
            ...customersHoldFiles,
            [compoundId]: customersNewHoldFiles,
          };
        },
      }),

      clearNewHoldFiles: assign({
        new: (_) => {
          return {
            notes: "",
            holdFile: {},
          };
        },
      }),
    },
  }
);

export const eventMap = {
  "filter-hold-files": "filter-hold-files",
  "view-customer": "view-customer",
  "view-files": "view-files",
  change_form_value: "change_form_value",
  "set-visible-titles": "set-visible-titles",
  delete: "delete",
  edit: "edit",
  hide: "hide",
  submit: "submit",
  submit_and_close: "submit-and-close",
  "submit-bundle": "submit-bundle",
};

export const stateMap = {
  editing: "editing",
  loaded: "loaded",
  refreshing: "refreshing",
  loading: "loading",
  loadingCustomersWithFile: "loadingCustomersWithFile",
  pending: "pending",
  submitting: "submitting",
  submitting_and_closing: "submitting_and_closing",
  bundling: "bundling",
};

export const HoldFilesContext = React.createContext({
  holdFilesService: {} as InterpreterFrom<typeof holdFilesMachine>,
});

export const HoldFilesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  //@ts-ignore
  const holdFilesService = useInterpret(holdFilesMachine);

  return (
    <HoldFilesContext.Provider value={{ holdFilesService }}>
      {children}
    </HoldFilesContext.Provider>
  );
};

export const useHoldFiles = () => {
  const { holdFilesService } = React.useContext(HoldFilesContext);
  const [state, send] = useActor(holdFilesService);

  const holdFilesWithCustomers = state.context.holdFilesWithCustomers;

  const loaded = state.matches("loaded");
  const loading = state.matches("loadingCustomersWithFile");
  const editing = state.matches("editing");
  const submitting = state.matches("submitting");
  const error = state.matches("error");

  React.useEffect(() => {
    if (error) {
      toast.error("There was an error submitting this hold file.");
    }
  }, [error]);

  const handleHideHoldFiles = () => send("hide");
  const handleSetVisibleTitles = (titlesToShow: string[]) =>
    send({
      type: "set-visible-titles",
      titlesToShow,
    });
  const handleSetViewFiles = (option: TTitle) => {
    send({
      type: "view-files",
      ...{ currentTitle: option },
    });
  };
  const {
    customersHoldFiles,
    customersWithHoldFile,
    checkedInTitles,
    new: newHoldFile,
  } = state.context;

  return {
    checkedInTitles,
    customersHoldFiles,
    customersWithHoldFile,
    editing,
    handleHideHoldFiles,
    handleSetViewFiles,
    handleSetVisibleTitles,
    holdFilesWithCustomers,
    loaded,
    loading,
    newHoldFile,
    send,
    submitting,
  };
};
