import React, { createContext } from "react";
import { API } from "aws-amplify";
import { createMachine, assign, StateFrom, InterpreterFrom } from "xstate";
import { useActor, useInterpret, useSelector } from "@xstate/react";
import isDev from "../utils/isDev";
import renderPhone from "../utils/renderPhone";
import { TCustomer } from "../types/customer";

export const eventMap = {
  delete_customer_by_id: "delete_customer_by_id",
};

type Services = {
  getCustomers: {
    data: TCustomer[];
  };
};
type Context = {
  shop?: string;
  customers: TCustomer[];
};
type Events =
  | {
      type: "delete_customer_by_id";
      customer: TCustomer;
    }
  | {
      type: "fetchCustomers";
      shop: string;
    }
  | {
      type: "customerAddSuccess";
      customer: TCustomer;
    }
  | {
      type: "customerEditSuccess";
      customer: TCustomer;
    };

export const customersMachine = createMachine(
  {
    id: "customersMachine",
    initial: "idle",
    context: {
      shop: undefined,
      customers: [],
    },
    schema: {
      services: {} as Services,
      context: {} as Context,
      events: {} as Events,
    },
    tsTypes: {} as import("./customersContext.typegen").Typegen0,
    states: {
      idle: {
        on: {
          delete_customer_by_id: {
            actions: ["removeCustomerFromCustomers"],
          },
          fetchCustomers: {
            target: "loading",
            actions: ["updateShop"],
          },
          // This is sent from an actor
          customerAddSuccess: {
            target: ["idle"],
            actions: "addCustomerToCustomers",
          },
          customerEditSuccess: {
            target: ["idle"],
            actions: "updateCustomerInCustomers",
          },
        },
      },
      loading: {
        invoke: {
          src: "getCustomers",
          onDone: {
            target: "loaded",
            actions: "loadCustomersIntoContext",
          },
          onError: {
            target: "error",
          },
        },
      },
      loaded: {
        always: ["idle"],
      },
      error: {
        always: ["idle"],
      },
    },
  },
  {
    actions: {
      removeCustomerFromCustomers: assign({
        customers: ({ customers }, { customer }) => {
          return customers.filter((c) => c.sk !== customer.sk);
        },
      }),
      updateShop: assign({
        shop: (_, event) => event.shop,
      }),
      loadCustomersIntoContext: assign({
        customers: (_, event) => {
          if (event.data.length === 0) {
            return [];
          }

          return event.data.map((customer) => {
            return {
              ...customer,
              phone: renderPhone(customer.phone),
            };
          });
        },
      }),
      addCustomerToCustomers: assign({
        /*
         * the value is { type: customerAddSuccess, customer: ... }
         */
        customers: (context, event) => {
          return [event.customer, ...context.customers];
        },
      }),
      updateCustomerInCustomers: assign({
        /*
         * the value is { type: customerAddSuccess, customer: ... }
         */
        customers: (context, event) => {
          const updateArrayBy = (
            id: "customerId",
            arr: TCustomer[],
            obj: TCustomer
          ): TCustomer[] =>
            arr.map((t) => {
              return t[id] === obj[id] ? obj : t;
            });
          return updateArrayBy("customerId", context.customers, event.customer);
        },
      }),
    },
    services: {
      getCustomers: async (context) => {
        const shop = context.shop as string;

        const { customers } = await API.get(
          "customers",
          `/customers?shop=${shop}`,
          {}
        );
        return customers;
      },
    },
  }
);

export const selectCustomers = (state: StateFrom<typeof customersMachine>) => {
  return state.context.customers;
};

export const useCustomers = () => {
  const { customersService } = React.useContext(CustomersContext);
  const [state, send] = useActor(customersService);
  const loading = state.matches("loading");
  const customers = useSelector(customersService, selectCustomers);

  const handleCustomerAddSuccess = (customer: TCustomer) => {
    send({
      type: "customerAddSuccess",
      customer,
    });
  };

  const handleCustomerEditSuccess = (customer: TCustomer) => {
    send({
      type: "customerEditSuccess",
      customer,
    });
  };

  return {
    customers,
    handleCustomerEditSuccess,
    handleCustomerAddSuccess,
    loading,
  };
};

export const CustomersContext = createContext({
  customersService: {} as InterpreterFrom<typeof customersMachine>,
});

export const CustomersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const customersService = useInterpret(customersMachine, {
    devTools: isDev(),
  });

  return (
    <CustomersContext.Provider value={{ customersService }}>
      {children}
    </CustomersContext.Provider>
  );
};
