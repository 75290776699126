import { Link } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import { useCustomers } from "../context/customersContext";
import { IMessage } from "../types/messages";
import Button from "./buttons/Button";
import { Modal } from "./Modal";
import ModalHeader from "./ModalHeader";
import Spinner from "./Spinner";
import { useMessages } from "../hooks/messages/useMessages";

export const NoMessages = () => {
  const { value, setTrue, setFalse } = useBoolean(false);
  return (
    <div className="px-2">
      <p className="mb-4">You have no new messages.</p>
      <Button variant="informational" onClick={setTrue}>
        Learn more about messaging
      </Button>
      <Modal isOpen={value} onClose={setFalse}>
        <ModalHeader>How to message customers</ModalHeader>
        <div>
          <p className="prose-p">
            You can create messages on the{" "}
            <Link to="/customers" className="text-blue-800 underline">
              customers
            </Link>{" "}
            page. Select a customer with the check box and choose Bulk Message.
            Customers will receive your message by SMS and be taken to a special
            page where they can reply to you.
          </p>
        </div>
      </Modal>
    </div>
  );
};

interface IMessagesProps {
  messages: IMessage[];
}

export const Messages = ({ messages }: IMessagesProps) => {
  const { customers, loading } = useCustomers();
  const { handleResolveMessage } = useMessages();

  return (
    <div className="px-2">
      {loading ? (
        <Spinner size="36px" />
      ) : (
        messages.map((message) => {
          // @ts-ignore
          const { reply } = message;
          const customer = customers.find(
            (customer) => customer.customerId === message.customerId
          );
          return (
            <div
              key={message.pk}
              className="border flex items-center justify-between p-2 mb-2"
            >
              <p>
                <Link
                  to={`/customers/${customer?.customerId}`}
                  className="underline underline-offset-1 decoration-purple-500 font-medium text-gray-800 hover:text-gray-900 print:no-underline"
                >
                  {customer?.lastName}, {customer?.firstName}
                </Link>{" "}
                <span className="italic">{reply}</span>
              </p>
              <Button
                variant="informational"
                onClick={() => {
                  handleResolveMessage(message);
                }}
              >
                Mark resolved
              </Button>
            </div>
          );
        })
      )}
    </div>
  );
};
