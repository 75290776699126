import React from "react";

import useShopDetails from "../hooks/useShopDetails";

export const AuthContext = React.createContext({
  auth: null,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useShopDetails();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
