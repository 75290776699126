import { Outlet } from "react-router-dom";
import { StateFrom } from "xstate";

import { PageBanner } from "../components";
import { Events, titlesMachine, useTitles } from "../context/titlesContext";

export type TitlesOutletContext = {
  handleNo: () => void;
  status: string;
  state: StateFrom<typeof titlesMachine>;
  send: (event: Events) => void;
};

export default function Titles() {
  const { status, state, send, handleNo } = useTitles();

  return (
    <>
      <PageBanner heading="Titles">
        You can manage the details of your titles here
      </PageBanner>
      <Outlet context={{ send, state, status, handleNo }} />
    </>
  );
}
