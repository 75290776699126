import { NavLink } from "react-router-dom";

interface ActionLinkProps {
  children: React.ReactNode;
  isSelected: boolean;
  linkTarget: string;
  title: string;
}

const ActionLink = ({
  children,
  isSelected,
  linkTarget,
  title,
}: ActionLinkProps) => {
  return (
    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0`}>
      <span
        className={`block text-gray-200 hover:text-white transition duration-150 ${
          isSelected && "hover:text-gray-200"
        }`}
      >
        <div className="flex flex-grow">
          {children}
          <NavLink
            to={linkTarget}
            className={({ isActive }) =>
              `block text-gray-200 hover:text-white transition duration-150 border-b-2 border-transparent ${
                isActive && "border-gray-200"
              }`
            }
          >
            {title}
          </NavLink>
        </div>
      </span>
    </li>
  );
};

export default ActionLink;
