import React from "react";
import useKeypress from "../hooks/useKeypress";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: Boolean;
};

/**
 * A modal that takes up a small portion of the screen
 * best for information, expects an XState machine type
 * for the close action
 *
 * @param {Props} { children, send, isOpen, closeEvent }
 * @return {*}
 */

const Modal: React.FC<Props> = ({ children, isOpen, onClose }) => {
  useKeypress("Escape", () => {
    onClose();
  });

  return (
    <>
      {isOpen ? (
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          id="modal"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            if (event.currentTarget.id === "modal") {
              onClose();
            }
          }}
        >
          <div
            // this is here because sometimes TS is really dumb
            onClick={(e) => e.stopPropagation()}
            className="relative top-20 mx-auto pb-10 pt-5 px-5 border w-11/12 sm:w-1/2 shadow-lg rounded-md bg-white"
          >
            <div className="flex justify-end text-gray-500">
              <button
                onClick={() => {
                  onClose();
                }}
              >
                <svg
                  id="close-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    id="close-icon"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-3">{children}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { Modal };
