import Fuse from "fuse.js";
import { assign, createMachine } from "xstate";

export type Tags = "Customers" | "Titles" | "Account" | "Orders";
export interface PaletteOption {
  value: string;
  label: string;
  tag: Tags;
  action?: () => void;
  visible: boolean;
}

type Context = {
  commands: PaletteOption[];
  defaultCommands: PaletteOption[];
};

type Events =
  | { type: "close" }
  | { type: "open" }
  | { type: "reset" }
  | { type: "input"; value: string };

const initialState = {
  commandPaletteService: {},
  matchString: "",
  defaultCommands: [],
  commands: [],
};

export const commandPaletteMachine = createMachine(
  {
    tsTypes: {} as import("./commandPaletteMachine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    id: "command-palette",
    context: initialState,
    initial: "closed",
    states: {
      closed: {
        on: {
          open: {
            target: "open",
          },
        },
      },
      open: {
        on: {
          close: {
            target: "closed",
            actions: ["clear"],
          },
          reset: {
            actions: ["clear"],
          },
          input: {
            actions: ["evaluateString"],
          },
        },
      },
    },
  },
  {
    actions: {
      clear: assign({
        commands: (context) => {
          return context.defaultCommands;
        },
      }),
      evaluateString: assign({
        commands: (context, event) => {
          if (event.value?.length < 2) {
            return context.commands;
          }
          const fuse = new Fuse(context.commands, {
            keys: ["label", "value", "tag"],
          });

          const results = fuse.search(event.value);
          const res = results.map((item) => {
            return { ...item.item, visible: true };
          });

          return res;
        },
      }),
    },
  }
);
