import React from "react";
import { useActor } from "@xstate/react";
import { useLocation } from "react-router-dom";

import ActionLink from "./ActionLink";
import CampaignsIcon from "../icons/CampaignsIcon";
import CustomersIcon from "../icons/CustomersIcon";
import DashboardIcon from "../icons/DashboardIcon";
import OrdersIcon from "../icons/OrdersIcon";
import PreordersIcon from "../icons/PreordersIcon";
import ReceivingIcon from "../icons/ReceivingIcon";
import RewardsIcon from "../icons/RewardsIcon";
import TitlesIcon from "../icons/TitlesIcon";
import { ProfileContext } from "../../context/profileContext";
import { GlobalServicesContext } from "../../context/globalServicesContext";
import CommandPaletteButton from "../buttons/CommandPaletteButton";

interface ISidebar {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (arg: (current: boolean) => boolean) => void;
}

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }: ISidebar) => {
  const { commandPaletteService } = React.useContext(GlobalServicesContext);
  const [, send] = useActor(commandPaletteService);
  const location = useLocation();
  const { profileService } = React.useContext(ProfileContext);
  const [profileState] = useActor(profileService);
  const hasLocations = Boolean(profileState.context.profile?.locations);

  const page = location.pathname.split("/")[1];

  return (
    <div className="lg:w-64 relative">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          isSidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setIsSidebarOpen((current) => !current)}
            aria-controls="sidebar"
            aria-expanded={isSidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <span className="block">
            <svg width="32" height="32" viewBox="0 0 32 32">
              <defs>
                <linearGradient
                  x1="28.538%"
                  y1="20.229%"
                  x2="100%"
                  y2="108.156%"
                  id="logo-a"
                >
                  <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                  <stop stopColor="#A5B4FC" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="88.638%"
                  y1="29.267%"
                  x2="22.42%"
                  y2="100%"
                  id="logo-b"
                >
                  <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                  <stop stopColor="#38BDF8" offset="100%" />
                </linearGradient>
              </defs>
              <rect fill="#6366F1" width="32" height="32" rx="16" />
              <path
                d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                fill="#4F46E5"
              />
              <path
                d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                fill="url(#logo-a)"
              />
              <path
                d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                fill="url(#logo-b)"
              />
            </svg>
          </span>
        </div>

        {/* Links */}
        <div>
          <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
            Pages
          </h3>
          <ul className="mt-3">
            {/* Dashboard */}
            <ActionLink
              title="Dashboard"
              linkTarget="/"
              isSelected={page === ""}
            >
              <DashboardIcon isSelected={page === ""} />
            </ActionLink>
            {hasLocations && (
              <>
                {/* Customers */}
                <ActionLink
                  title="Customers"
                  linkTarget="customers"
                  isSelected={page === "customers"}
                >
                  <CustomersIcon isSelected={page === "customers"} />
                </ActionLink>
                {/* Titles */}
                <ActionLink
                  isSelected={page === "titles"}
                  linkTarget="titles"
                  title="Titles"
                >
                  <TitlesIcon isSelected={page === "titles"} />
                </ActionLink>
                {/* Receiving */}
                <ActionLink
                  isSelected={page === "receiving"}
                  linkTarget="receiving"
                  title="Receiving"
                >
                  <ReceivingIcon isSelected={page === "receiving"} />
                </ActionLink>
                {/* Orders */}
                <ActionLink
                  isSelected={page === "orders"}
                  linkTarget="orders"
                  title="Orders"
                >
                  <OrdersIcon isSelected={page === "orders"} />
                </ActionLink>
                <ActionLink
                  title="Rewards"
                  linkTarget="rewards"
                  isSelected={page === "rewards"}
                >
                  <RewardsIcon isSelected={page === "rewards"} />
                </ActionLink>
                {/* Campaigns */}
                <ActionLink
                  isSelected={page === "campaigns"}
                  linkTarget="campaigns"
                  title="Campaigns"
                >
                  <CampaignsIcon isSelected={page === "campaigns"} />
                </ActionLink>
                {/* Upcoming books */}
                <ActionLink
                  isSelected={page === "preorders"}
                  linkTarget="preorders"
                  title="Release Pre-orders"
                >
                  <PreordersIcon isSelected={page === "preorders"} />
                </ActionLink>
              </>
            )}
          </ul>
        </div>
      </div>
      <CommandPaletteButton send={send} />
    </div>
  );
};

export default Sidebar;
