import React from "react";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import config from "./config";
import "./index.css";

import App from "./App";
import Campaigns from "./routes/campaigns";
import Bundle from "./routes/bundle";
import Bundles from "./routes/bundles";
import Customer from "./routes/customer";
import Customers from "./routes/customers";
import Dashboard from "./routes/dashboard";
import DownloadHoldFiles from "./routes/download-hold-files";
import Orders from "./routes/orders";
import Plans from "./routes/plans";
import Preorders from "./routes/preorders";
import Profile from "./routes/profile";
import Receiving from "./routes/receiving";
import Rewards from "./routes/rewards";
import Title from "./routes/title";
import Tags from "./routes/tags";
import Titles from "./routes/titles";

import { BundlesPage, CustomersPage, TitlesPage } from "./pages";

import { initSentry } from "./utils/errorLib";

import { createRoot } from "react-dom/client";
import Public from "./routes/public";

initSentry();
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "customers",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
  },
});
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <div>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/p/:id" element={<Public />} />
          <Route
            path="/download-hold-files/:id"
            element={<DownloadHoldFiles />}
          />
          <Route path="/" element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path="customers" element={<Customers />}>
              <Route index element={<CustomersPage />} />
              <Route path=":customerId" element={<Customer />} />
            </Route>
            <Route path="titles" element={<Titles />}>
              <Route index element={<TitlesPage />} />
              <Route path=":titleId" element={<Title />} />
            </Route>
            <Route path="bundles" element={<Bundles />}>
              <Route index element={<BundlesPage />} />
              <Route path=":bundleId" element={<Bundle />} />
            </Route>
            <Route path="receiving" element={<Receiving />} />
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="orders" element={<Orders />} />
            <Route path="plans" element={<Plans />} />
            <Route path="preorders" element={<Preorders />} />
            <Route path="profile" element={<Profile />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="tags" element={<Tags />} />
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </div>
);
