type Props = {
  children: React.ReactNode;
  className?: string;
};

const Paper: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={`col-span-full bg-white shadow-lg rounded-sm border border-gray-200 ${className} print:border-none print:shadow-none`}
    >
      {children}
    </div>
  );
};

export default Paper;
