import Button from "../buttons/Button";

type Props = {
  action?: () => void;
  actionText?: string;
  children: React.ReactNode;
  headingText?: string;
};

const EmptyData: React.FC<Props> = ({
  action,
  actionText,
  children,
  headingText,
}) => {
  return (
    <div className="w-full lg:max-w-full lg:flex my-6 px-2">
      <div
        className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
        style={{
          backgroundImage:
            "url('https://cdn.pixabay.com/photo/2019/03/01/18/32/night-4028339_960_720.jpg'",
        }}
        title="Mountain"
      ></div>
      <div className="border-r border-b border-l border-gray-100 lg:border-l-0 lg:border-t lg:border-gray-100 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="mb-8">
          {headingText && (
            <h3 className="text-gray-900 font-bold text-xl mb-2">
              {headingText}
            </h3>
          )}
          <p className="text-gray-700 text-base">{children}</p>
          <div className="text-right mb-2 mt-6">
            {action && <Button onClick={action}>{actionText}</Button>}
          </div>
        </div>
        <div className="flex items-center ml-auto">
          <img
            className="w-10 h-10 rounded-full mr-4"
            src="https://pbs.twimg.com/media/CQq_6AvU8AA-EGq?format=jpg&name=small"
            alt="Avatar"
          />
          <div className="text-sm">
            <p className="text-gray-900 leading-none">Issues? Just email me</p>
            <p className="text-gray-500">
              <a href="mailto: fitzsimonsdevin@gmail.com">
                fitzsimonsdevin@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyData;
