import React from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  Link,
} from "react-router-dom";

import Spinner from "../components/Spinner";
import Paper from "../components/paper/Paper";
import PaperHeader from "../components/paper/PaperHeader";
import InputModal from "../components/InputModal";
import { CustomersWithTitle } from "../components/CustomersWithTitle";
import useKeypress from "../hooks/useKeypress";
import Button from "../components/buttons/Button";
import { useTotals } from "../context/totalsContext";
import { TitlesOutletContext } from "./titles";
import ReactSelect from "react-select";
import { useCustomers } from "../context/customersContext";
import { useMutation } from "@tanstack/react-query";
import { TTitle } from "../types/titles";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { Bar } from "react-chartjs-2";
import { COLORS, useMovement } from "./dashboard";
import { distributors } from "../types/distributors";

const pickAColor = (min = 1, max = 3) => {
  return COLORS[Math.floor(Math.random() * (max - min + 1) + min)];
};

export const getTitleMovement = async (shop: string, title: string) => {
  const movement = await API.get(
    "customers",
    `/movement?shop=${shop}&title=${title}&window=42`,
    {}
  );
  const { titleSk: series, ...rest } = movement.titleHistory;
  return { [series]: Object.values(rest).reverse() };
};

const initialValues = {
  title: "",
  publisher: "",
  notes: "",
  active: true,
  distributors: [],
};

interface IFormData {
  customers: FormDataEntryValue[];
  title: TTitle;
}

export default function Title() {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (formData: IFormData) => {
      return API.post("customers", `/hold-files/bulk`, {
        body: formData,
      });
    },
    onSuccess: (res) => {
      toast.success(`${res.updatedCount} hold files created`);
      navigate("/titles");
    },
  });

  const { customers } = useCustomers();
  const [isEditing, setIsEditing] = React.useState(false);
  const [formValues, setFormValues] = React.useState(initialValues);
  const {
    state: titlesState,
    send: titlesSend,
    handleNo,
  } = useOutletContext<TitlesOutletContext>();
  const { handleRefreshTotals } = useTotals();

  const { pathname } = useLocation();
  const currentTitle = React.useMemo(() => {
    return titlesState.context.titles.find(
      (title) => title.sk === `TITLE#${pathname.replace("/titles/", "")}`
    )!;
  }, [titlesState, pathname]);
  const m = useMovement(pathname.replace("/titles/", ""));
  const currentTitlesGraph =
    // @ts-ignore
    m[`TITLE#${pathname.replace("/titles/", "")}`] || [];

  const isActive = currentTitle?.active;
  React.useEffect(() => {
    setFormValues({
      title: currentTitle?.title || "",
      publisher: currentTitle?.publisher || "",
      notes: currentTitle?.notes || "",
      active: true,
      // @ts-ignore
      distributors: currentTitle?.distributors || [],
    });
  }, [currentTitle]);

  useKeypress("Escape", () => {
    handleNo();
  });

  const handleBulkSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formValues = new FormData(e.currentTarget);
    const customers = formValues.getAll("customers");

    mutation.mutate({ customers, title: currentTitle });
  };

  const titleActionMessage = isActive ? "Deactivate Title" : "Activate Title";
  const titleVerifyMessage = isActive
    ? "This will deactivate this title along with every hold file that contains this title."
    : "This will reactivate all hold files with this title.";

  if (
    titlesState.matches("pending") ||
    titlesState.matches("loading") ||
    titlesState.matches("deleting")
  ) {
    return <Spinner size="64px" />;
  }

  return (
    <div className="">
      <Paper className="mb-8">
        <PaperHeader
          action={() => setIsEditing((current) => !current)}
          actionText="Edit"
        >
          Title Details
        </PaperHeader>
        <div className="p-4">
          {isEditing ? (
            <form>
              <label
                htmlFor="title"
                className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
              >
                <span>Title of series</span>
                <input
                  autoFocus
                  type="text"
                  name="title"
                  placeholder="Tank Girl"
                  value={formValues.title}
                  onChange={(e) => {
                    setFormValues((current) => {
                      return {
                        ...current,
                        [e.target.name]: e.target.value,
                      };
                    });
                  }}
                />
              </label>
              <div className="flex">
                <label
                  htmlFor="publisher"
                  className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
                >
                  <span>Select a publisher</span>
                  <select
                    name="publisher"
                    placeholder="Tank Girl"
                    value={formValues.publisher}
                    onChange={(e) => {
                      setFormValues((current) => {
                        return {
                          ...current,
                          [e.target.name]: e.target.value,
                        };
                      });
                    }}
                  >
                    <option value="">Choose a publisher</option>
                    <option value="Marvel">Marvel</option>
                    <option value="DC">DC</option>
                    <option value="Image">Image</option>
                    <option value="Boom">Boom</option>
                    <option value="Dark Horse">Dark Horse</option>
                    <option value="IDW">IDW</option>
                    <option value="Dynamite">Dynamite</option>
                    <option value="other">Other</option>
                  </select>
                </label>
                <label
                  htmlFor="distributor"
                  className="flex flex-1 flex-col my-2 md:m-0 md:ml-2"
                >
                  <span className="flex">
                    Select a distributor
                    <span className="ml-2 text-xs italic">*optional</span>
                  </span>
                  <ReactSelect
                    className="border border-gray-500"
                    name="distributors"
                    styles={{
                      // @ts-expect-error
                      control: (styles) => ({
                        ...styles,
                        border: "none",
                        padding: "0.125rem",
                      }),
                    }}
                    defaultValue={formValues.distributors}
                    onChange={(selection) => {
                      // @ts-ignore
                      setFormValues((current) => {
                        return {
                          ...current,
                          distributors: selection,
                        };
                      });
                    }}
                    placeholder="Choose one or more distributors"
                    isMulti
                    // @ts-ignore
                    options={distributors}
                  />
                </label>
              </div>
              <div className="flex">
                <label
                  htmlFor="notes"
                  className="flex flex-1 flex-col my-2 md:m-0 md:ml-2 pb-6"
                >
                  <span className="flex">
                    Notes <span className="ml-2 text-xs italic">*optional</span>
                  </span>
                  <input
                    type="text"
                    name="notes"
                    value={formValues.notes}
                    onChange={(e) => {
                      setFormValues((current) => {
                        return {
                          ...current,
                          [e.target.name]: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
              </div>
              <div className="flex justify-between items-center">
                <Link to="/titles" className="ml-4 text-indigo-600 underline">
                  Go Back
                </Link>
                <div>
                  <Button
                    type="submit"
                    disabled={titlesState.matches("submitting")}
                    variant="submission"
                    onClick={(e) => {
                      e.preventDefault();
                      titlesSend({
                        type: "update-title",
                        title: { ...currentTitle, ...formValues },
                      });
                      navigate("/titles");
                    }}
                  >
                    Update Title
                  </Button>
                  <Button
                    type="submit"
                    className="ml-4"
                    disabled={titlesState.matches("submitting")}
                    id="toggle-active-status"
                    onClick={(e) => {
                      e.preventDefault();
                      titlesSend({
                        type: "toggle-title-activation",
                        currentTitle,
                      });
                    }}
                  >
                    {titleActionMessage}
                  </Button>
                </div>
                {!isActive && (
                  <Button
                    type="submit"
                    variant="destructive"
                    onClick={(e) => {
                      e.preventDefault();
                      titlesSend({
                        type: "delete-title",
                        currentTitle,
                      });
                      handleRefreshTotals();
                      navigate("/titles");
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </form>
          ) : (
            <div>
              <div className="flex justify-between pb-20">
                <div className="flex-1">
                  <p className="pl-2  text-gray-500 text-sm">
                    Title:{" "}
                    <span className="text-base text-gray-900">
                      {currentTitle.title}
                    </span>
                  </p>
                  <p className="pl-2  text-gray-500 text-sm">
                    Publisher:{" "}
                    <span className="text-base text-gray-900">
                      {currentTitle.publisher}
                    </span>
                  </p>
                  {currentTitle.distributors?.length && (
                    <p className="pl-2  text-gray-500 text-sm">
                      Distributors:{" "}
                      <span className="text-base text-gray-900">
                        {currentTitle.distributors
                          .map((d) => d.label)
                          .join(", ")}
                      </span>
                    </p>
                  )}
                  {currentTitle.notes && (
                    <p className="pl-2  text-gray-500 text-sm">
                      Notes:{" "}
                      <span className="text-base text-gray-900">
                        {currentTitle.notes}
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex-1">
                  <form onSubmit={handleBulkSubmit}>
                    <label
                      htmlFor="customers"
                      className="flex flex-1 flex-col my-2 md:mr-2"
                    >
                      <span>Bulk add this title to files</span>
                      <ReactSelect
                        name="customers"
                        isMulti
                        options={customers.map((customer) => ({
                          ...customer,
                          label: `${customer.lastName} ${customer.firstName}`,
                          value: customer.sk,
                        }))}
                        placeholder="Select multiple customers"
                        className="flex-1"
                      />
                    </label>
                    <Button
                      variant="submission"
                      type="submit"
                      disabled={mutation.isPending}
                    >
                      Add to all
                    </Button>
                  </form>
                </div>
              </div>
              <div className="flex justify-between items-center pt-6">
                <Link to="/titles" className="ml-4 text-indigo-600 underline">
                  Go Back
                </Link>
              </div>
            </div>
          )}
        </div>
      </Paper>
      <CustomersWithTitle currentTitle={currentTitle} />
      <Paper className="mt-4 p-2">
        <PaperHeader>Change in subscribers</PaperHeader>
        <Bar
          className="flex-7"
          data={{
            labels: [
              "6 weeks ago",
              "5 weeks ago",
              "4 weeks ago",
              "3 weeks ago",
              "2 weeks ago",
              "Last Week",
            ],
            datasets: [
              {
                label: currentTitle.title,
                data: currentTitlesGraph,
                // borderColor: pickAColor(),
                backgroundColor: pickAColor(),
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top" },
            },
          }}
        />
      </Paper>
      {titlesState.matches("validating") && (
        // @ts-ignore
        <InputModal send={titlesSend} onCloseModal={handleNo}>
          <p className="text-xl text-center mb-4">{titleActionMessage}</p>
          <div className="p-8">
            <h2 className="mb-4">{titleVerifyMessage}</h2>
            <div className="flex items-center">
              <p className="text-lg mr-4">Are you sure?</p>
              <Button
                variant="submission"
                onClick={() => {
                  titlesSend({ type: "yes", currentTitle });
                  handleRefreshTotals();
                  navigate("/titles");
                }}
                className="mr-4"
              >
                Yes
              </Button>
              <Button onClick={handleNo}>Never mind</Button>
            </div>
          </div>
        </InputModal>
      )}
    </div>
  );
}
