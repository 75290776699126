import React from "react";
import Spinner from "../components/Spinner";
import Paper from "../components/paper/Paper";
import Button from "../components/buttons/Button";
import AccordionItem from "../components/AccordionItem";
import { useBundles } from "../machines/bundlesMachine";
import { useTitles } from "../context/titlesContext";

const BundlesPage = () => {
  const { bundles, isBundlesLoading, handleShowBundle } = useBundles();
  const { titles, loading } = useTitles();

  return (
    <div>
      <Button variant="contained" onClick={() => handleShowBundle()}>
        Create a bundle
      </Button>
      <div className="mt-8">
        <Paper>
          <ul className="p-4">
            {loading || isBundlesLoading ? (
              <Spinner size="64px" />
            ) : (
              <>
                {bundles.map(({ sk, bundleName, bundle }) => {
                  const details = bundle
                    .map((seriesKey) => {
                      const series = titles.find(
                        (title) => title.sk === seriesKey
                      )!;
                      return {
                        active: Boolean(series?.active),
                        key: seriesKey,
                        label: series?.publisher,
                        linkTarget: `/titles/${series?.sk.replace(
                          "TITLE#",
                          ""
                        )}`,
                        linkValue: series?.title,
                        notes: series?.notes || "",
                        value: series?.title,
                      };
                    })
                    .sort((a, b) => (a.linkValue > b.linkValue ? 1 : -1));

                  return (
                    <AccordionItem
                      actionLink={sk.replace("BUNDLE#", "")}
                      key={sk}
                      title={bundleName}
                      details={details}
                    />
                  );
                })}
              </>
            )}
          </ul>
        </Paper>
      </div>
    </div>
  );
};

export default BundlesPage;
