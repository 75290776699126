type Location = string;

interface Props {
  locations: Location[];
  setHiddenLocations: React.Dispatch<React.SetStateAction<string[]>>;
}

const LocationVisibility = ({ locations, setHiddenLocations }: Props) => {
  return (
    <div>
      <span className="text-sm text-gray-500">Visible locations</span>
      <ul className="flex">
        {locations.map((location) => (
          <li key={location}>
            <label className="flex items-center mr-4">
              <input
                className="mr-2"
                type="checkbox"
                name={location}
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setHiddenLocations((current) =>
                      current.filter((l) => l !== location)
                    );
                  } else {
                    setHiddenLocations((current) => [...current, location]);
                  }
                }}
              />
              {location}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationVisibility;
