const config = {
  // Backend config
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET_NAME,
  },
  stripeKey:
    "pk_test_51GwRthDZGT4kxcQ8pNV17TnNapbH1lhY7yflM1UbEvcTr68MmNZp4qoHGYWIV0WkBEVtzEIBkEFzxGD62JDhbih700mv19Ff6I",
  sentryDSN:
    "https://60cfa0d95b3845e8968a76d2438d6c1c@o1301302.ingest.sentry.io/6537209",
};

export default config;
